import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AccountAmplitude } from 'account/services/amplitude.service';

@Component({
    name: 'BsSwapTravelModal',
    template: require('./bs-swap-travel-modal.html'),
})
export default class BSSwapTravelModalComponent extends Vue {
    @Prop({
        default: true,
    })
    showModal: boolean;

    @Prop()
    changeMode: () => void

    @Prop()
    closeModal: () => void


    get show() {
        return this.showModal;
    }

    eventTrack() {
        AccountAmplitude.swapModalConfirm()
    }

    closeEventTrack() {
        AccountAmplitude.swapModalClose()
    }
}
