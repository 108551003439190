import { CreditCard } from 'account/model';
import { CreditCardDTO } from 'account/services/dto';
import { parserStringToPaymentBrand } from 'checkout/enums/payment-brand.enum';
import { parseStringToDate } from 'vue-shared/utils/date';

export default function creditCardAdapter(creditCardDto: CreditCardDTO): CreditCard {
    return {
        id: creditCardDto.id,
        number: creditCardDto.card,
        cvv: creditCardDto.cvv,
        expirationDate: creditCardDto.expirationDate,
        brand: parserStringToPaymentBrand(creditCardDto.brand),
        last4: creditCardDto.last4,
        cardOwner: {
            name: creditCardDto.cardOwner.name,
            document: creditCardDto.cardOwner.document,
            birthDate: parseStringToDate(creditCardDto.cardOwner.birthDate, "YYYY-MM-DD"),
            phoneNumber: creditCardDto.cardOwner.phoneNumber,
            email: creditCardDto.cardOwner.email
        }
    }
}