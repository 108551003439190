import { BusCompany } from 'account/model';
import { BusCompanyDTO } from 'account/services/dto';

export default function busCompanyAdapter(busCompanyDto: BusCompanyDTO): BusCompany {
    return {
        id: busCompanyDto.id,
        key: busCompanyDto.key,
        name: busCompanyDto.name,
        nickname: busCompanyDto.nickname,
        logo: busCompanyDto.logo.id,
        url: busCompanyDto.parentUrl
    }
}