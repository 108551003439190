import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import credit from './credit';
import dialog from './dialog';
import loading from './loading';
import modal from './modal';
import payment from './payment';

Vue.use(Vuex);

const store: StoreOptions<{}> = {
    // strict: process.env.NODE_ENV !== 'production',
    modules: {
        loading,
        credit,
        payment,
        dialog,
        modal,
    },
};

export default new Vuex.Store<{}>(store);
