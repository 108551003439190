import * as moment from 'moment';

// Métodos para tratamento de data e hora

var dateUtils = {
    // Retorna a data formatada conforme parâmetro passado (string)
    getFormattedDate(date, format) {
        return moment(date).format(format);
    },

    // Retorna a diferença entre duas datas
    getDateDiff: function(param1, param2) {
        try {
            var time1 = moment(param1);
            var time2 = moment(param2);

            var dateDiff = moment.duration(time2.diff(time1));

            if (dateDiff.hours() == 23) {
                dateDiff.add(1, 'hour');
            }

            if (dateDiff.asMilliseconds() < 0) {
                dateDiff.add(1, 'hour');
            }

            return dateDiff;
        } catch (e) {
            return '';
        }
    },

    getPreposicao(m) {
        return m == 'manhã' || m == 'madrugada' ? ' de ' : ' à ';
    },

    // Formata as datas usando o moment
    humanizeDate(date) {
        if (!date) return date;

        var days = date.days() ? date.days() + 'd ' : '';
        var hours = date.hours() ? (date.hours() % 24) + 'h ' : '';
        var minutes = date.minutes() ? (date.minutes() % 60) + 'm ' : '';

        if (!days) return hours + minutes;
        if (minutes) {
            date.add(1, 'hour');
            hours = date.hours() ? (date.hours() % 24) + 'h ' : '';
        }
        return days + hours;
    },

    // Retorna uma string formatada com o período atual do dia, baseado na hora informada.
    getDayMoment(hour) {
        var res;
        if (hour < 6) res = 'madrugada';
        if (hour >= 6 && hour < 12) res = 'manhã';
        if (hour >= 12 && hour < 18) res = 'tarde';
        if (hour >= 18 && hour <= 24) res = 'noite';

        return res;
    },

    // Verifica se a data informada é válida (Se é no mínimo a partir da data de hoje).
    dateIsValid(date) {
        return moment(date).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD');
    },

    add(date: string, timeToAdd: any, type: string): string {
        return moment(date)
            .add(timeToAdd, type)
            .format('YYYY-MM-DD HH:mm');
    },

    subtract(date: string, timeToSubtract: any, type: string): string {
        return moment(date)
            .subtract(timeToSubtract, type)
            .format('YYYY-MM-DD HH:mm');
    },
};

export default dateUtils;
