import { Component, Prop, Vue } from 'vue-property-decorator';
import FinancialBlockLine from './components/financial-block-line/financial-block-line';
import { MONTHS } from 'account/utils/date-time/consts';

@Component({
    name: 'FinancialBlockTable',
    template: require('./financial-block-table.html'),
    components: {
        'financial-block-line': FinancialBlockLine
    }
})
export default class FinancialBlockTable extends Vue {
    @Prop({ type: Date })
    month: Date;

    @Prop()
    financialStatements: Array<number>;

    get date(): string {
        return `${MONTHS[this.month.getMonth()].Name} ${this.month.getFullYear()}`;
    }
}