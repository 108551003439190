
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { ImgsUtil } from 'vue-shared/utils/imgs/imgs.util';
import { CreditCardSelector } from 'account/store/selector';
import {
    parserPaymentBrandToImg,
    parserPaymentBrandToString
} from 'checkout/enums/payment-brand.enum';

@Component({
    name: 'GVCreditCard',
    template: require('./gv-credit-card.html'),
})
export default class GVCreditCard extends Vue {
    private _creditCardSelector: CreditCardSelector = null;

    @Prop()
    creditCardId: number;

    created(): void {
        this._creditCardSelector = new CreditCardSelector(this.creditCardId);
    }

    get name(): string {
        return this._creditCardSelector.ownerName;
    }

    get last4(): string {
        return this._creditCardSelector.last4;
    }

    get brand(): string {
        return parserPaymentBrandToString(this._creditCardSelector.brand);
    }

    get expirationDate(): string {
        return this._creditCardSelector.expirationDate;
    }

    get image(): string {
        const bgImage: string = parserPaymentBrandToImg(this._creditCardSelector.brand);
        return `${ImgsUtil.staticAws()}/_v2/static/img/${bgImage}`;
    }

    get chip() {
        return `${ImgsUtil.staticAws()}/_v2/static/img/chip.png`;
    }

    get brandColor() {
        const image: string = parserPaymentBrandToImg(this._creditCardSelector.brand);
        return {'background-image': `url('${ImgsUtil.staticAws()}/_v2/static/img/${image}')`}
    }

    removeCard(): void {
        this.$emit('remove-card', this.creditCardId);
    }
}
