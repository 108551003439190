import {
    Action,
    Module,
    Mutation,
    VuexModule,
    getModule
} from 'vuex-module-decorators';
import { FinancialStatement } from 'account/model';
import { FinancialStatementType, CREDIT } from 'account/model/enum/financial-statement-type';
import { Store } from 'account/store';

@Module({
    name: 'financial-statement',
    namespaced: true,
})
export default class FinancialStatementModule extends VuexModule {
    /**
     * Repository of financial statements.
     * 
     * It's a map from FinancialStatement.id to FinancialStatement.
     */
    private _repo: Map<number, FinancialStatement> = new Map<number, FinancialStatement>();
    private _statements: Array<FinancialStatement> = [];

    static getInstance(): FinancialStatementModule {
        return getModule(FinancialStatementModule, Store.getInstance());
    }
    
    @Mutation
    private _insertFinancialStatement(financialStatement: FinancialStatement): void {
        if (!this._repo.has(financialStatement.id))
            this._statements.push(financialStatement);

        this._repo.set(financialStatement.id, financialStatement);
    }

    @Action
    insertFinancialStatement(financialStatement: FinancialStatement): void {
        this.context.commit('_insertFinancialStatement', financialStatement);
    }

    get getDate(): (financialStatementId: number, def?: Date) => Date {
        return (financialStatementId: number, def: Date = new Date()): Date => {
            if (this._repo.has(financialStatementId))
                return this._repo.get(financialStatementId).date;

            return def;
        }
    }

    get getType(): (financialStatementId: number, def?: FinancialStatementType) => FinancialStatementType {
        return (financialStatementId: number, def: FinancialStatementType = CREDIT): FinancialStatementType => {
            if (this._repo.has(financialStatementId))
                return this._repo.get(financialStatementId).type;

            return def;
        }
    }

    get getDetails(): (financialStatementId: number, def?: string) => string {
        return (financialStatementId: number, def: string = ""): string => {
            if (this._repo.has(financialStatementId))
                return this._repo.get(financialStatementId).details;

            return def;
        }
    }

    get getValue(): (financialStatementId: number, def?: number) => number {
        return (financialStatementId: number, def: number = 0): number => {
            if (this._repo.has(financialStatementId))
                return this._repo.get(financialStatementId).value;

            return def;
        }
    }

    /**
     * Returns a deep copy from all financial statements which satisfies filter condition.
     * 
     * @param filter function
     */
    get query(): (filter: (a:FinancialStatement) => boolean) => Array<FinancialStatement> {
        return (filter: (a:FinancialStatement) => boolean): Array<FinancialStatement> => {
            return this._statements.filter(filter);
        }
    }

    get oldestStatement(): FinancialStatement {
        if (this._statements.length == 0)
            return null;

        var ret: FinancialStatement = this._statements[0];

        this._statements.forEach(statement => {
            if (statement.date.getTime() < ret.date.getTime())
                ret = statement;
        })

        return ret;
    }
}