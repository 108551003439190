export class Util {
    normalize = (str: string): string => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    removeSpaces = (str: string): string => {
        return str.replace(/\s/g, '');
    };

    static removeDots = (str: string): string => {
        return (str || '').replace(/\./g, ' ').replace(/\s+/g, " ");
    }

    normalizeAndRemoveSpaces = (str: string) => {
        const normalized: string = this.normalize(str);
        return this.removeSpaces(normalized);
    };

    capitalizeAll(text: string): string {
        if (!text) return '';

        const splitedText: Array<string> = text
            .toString()
            .toLowerCase()
            .split(' ');

        const convertUpperRule: Function = (text: string): string => {
            const haveNumber: Function = (): boolean => {
                return /\d/.test(text);
            };

            const isState: Function = (): boolean => {
                text = text.toUpperCase();
                const states: Array<string> = [
                    'AC',
                    'AL',
                    'AP',
                    'AM',
                    'BA',
                    'CE',
                    'DF',
                    'ES',
                    'GO',
                    'MA',
                    'MT',
                    'MS',
                    'MG',
                    'PA',
                    'PB',
                    'PR',
                    'PE',
                    'PI',
                    'RJ',
                    'RN',
                    'RS',
                    'RO',
                    'RR',
                    'SC',
                    'SP',
                    'SE',
                    'TO',
                    'PY',
                    'UY',
                ];
                return states.indexOf(text) > -1;
            };

            return haveNumber() || isState();
        };

        const convertLowerRule: Function = (text: string): boolean => {
            const isArtigo: Function = (): boolean => {
                text = text.toLowerCase();
                const states: Array<string> = ['da', 'de', 'do', 'das', 'dos', 'e', 'x'];
                return states.indexOf(text) > -1;
            };

            return isArtigo();
        };

        return splitedText
            .map((text: string) => {
                if (convertUpperRule(text)) {
                    return text.toUpperCase();
                } else if (convertLowerRule(text)) {
                    return text.toLowerCase();
                }
                return text.charAt(0).toUpperCase() + text.slice(1);
            })
            .join(' ');
    }
}

export const StringUtil: Util = new Util();
