export enum OperationStatus {
    PAYMENT_PENDING = 'PENDING',
    IN_ANALYSIS = 'IN_ANALYSIS',
    PAYMENT_REJECTED = 'PAYMENT_REJECTED',
    PAYMENT_SUCCESS = 'PAID',
    RESERVED = 'RESERVED',
    PENDING_BOLETO = 'BOLETO_PENDENTE',
    EXPIRED = 'EXPIRED',
    FAIL = 'FAIL',
    CANCEL = 'CANCEL',
    PARTIAL_CANCEL = 'PARTIAL_CANCEL',
    AUTHENTICATED_SALE = 'AUTHENTICATED_SALE',
    OFFLINE_TICKETS_PENDING = 'OFFLINE_TICKETS_PENDING',
    RECOVER = 'RECOVER',
}
