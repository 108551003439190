import { HttpClient } from 'commons/ts/http.client';
import { CreditCardDTO } from './dto';

export default class CreditCardService {
    private static _instance: CreditCardService = new CreditCardService();

    static getInstance(): CreditCardService {
        return this._instance;
    }

    saveCreditCard(creditCard: CreditCardDTO): Promise<CreditCardDTO> {
        return HttpClient.post("/t/api/account/credit-card", creditCard);
    }
}