function parseSubstring(date: string, idx: number, length: number, def: number): number {
    if (idx == -1)
        return def;

    const ret: number = Number(date.substr(idx, length));
    return ret != NaN ? ret :def;
}

export function parseStringToDate(date: string, format: string): Date {
    if (typeof(date) != "string" || date.length != format.length) {
        return new Date();
    }

    const year: number = parseSubstring(date, format.indexOf("YYYY"), 4, 2000);
    const month: number = parseSubstring(date, format.indexOf("MM"), 2, 1);
    const day: number = parseSubstring(date, format.indexOf("DD"), 2, 1);
    const hour: number = parseSubstring(date, format.indexOf("hh"), 2, 0);
    const minute: number = parseSubstring(date, format.indexOf("mm"), 2, 0);
    const second: number = parseSubstring(date, format.indexOf("ss"), 2, 0);

    return new Date(year, month-1, day, hour, minute, second);
}

const dateParser = {
    // Month
    Mth: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    mth: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],

    // Day of week
    Dow: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    dow: ["dom", "seg", "ter", "qua", "qui", "sex", "sab"]
}

export function parseDateToString(date: Date, format: string): string {
    return format
        .replace("Mth", dateParser.Mth[date.getMonth()])
        .replace("mth", dateParser.mth[date.getMonth()])
        .replace("Dow", dateParser.Dow[date.getDay()])
        .replace("dow", dateParser.dow[date.getDay()])
        .replace("YYYY", String(date.getFullYear()))
        .replace("MM", asTwoDigits(date.getMonth()+1))
        .replace("DD", asTwoDigits(date.getDate()))
        .replace("hh", asTwoDigits(date.getHours()))
        .replace("mm", asTwoDigits(date.getMinutes()));
}

function asTwoDigits(val: number): string {
    if (val < 10)
        return "0"+val;

    return String(val);
}