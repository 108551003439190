export interface PaginationListConfig<T> {
    list?: Array<T>,
    pageIdx?: number,
    pageSize?: number,
    sortFunc?:(a: T, b: T) => number
}

export class PaginationList<T> {
    private _list: Array<T>;
    private _pageIdx: number;
    private _pageSize: number;
    private _morePagesAvailable: boolean;
    private _sortFunc: (a: T, b: T) => number;

    constructor(config: PaginationListConfig<T> = {}) {
        const {list, pageIdx, pageSize, sortFunc} = config;

        this._list = list || [];
        this._pageIdx = pageIdx || 0;
        this._pageSize = pageSize || 4;
        this._morePagesAvailable = this._list.length == 0 || this.list.length == this._pageSize;
        this._sortFunc = sortFunc;
    }

    get pageIdx(): number {
        return this._pageIdx;
    }

    get pageSize(): number {
        return this._pageSize;
    }

    get morePagesAvailable(): boolean {
        return this._morePagesAvailable;
    }

    get list(): Array<T> {
        return [...this._list];
    }

    insertNewPage(newPage: Array<T>): void {
        if (!this._morePagesAvailable)
            return;

        if (newPage.length < this._pageSize)
            this._morePagesAvailable = false;
        else
            this._pageIdx++;
        
        newPage.forEach(item => {
            if (!this._list.includes(item))
                this._list.push(item);
        })

        if (!!this._sortFunc)
            this._list = this._list.sort(this._sortFunc);
    }

    enableMorePages(): void {
        this._morePagesAvailable = true;
    }
}