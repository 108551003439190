import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { ImgsUtil } from 'vue-shared/utils/imgs/imgs.util';
import { OperationSelector } from 'account/store/selector';
import { OperationStatus } from 'account/model/enum';
import AccountModule from 'account/store/account';
import GVButton from 'vue-shared/components/gv-button/gv-button';
import GVRoute from 'account/components/gv-route';
import GVTicket from 'account/pages/travel/components/gv-ticket';
import store from 'account/store';

const account: AccountModule = getModule(AccountModule, store);

@Component({
    name: 'GVTravelCard',
    template: require('./gv-travel-card.html'),
    components: {
        'gv-button': GVButton,
        'gv-ticket': GVTicket,
        'gv-route': GVRoute,
    }
})
export default class GVTravelCard extends Vue {
    private _reserveOperation: OperationSelector;

    @Prop()
    reserveOperationId: number;

    created(): void {
        this._reserveOperation = new OperationSelector(this.reserveOperationId);
    }

    get image() {
        return `${ImgsUtil.assetSrc}${this._reserveOperation.destinationBannerUrl}`;
    }

    get origin(): string {
        return this._reserveOperation.originLabel;
    }

    get destination(): string {
        return this._reserveOperation.destinationLabel;
    }

    get companyLogoUrl(): string {
        return this._reserveOperation.companyLogoUrl;
    }

    get isConnection(): boolean {
        return this._reserveOperation.isConnection;
    }

    get isStopOver(): boolean {
        return this._reserveOperation.isStopOver;
    }

    get locator(): number {
        return this._reserveOperation.id;
    }

    get status(): OperationStatus {
        return this._reserveOperation.status;
    }

    get departureDate(): Date {
        return this._reserveOperation.goTravelDepartureDate;
    }

    get arrivalDate(): Date {
        return this._reserveOperation.goTravelArrivalDate;
    }

    get hasBack(): boolean {
        return this._reserveOperation.hasBack;
    }

    get backDepartureDate(): Date {
        return this._reserveOperation.goTravelBackDate;
    }

    get detailsUrl(): string {
        if (this.status === OperationStatus.RESERVED || this.status === OperationStatus.PENDING) {
            return `/t/pagamento/sec/r/${this.locator}`;
        }

        return account.isPartialCancelEnable
                ? `/detalhes-viagem/${this.locator}`
                : `/t/pagamento/sec/r/${this.locator}`;
    }
}
