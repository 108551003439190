import { MDCSnackbar } from '@material/snackbar';
import {Component, Prop, Vue} from 'vue-property-decorator';
import AccountModule from 'account/store/account';
import {getModule} from 'vuex-module-decorators';
import storeAccount from 'account/store';
import {UserInfo} from 'account/interfaces/user-info.interface';
import {AccountService} from 'account/services/account.service';
import LoadingModule from 'account/store/loading';
import store from 'account/store';
import { SnackbarIdentifier } from 'account/enums/snackbar-identifier.enum';
import { AccountAmplitude } from 'account/services/amplitude.service';
import { PageName } from 'account/enums/page-name.enums';
import { RouterPath } from 'account/enums/router-path.enum';

const account: AccountModule = getModule(AccountModule, storeAccount);
const loading: LoadingModule = getModule(LoadingModule, store);
@Component({
    name: 'UpdateProfile',
    template: require('./update-profile.page.html'),
})
export default class UpdateProfile extends Vue {
    userPictureToEdit: string;
    newUserPictureBlob: File;
    canEditUserPicture: boolean = true;
    profilePicture: string;

    private userInfoToEdit: UserInfo = {
        id: '',
        email: '',
        firstName: '',
        name: '',
        features: [],
        cpf: '',
        telephone: ''
    };

    @Prop()
    value: File;

    created(): void {
        this.userInfoToEdit.id = account.userInfo.id;
        this.userInfoToEdit.firstName = account.userInfo.firstName;
        this.userInfoToEdit.name = account.userInfo.name;
        this.userInfoToEdit.email = account.userInfo.email;
        this.userInfoToEdit.cpf = account.userInfo.cpf;
        this.userInfoToEdit.telephone = account.userInfo.telephone;
        this.userPictureToEdit = account.userPicture;
        this.profilePicture = this.userPictureToEdit;
    }

    mounted(): void {
        AccountAmplitude.accountOpen(PageName.EDIT_PROFILE);
    }

    get userInfo() {
        return account.userInfo;
    }

    userPicture() {
        return this.profilePicture;
    }

    openSnackbar(snackbarId: string): void {
        const snackbar: MDCSnackbar = new MDCSnackbar(document.querySelector(snackbarId));
        snackbar.open();
    }

    /*
    * Save update profile
    */
    async saveUpdate(): Promise<void> {
        const formIsValid: boolean = this.validateFields();

        if(!formIsValid) {
            this.openSnackbar(SnackbarIdentifier.WARNING);
            return;
        }

        try {
            loading.startLoading();

            // Atualiza usuario
            const updatedUser: UserInfo = await AccountService.updateUser(this.userInfoToEdit);
            AccountAmplitude.saveUpdateProfile();
            this.openSnackbar(SnackbarIdentifier.VALIDATION_OK);

            account.setUserInfo(updatedUser);
            this.$router.push('/perfil');
        } catch (error) {
            this.openSnackbar(SnackbarIdentifier.VALIDATION_ERROR);
        }
        finally {
            loading.finishLoading();
        }
    }

    handleFileChange(event: any): void {
        if (!event || !event.target || !event.target.files || !event.target.files.length) {
            return;
        }

        const image: File = event.target.files[0] as File;
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e: any): void => {
            this.userPictureToEdit = e.target.result;
            this.newUserPictureBlob = image;
            account.setUserPicture(this.userPictureToEdit);
            this.profilePicture = this.userPictureToEdit;
            this.$forceUpdate();
        };
    }

    fileRemove(event: any): void {
    }

    doCancel(): void {
        window.location.href = RouterPath.HOME;
    }

    changePassword() {
        this.$router.push(RouterPath.ACCESS_DATA);
    }

    validateField(input: HTMLInputElement): boolean {
        input.parentElement.classList.remove('error');
        if (input.required && !input.value) {
            input.parentElement.classList.add('error');
            input.parentElement.querySelector('.bs-input-error').innerHTML = 'Campo obrigatório';
            return false;
        }
        if (input.dataset.type === 'name-update') {
            const valid: boolean = input.value.length > 2;
            if (!valid) {
                input.parentElement.classList.add('error');
                input.parentElement.querySelector('.bs-input-error').innerHTML = 'Nome inválido';
                return false;
            }
            input.parentElement.querySelector('.bs-input-error').innerHTML = '';
        }
        if (input.dataset.type === 'cpf-update') {
            const valid: boolean = input.value.length > 2;
            if (!valid) {
                input.parentElement.classList.add('error');
                input.parentElement.querySelector('.bs-input-error').innerHTML = 'CPF inválido';
                return false;
            }
            input.parentElement.querySelector('.bs-input-error').innerHTML = '';
        }
        if (input.dataset.type === 'telephone-update') {
            const valid: boolean = input.value.length > 9;
            const regex = /^[0-9\b]+$/;
            if (!input.value.match(regex)) {
                input.parentElement.classList.add('error');
                input.parentElement.querySelector('.bs-input-error').innerHTML = 'Telefone inválido';
                return false;
            }
            if (!valid) {
                input.parentElement.classList.add('error');
                input.parentElement.querySelector('.bs-input-error').innerHTML = 'Telefone inválido';
                return false;
            }
            input.parentElement.querySelector('.bs-input-error').innerHTML = '';
        }
        return true;
    }

    private validateFields(): boolean {
        const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('.js-profile-form');
        let valid: boolean = true;
        inputs.forEach((input: HTMLInputElement) => {
            const validField: boolean = this.validateField(input);
            if (valid) valid = validField;
        });
        return valid;
    }

}
