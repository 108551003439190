import { BusStation } from 'account/model';
import { BusStationDTO } from 'account/services/dto';

export default function busStationAdapter (busStationDTO: BusStationDTO): BusStation {
    return {
        id: busStationDTO.id,
        name: busStationDTO.name,
        nickname: busStationDTO.nickname,
        label: busStationDTO.label,
        url: busStationDTO.url,
        countryCode: busStationDTO.country,
        stateCode: busStationDTO.stateCode,
        cityName: busStationDTO.cityName,
        timeZone: busStationDTO.timeZone
    }
}