import {
    Action,
    Module,
    Mutation,
    VuexModule,
    getModule
} from 'vuex-module-decorators';
import { Store } from 'account/store';
import { PaginationList, PaginationListConfig } from 'vue-shared/utils/pagination';

@Module({
    name: 'travel-page',
    namespaced: true,
})
export default class TravelPageModule extends VuexModule {
    private static PAGENATION_PARAMS: PaginationListConfig<number> = {
        pageSize: 3,
        sortFunc: (a: number, b: number) => a < b ? -1 : 1
    };

    private _hasInit: boolean = false;
    private _futureOperationList: PaginationList<number> = new PaginationList(TravelPageModule.PAGENATION_PARAMS);
    private _confirmedOperationList: PaginationList<number> = new PaginationList(TravelPageModule.PAGENATION_PARAMS);
    private _expiredOperationList: PaginationList<number> = new PaginationList(TravelPageModule.PAGENATION_PARAMS);
    private _cancelledOperationList: PaginationList<number> = new PaginationList(TravelPageModule.PAGENATION_PARAMS);
    private _pendingOperationList: PaginationList<number> = new PaginationList(TravelPageModule.PAGENATION_PARAMS);

    static getInstance(): TravelPageModule {
        return getModule(TravelPageModule, Store.getInstance());
    }

    get hasInit(): boolean {
        return this._hasInit;
    }

    get futureOperations(): Array<number> {
        return this._futureOperationList.list;
    }

    get futureOperationsPageIdx(): number {
        return this._futureOperationList.pageIdx;
    }

    get futureOperationsPageSize(): number {
        return this._futureOperationList.pageSize;
    }

    get moreFutureOperationsAvailable(): boolean {
        return this._futureOperationList.morePagesAvailable;
    }

    get confirmedOperations(): Array<number> {
        return this._confirmedOperationList.list;
    }

    get confirmedOperationsPageIdx(): number {
        return this._confirmedOperationList.pageIdx;
    }

    get confirmedOperationsPageSize(): number {
        return this._confirmedOperationList.pageSize;
    }

    get moreConfirmedOperationsAvailable(): boolean {
        return this._confirmedOperationList.morePagesAvailable;
    }

    get expiredOperations(): Array<number> {
        return this._expiredOperationList.list;
    }

    get expiredOperationsPageIdx(): number {
        return this._expiredOperationList.pageIdx;
    }

    get expiredOperationsPageSize(): number {
        return this._expiredOperationList.pageSize;
    }

    get moreExpiredOperationsAvailable(): boolean {
        return this._expiredOperationList.morePagesAvailable;
    }

    get cancelledOperations(): Array<number> {
        return this._cancelledOperationList.list;
    }

    get cancelledOperationsPageIdx(): number {
        return this._cancelledOperationList.pageIdx;
    }

    get cancelledOperationsPageSize(): number {
        return this._cancelledOperationList.pageSize;
    }

    get moreCancelledOperationsAvailable(): boolean {
        return this._cancelledOperationList.morePagesAvailable;
    }

    get pendingOperations(): Array<number> {
        return this._pendingOperationList.list;
    }

    get pendingOperationsPageIdx(): number {
        return this._pendingOperationList.pageIdx;
    }

    get pendingOperationsPageSize(): number {
        return this._pendingOperationList.pageSize;
    }

    get morePendingOperationsAvailable(): boolean {
        return this._pendingOperationList.morePagesAvailable;
    }

    @Action
    dispatchInit(): void {
        this.context.commit('_dispatchInit');
    }

    @Action
    insertFutureOperationPage(operationIds: Array<number>): void {
        this.context.commit('_insertFutureOperationPage', operationIds);
    }

    @Action
    insertConfirmedOperationPage(operationIds: Array<number>): void {
        this.context.commit('_insertConfirmedOperationPage', operationIds);
    }

    @Action
    insertExpiredOperationPage(operationIds: Array<number>): void {
        this.context.commit('_insertExpiredOperationPage', operationIds);
    }

    @Action
    insertCancelledOperationPage(operationIds: Array<number>): void {
        this.context.commit('_insertCancelledOperationPage', operationIds);
    }

    @Action
    insertPendingOperationPage(operationIds: Array<number>): void {
        this.context.commit('_insertPendingOperationPage', operationIds);
    }

    @Mutation
    private _dispatchInit(): void {
        this._hasInit = true;
    }

    @Mutation
    private _insertFutureOperationPage(operationIds: Array<number>): void {
        this._futureOperationList.insertNewPage(operationIds);
    }

    @Mutation
    private _insertConfirmedOperationPage(operationIds: Array<number>): void {
        this._confirmedOperationList.insertNewPage(operationIds);
    }

    @Mutation
    private _insertExpiredOperationPage(operationIds: Array<number>): void {
        this._expiredOperationList.insertNewPage(operationIds);
    }

    @Mutation
    private _insertCancelledOperationPage(operationIds: Array<number>): void {
        this._cancelledOperationList.insertNewPage(operationIds);
    }

    @Mutation
    private _insertPendingOperationPage(operationIds: Array<number>): void {
        this._pendingOperationList.insertNewPage(operationIds);
    }
}