enum PaymentType {
    DEBIT,
    CREDIT,
    FASTCASH,
    BOLETO,
    INVOICE,
    TRANSFER,
    DIGITAL_WALLET,
    UNKNOWN
}

export default PaymentType;