import {
    Action,
    Module,
    Mutation,
    VuexModule,
    getModule
} from 'vuex-module-decorators';
import { BusStation } from 'account/model';
import { Store } from 'account/store';

@Module({
    name: 'bus-station',
    namespaced: true,
})
export default class BusStationModule extends VuexModule {
    /**
     * Repository of bus stations.
     * 
     * It's a map from BusStation.id to BusStation.
     */
    private _repo: Map<number, BusStation> = new Map<number, BusStation>();

    static getInstance(): BusStationModule {
        return getModule(BusStationModule, Store.getInstance());
    }

    @Mutation
    private _insertBusStation(busStation: BusStation): void {
        this._repo.set(busStation.id, busStation);
    }

    @Action
    insertBusStation(busStation: BusStation): void {
        this.context.commit('_insertBusStation', busStation);
    }

    get getLabel(): (busStationId: number, def?: string) => string {
        return (busStationId: number, def: string = ""): string => {
            if (this._repo.has(busStationId))
                return this._repo.get(busStationId).label;

            return def;
        }
    }
}