import { CancelType } from 'account/enums/cancel-type.enum';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    name: 'BsCancelSelector',
    template: require('./bs-cancel-selector.html'),
})
export default class BSCancelDetailComponent extends Vue {
    @Prop()
    changeOperation: (operation: string) => void;

    @Prop()
    changeMode: (mode: CancelType) => void;

    @Prop({default: false})
    haveBack: boolean;

    @Prop({default: CancelType.PARTIAL})
    defaultSelection: CancelType;

    @Prop()
    showGo: boolean;

    @Prop()
    showBack: boolean;

    selectedOption: string = '';

    get disabled() {
        return !this.selectedOption;
    }

    mounted() {
        this.onDefaultChange(this.defaultSelection);
    }

    @Watch('default')
    onDefaultChange(value: CancelType) {
        switch (value) {
            case CancelType.COMPLETE:
                this.selectedOption = 'full';
                break;
            case CancelType.PARTIAL:
                this.selectedOption = 'manual';
                break;
            case CancelType.ONLY_GO:
                this.selectedOption = 'go';
                break;
            case CancelType.ONLY_BACK:
                this.selectedOption = 'back';
                break;
        }
    }

    action() {
        switch (this.selectedOption) {
            case 'full':
                this.changeMode(CancelType.COMPLETE);
                break;
            case 'go':
                this.changeMode(CancelType.ONLY_GO);
                break;
            case 'back':
                this.changeMode(CancelType.ONLY_BACK);
                break;
            case 'manual':
                this.changeMode(CancelType.PARTIAL);
                return this.changeOperation('partialCancel');
            default:
                this.changeMode(CancelType.COMPLETE);
                break;
        }
        return this.changeOperation('completeCancel');
    }
}
