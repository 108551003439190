import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
    name: 'modal',
    namespaced: true,
})
export default class ModalModule extends VuexModule {
    showCreditPaymentModal: boolean = false;
    showDebitPaymentModal: boolean = false;
    showTransferPaymentModal: boolean = false;
    showTaxInfoModal: boolean = false;
    showTransferStepByStep: boolean = false;
    showInsuranceInformation: boolean = false;
    showPixPaymentModal: boolean = false;

    @Mutation
    setCreditModalMutation(showCreditPaymentModal: boolean): void {
        this.showCreditPaymentModal = showCreditPaymentModal;
    }

    @Action({ commit: 'setCreditModalMutation' })
    setCreditModal(showCreditPaymentModal: boolean): boolean {
        return showCreditPaymentModal;
    }

    @Mutation
    setDebitModalMutation(showDebitPaymentModal: boolean): void {
        this.showDebitPaymentModal = showDebitPaymentModal;
    }

    @Action({ commit: 'setDebitModalMutation' })
    setDebitModal(showDebitPaymentModal: boolean): boolean {
        return showDebitPaymentModal;
    }

    @Mutation
    setTransferModalMutation(showTransferPaymentModal: boolean): void {
        this.showTransferPaymentModal = showTransferPaymentModal;
    }

    @Action({ commit: 'setTransferModalMutation' })
    setTransferModal(showTransferPaymentModal: boolean): boolean {
        return showTransferPaymentModal;
    }

    @Mutation
    setTaxInfoModalMutation(showTaxInfoModal: boolean): void {
        this.showTaxInfoModal = showTaxInfoModal;
    }

    @Action({ commit: 'setTaxInfoModalMutation' })
    setTaxInfoModal(showTaxInfoModal: boolean): boolean {
        return showTaxInfoModal;
    }

    @Mutation
    setTransferPaymentModalMutation(showTransferPaymentModal: boolean): void {
        this.showTransferPaymentModal = showTransferPaymentModal;
    }

    @Action({commit: 'setTransferPaymentModalMutation'})
    setTransferPaymentModal(showTransferPaymentModal: boolean): boolean {
        return showTransferPaymentModal;
    }

    @Mutation
    setPixPaymentModalMutation(showPixPaymentModal: boolean): void {
        this.showPixPaymentModal = showPixPaymentModal;
    }

    @Action({commit: 'setPixPaymentModalMutation'})
    setPixPaymentModal(showPixPaymentModal: boolean): boolean {
        return showPixPaymentModal;
    }

    /**
     * Note: the gv-dialog-tansfer-step-by-step is watching this mutation, so if you
     * changes this mutation name OR THE MODULE NAMESPACE, adjust the component accordly
     */
    @Mutation
    setTransferStepByStep(showTransferStepByStep: boolean): void {
        this.showTransferStepByStep = showTransferStepByStep;
    }

    @Action({ commit: 'setTransferStepByStep' })
    openDialogTransferStepByStep(): boolean {
        return true;
    }

    @Action({ commit: 'setTransferStepByStep' })
    closeDialogTransferStepByStep(): boolean {
        return false;
    }

    /**
     * Note: the gv-dialog-insurance-information is watching this mutation, so if you
     * changes this mutation name OR THE MODULE NAMESPACE, adjust the component accordly
     */
    @Mutation
    setInsuranceInformation(showInsuranceInformation: boolean): void {
        this.showInsuranceInformation = showInsuranceInformation;
    }

    @Action({ commit: 'setInsuranceInformation' })
    openInsuranceInformation(): boolean {
        return true;
    }

    @Action({ commit: 'setInsuranceInformation' })
    closeInsuranceInformation(): boolean {
        return false;
    }
}
