import { PaymentBrand } from 'checkout/enums/payment-brand.enum';
import { PaymentTransfer } from 'checkout/enums/payment-transfer.enum';
import { PaymentType } from 'checkout/enums/payment-type.enum';
import { PaymentOption } from 'checkout/interfaces/payment-option.interface';
import { CardIdentifier } from 'checkout/models/card.identifier.model';
import { CreditCardUtil } from 'checkout/utils/credit-card/credit-card.util';
import * as dayjs from 'dayjs';
import { parseDate } from '../../../../../src/_v2/application/components/common/gv-common';

const VISA_OPTIONS: Array<PaymentOption> = [
    {
        id: 'radio-debito-bradesco-visa',
        value: PaymentBrand.DEBITO_BRADESCO,
        class: 'gv-card-flag-thumb-bradesco',
        bankName: 'Bradesco',
    },
    {
        id: 'radio-debito-brasil-visa',
        value: PaymentBrand.DEBITO_BRASIL,
        class: 'gv-card-flag-thumb-bbrasil',
        bankName: 'Banco do Brasil',
    },
    {
        id: 'radio-debito-hsbc-visa',
        value: PaymentBrand.DEBITO_HSBC,
        class: 'gv-card-flag-thumb-hsbc',
        bankName: 'HSBC',
    },
    {
        id: 'radio-debito-santander-visa',
        value: PaymentBrand.DEBITO_SANTANDER,
        class: 'gv-card-flag-thumb-santander',
        bankName: 'Santander',
    },
    {
        id: 'radio-debito-itau-visa',
        value: PaymentBrand.DEBITO_ITAU_VISA,
        class: 'gv-card-flag-thumb-itau',
        bankName: 'Itaú',
    },
    {
        id: 'radio-debito-brb-visa',
        value: PaymentBrand.DEBITO_BRB,
        class: 'gv-card-flag-thumb-brb',
        bankName: 'BRB',
    },
];

const MAESTRO_OPTIONS: Array<PaymentOption> = [
    {
        id: 'radio-debito-santander-maestro',
        value: PaymentBrand.DEBITO_SANTANDER_MAESTRO,
        class: 'gv-card-flag-thumb-santander',
        bankName: 'Santander',
    },
    {
        id: 'radio-debito-brb-maestro',
        value: PaymentBrand.DEBITO_BRB_MAESTRO,
        class: 'gv-card-flag-thumb-brb',
        bankName: 'BRB',
    },
    {
        id: 'radio-debito-citibank-maestro',
        value: PaymentBrand.DEBITO_CITIBANK_MAESTRO,
        class: 'gv-card-flag-thumb-citi',
        bankName: 'Citibank',
    },
    {
        id: 'radio-debito-brasil-maestro',
        value: PaymentBrand.DEBITO_BRASIL_MAESTRO,
        class: 'gv-card-flag-thumb-bbrasil',
        bankName: 'Banco do Brasil',
    },
];

const ACCOUNT_DEBIT_OPTIONS: Array<PaymentOption> = [
    { id: 'radio-debito-itau', value: PaymentBrand.DEBITO_ITAU, class: 'gv-card-flag-thumb-itau', bankName: 'Itaú' },
];

const TRANSFER_BANK_OPTIONS: Array<PaymentOption> = [
    {
        id: 'radio-transfer-bb',
        value: PaymentTransfer.BB_TRANSFER,
        class: 'gv-card-flag-thumb-bbrasil',
        bankName: 'Banco do Brasil',
    },
    {
        id: 'radio-transfer-bradesco',
        value: PaymentTransfer.BRADESCO_TRANSFER,
        class: 'gv-card-flag-thumb-bradesco',
        bankName: 'Bradesco',
    },
    {
        id: 'radio-transfer-santander',
        value: PaymentTransfer.SANTANDER_TRANSFER,
        class: 'gv-card-flag-thumb-santander',
        bankName: 'Santander',
    },
    {
        id: 'radio-transfer-cef',
        value: PaymentTransfer.CEF_TRANSFER,
        class: 'gv-card-flag-thumb-cef',
        bankName: 'Caixa',
    },
    {
        id: 'radio-transfer-original',
        value: PaymentTransfer.ORIGINAL_TRANSFER,
        class: 'gv-card-flag-thumb-original',
        bankName: 'Banco Original',
    },
    {
        id: 'radio-transfer-inter',
        value: PaymentTransfer.INTER_TRANSFER,
        class: 'gv-card-flag-thumb-inter',
        bankName: 'Banco Inter',
    },
];

const PAYMENT_OPTIONS_FORM_LIST: Array<PaymentOption> = [
    { id: 'radio-pix', value: PaymentType.PIX, label: 'Pix', svg: 'pix' },
    { id: 'radio-credit', value: PaymentType.CREDIT, label: 'Cartão de crédito', icon: 'credit_card' },
    { id: 'radio-transfer', value: PaymentType.TRANSFER, label: 'Transferência', svg: 'bank_transfer' },
    { id: 'radio-debit', value: PaymentType.DEBIT, label: 'Cartão de débito', icon: 'credit_card' },
    { id: 'radio-invoice', value: PaymentType.INVOICE, label: 'Fatura', svg: 'barcode' },
];

const DICTIONARY: any = {
    'pt-br': {
        messages: {
            // padrao
            alpha: function(): string {
                return 'Apenas letras são permitidas';
            },
            alpha_spaces: function(): string {
                return 'Apenas letras e espaços vazios são permitidos';
            },
            email: function(): string {
                return 'E-mail inválido';
            },
            min: function(info: string, min: string): string {
                return `Minímo ${min} caracteres`;
            },
            max: function(info: string, max: string): string {
                return `Máximo ${max} caracteres`;
            },
            regex: function(): string {
                return 'Valor inválido';
            },
            required: function(): string {
                return 'Campo obrigatório';
            },
            date_format: function(): string {
                return 'Data inválida. dd/mm/aaaa';
            },
            confirmed: function(): string {
                return 'As senhas não coincidem';
            },

            // custom
            card_expiration_date: function(): string {
                return VALIDATIONS.card_expiration_date.message;
            },
            cpf: function(): string {
                return VALIDATIONS.cpf.message;
            },
            telephone: function(): string {
                return VALIDATIONS.telephone.message;
            },
            fullname: function(): string {
                return VALIDATIONS.fullname.message;
            },
            date_min: function(): string {
                return VALIDATIONS.date_min.message;
            },
            date_max: function(): string {
                return VALIDATIONS.date_max.message;
            },
            document: function(): string {
                return VALIDATIONS.document.message;
            },
            card_brand: function(): string {
                return VALIDATIONS.card_brand.message;
            },
            transfer_info: function(): string {
                return VALIDATIONS.transfer_info.message;
            },
            past_date_or_today: function(): string {
                return VALIDATIONS.past_date_or_today.message;
            },
            travel_max_age: function(value: string, args): string {
                return VALIDATIONS.travel_max_age.message(args);
            },
        },
    },
};

const VALIDATIONS = {
    card_expiration_date: {
        method: function(dateString: string): boolean {
            if (!/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/.test(dateString)) {
                return false;
            }

            let month: string = '';
            let year: string = '';
            const momnow: dayjs.Dayjs = dayjs();

            if (dateString.split('/').length === 2) {
                month = dateString.split('/')[0];
                year = dateString.split('/')[1];
            } else if (dateString.length === 6) {
                month = dateString.substring(0, 2);
                year = dateString.substring(2);
            }

            if ((year.length === 2 && year > momnow.format('YY')) || year > momnow.format('YYYY')) {
                return true;
            }

            if ((year.length === 2 && year === momnow.format('YY')) || year === momnow.format('YYYY')) {
                return month >= momnow.format('MM');
            }

            return false;
        },
        message: 'Data inválida',
    },
    cpf: {
        method: function(value: string): boolean {
            function secondDigit(cpf: string): number {
                let mult: number = 11,
                    sum: number = 0;
                for (let i: number = 0; i < 10; i++) {
                    sum += mult * parseInt(cpf.charAt(i) + '');
                    mult--;
                }

                let rest: number = 11 - (sum % 11);
                if (rest >= 10) rest = 0;
                return rest;
            }

            function firstDigit(cpf: string): number {
                let mult: number = 10,
                    sum: number = 0;
                for (let i: number = 0; i < 9; i++) {
                    sum += mult * parseInt(cpf.charAt(i) + '');
                    mult--;
                }
                let rest: number = 11 - (sum % 11);
                if (rest >= 10) rest = 0;
                return rest;
            }

            const cpf: string = value.replace(/[\D]/g, '');
            // Regex: verifica se todos os digitos sao iguais)
            if (cpf.length !== 11 || /^([0-9])\1*$/.test(cpf)) {
                return false;
            }

            const digits: string = cpf.substring(9);
            const first: number = firstDigit(cpf);
            const second: number = secondDigit(cpf);
            const er: string = `${first}${second}`;
            return digits === er;
        },
        message: 'CPF inválido',
    },
    date_min: {
        method: function(date: string, min: number): boolean {
            return parseDate(date).isAfter(dayjs().add(-min, <dayjs.UnitType>'years'));
        },
        message: 'Data inválida. dd/mm/aaaa',
    },
    date_max: {
        method: function(date: string, max: number): boolean {
            return dayjs()
                .add(max, <dayjs.UnitType>'years')
                .isAfter(parseDate(date));
        },
        message: 'Data inválida. dd/mm/aaaa',
    },
    fullname: {
        method: function(value: string): boolean {
            if (value === null || value === '') {
                return false;
            }

            const t: string = value.trim().replace(/\s+/g, ' ');
            const sep: string[] = t.split(' ');
            if (sep.length === 1) {
                return false;
            }

            return true;
        },
        message: 'Preencha o nome completo',
    },
    telephone: {
        method: function(phone: string): boolean {
            const value: string = phone.replace(/[\D]/g, '').trim();
            return value.length === 10 || value.length === 11;
        },
        message: 'Use (dd)número',
    },
    document: {
        method: function(document: string): boolean {
            return /^[a-zA-Z0-9-. /\\]*$/.test(document) && /(.*[0-9]){3}/.test(document);
        },
        message: 'Documento inválido',
    },
    card_brand: {
        method: function(cardNumber: string): boolean {
            const card: CardIdentifier = CreditCardUtil.identifyCard(cardNumber);
            const cleanNumbers = cardNumber.replace(/ /g, '');
            return card.patternValidation.test(cleanNumbers);
        },
        message: 'Cartão inválido',
    },
    transfer_info: {
        method: function(transferInfo: string): boolean {
            return /^[0-9-/\\]*$/.test(transferInfo);
        },
        message: 'Somente números',
    },
    past_date_or_today: {
        method: function (value: string): boolean {
            const today = dayjs();
            const date = parseDate(value);

            return date.isBefore(today) || date.isSame(today);
        },
        message: 'Data inválida'
    },
    travel_max_age: {
        method: function(value: string, [maxAgeStr, lastTravelDate]: string[]): boolean {
            const birthdate = parseDate(value);
            const maxAge = parseInt(maxAgeStr);
            const arrivalDate = parseDate(lastTravelDate);

            const limitDate = birthdate.add(maxAge+1, <dayjs.UnitType>'years');
            return arrivalDate.isBefore(limitDate)
        },
        message: ([maxAge]: string[]) => `Idade máxima de ${maxAge} anos durante a viagem`,
    },
};

const PHONE_MASK: string[] = ['(##) ####-####', '(##) #####-####'];

export const CONSTANTS = {
    VISA_OPTIONS,
    MAESTRO_OPTIONS,
    ACCOUNT_DEBIT_OPTIONS,
    PAYMENT_OPTIONS_FORM_LIST,
    DICTIONARY,
    VALIDATIONS,
    PHONE_MASK,
    TRANSFER_BANK_OPTIONS,
};
