import { Component, Prop, Vue } from 'vue-property-decorator';
import LoadingModule from 'account/store/loading';
import { getModule } from 'vuex-module-decorators';
import store from 'account/store';

const loading: LoadingModule = getModule(LoadingModule, store);
@Component({
    name: 'GVNotify',
    template: require('./gv-notify.html'),
})
export default class GVNotify extends Vue {

    @Prop()
    title: string;

    @Prop()
    text: string;

    @Prop()
    img: string;

    @Prop({ required: false, type: String, default: 'texto' })
    buttonTitle: string;

    @Prop({ type: String, default: ''})
    urlTo: string;

    @Prop({ type: Boolean, default: true })
    showButton: boolean;

    @Prop({type: Boolean, default: true})
    isRouterLink: boolean;

    showLoading() {
        loading.show();
    }
}
