import { MDCDialog } from '@material/dialog';
import { MDCSnackbar } from '@material/snackbar';
import { Component, Vue } from 'vue-property-decorator';
import GVNotify from 'account/components/gv-notify';
import GVCreditCard from 'account/components/gv-credit-card';
import { PaymentService } from 'checkout/services/payment.service';
import { PaymentBrand } from 'checkout/enums/payment-brand.enum';
import LoadingModule from 'account/store/loading';
import { getModule } from 'vuex-module-decorators';
import store from 'account/store';
import { SnackbarIdentifier } from 'account/enums/snackbar-identifier.enum';
import { Dialog } from 'account/enums/dialog.enum';
import { AccountService } from 'account/services/account.service';
import { AccountAmplitude } from 'account/services/amplitude.service';
import { PaymentCardPageModule } from './module';
import { CreditCardModule } from 'account/store/module';
import { CreditCardDTO } from 'account/services/dto';
import { creditCardAdapter } from 'account/services/adapter';
import { PageName } from 'account/enums/page-name.enums';

const loading: LoadingModule = getModule(LoadingModule, store);

@Component({
    name: 'PaymentCardPage',
    template: require('./payment-card.page.html'),
    components: {
        'gv-notify': GVNotify,
        'gv-credit-card': GVCreditCard
    }
})
export default class PaymentCardPage extends Vue {
    private _paymentCardPageModule: PaymentCardPageModule = null;
    private _creditCardModule: CreditCardModule = null;

    created(): void {
        this._paymentCardPageModule = PaymentCardPageModule.getInstance();
        this._creditCardModule = CreditCardModule.getInstance();
    }
    mounted(): void {
        this.init();
        AccountAmplitude.accountOpen(PageName.PAYMENT_CARDS);
    }

    get hasInit(): boolean {
        return this._paymentCardPageModule.hasInit;
    }

    get cardIds(): Array<number> {
        return this._paymentCardPageModule.cardIds;
    }

    async init(): Promise<void> {
        if (this._paymentCardPageModule.hasInit)
            return;

        try {
            loading.startLoading();

            const creditCardDtos: Array<CreditCardDTO> = await PaymentService.getCreditCards();
            creditCardDtos.map(creditCardDto => creditCardAdapter(creditCardDto))
                .forEach(creditCard => {
                    this._creditCardModule.setCreditCard(creditCard);
                    this._paymentCardPageModule.insertCard(creditCard.id);
                });

            this._paymentCardPageModule.dispatchInit();
        }
        catch(e) {

        }
        finally {
            loading.finishLoading();
        }
    }

    /*
    * delete card
    */
    async deleteOneClick(id: number): Promise<void> {

        try {
            loading.startLoading();
            await AccountService.deleteOneClick(id);

            this._paymentCardPageModule.removeCard(id);

            AccountAmplitude.deleteCard();
            this.openSnackbar(SnackbarIdentifier.REMOVED_CARD);
        } catch (error) {
            this.openSnackbar(SnackbarIdentifier.ERROR);
            return;
        }
        finally {
            loading.finishLoading();
        }
    }

    openSnackbar(snackbarId: string): void {
        const snackbar: MDCSnackbar = new MDCSnackbar(document.querySelector(snackbarId));
        snackbar.open();
    }

    removeCard(id: number) {
        if (!id) {
            return;
        }

        const eventName = 'MDCDialog:closed';
        const removeDialog: MDCDialog = new MDCDialog(document.querySelector(Dialog.REMOVE_CARD_DIALOG));
        const eventListener = (event: any) => {
            if (event && event.detail.action === 'excluir') {
                this.deleteOneClick(id);
            }

            removeDialog.unlisten(eventName, eventListener);
        }

        removeDialog.listen(eventName, eventListener);
        removeDialog.open();
    }
}
