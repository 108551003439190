import { ReserveOperationDTO } from 'account/services/dto';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { parseDateToString } from 'vue-shared/utils/date';
import { ImgsUtil } from 'vue-shared/utils/imgs/imgs.util';

interface TravelCancelSelection {
    id: number;
    selected: boolean;
}

@Component({
    name: 'BsTravelCancelSelection',
    template: require('./bs-travel-cancel-selection.html'),
})
export default class BSTravelCancelSelectionComponent extends Vue {
    @Prop()
    travelInfoData: ReserveOperationDTO;

    @Prop()
    changeOperation: (location: string) => void;

    @Prop()
    selectReserves: (reserves: Array<number>) => void;

    back: boolean = false;

    selectedReserves = new Array<TravelCancelSelection>();


    toggleSelected(id: number) {
        const reserveData = this.reserves(null, false).find(reserve => reserve.id === id);
        // verify the seatTravelType to check for connections and stopovers because they need to be canceled together
        if(reserveData.seatTravelType === 'STOPOVER' || reserveData.seatTravelType === 'CONNECTION') {
            const reserves = this.reserves(null, false).filter(reserve =>
                reserve.passengerName === reserveData.passengerName
                && reserve.document === reserveData.document
                && reserve.seatTravelType === reserveData.seatTravelType);
            reserves.forEach(res => {
                const reserveIndex = this.selectedReserves.findIndex(reserve => reserve.id === res.id);
                if (reserveIndex === -1) {
                    this.selectedReserves.push({ id: res.id, selected: true });
                } else {
                    this.selectedReserves[reserveIndex].selected = !this.selectedReserves[reserveIndex].selected;
                }
            });
        } else {
            const reserve = this.selectedReserves.findIndex(reserve => reserve.id === id);
            if (reserve === -1) {
                this.selectedReserves.push({ id, selected: true });
            } else {
                this.selectedReserves[reserve].selected = !this.selectedReserves[reserve].selected;
            }
        }
    }

    isSelected(id: number) {
        return this.selectedReserves.find(reserve => reserve.id === id);
    }

    get selectedReserve() {
        return this.selectedReserves;
    }

    get anyReserveSelected() {
        let anySelected = false;
        this.selectedReserves.forEach(reserve => {
            if (reserve.selected) {
                anySelected = true;
            }
        })
        return anySelected;
    }

    get boardingPass() {
        return `${ImgsUtil.staticAws()}/_v2/static/img/icons/boarding-pass-icon.svg`
    }

    get travelInfo() {
        if(!this.travelInfoData) {
            return null;
        }
        return this.travelInfoData;
    }

    operation() {
        if(this.travelInfoData.operation) {
            return this.travelInfoData.operation;
        }
        return null;
    }

    reserves(origin: number, filteredInformation: boolean = true) {
        if(!this.travelInfoData.operation) {
            return [];
        }
        if (this.back) {

            return filteredInformation ? this.operation().reservedSeatsBack.filter(reserve => reserve.origin === origin) : this.operation().reservedSeatsBack;
        }
        return filteredInformation ? this.operation().reservedSeatsGo.filter(reserve => reserve.origin === origin) : this.operation().reservedSeatsGo;
    }

    get hasBack() {
        if(!this.travelInfoData.operation) {
            return false;
        }
        return this.operation().reservedSeatsBack.length > 0;
    }

    get origins() {
        const reserves = this.reserves(null, false);
        const reserveLocations = new Set<number>();

        reserves.forEach(reserve => {
            reserveLocations.add(reserve.origin);
        });

        return Array.from(reserveLocations);
    }

    departureDate(origin: number) {
        if(!this.travelInfoData.operation) {
            return '';
        }
        const date = new Date(this.reserves(origin)[0].departureDate);
        return parseDateToString(date, "Dow, DD mth");
    }

    companyName(origin: number) {
        if(!this.travelInfoData.operation) {
            return '';
        }
        return this.reserves(origin)[0].companyKey;
    }

    departureTime(origin: number) {
        if(!this.travelInfoData.operation) {
            return '';
        }
        const date = new Date(this.reserves(origin)[0].departureDate);
        return parseDateToString(date, "hh:mm");
    }

    arrivalTime(origin: number) {
        if(!this.travelInfoData.operation) {
            return '';
        }
        const reserves = this.reserves(origin);
        const date = new Date(reserves[reserves.length - 1].arrivalDate);
        return parseDateToString(date, "hh:mm");
    }

    passengers(origin) {
        if(!this.travelInfoData.operation) {
            return '';
        }
        return this.reserves(origin).filter(reserve => reserve.origin && reserve.status !== 'CANCELLED');
    }



    hasEasyBoarding(origin: number) {
        if(!this.travelInfoData.operation) {
            return '';
        }
        let easyBoarding = this.reserves(origin).find(reserve => reserve.easyBoarding);
        return easyBoarding ? true : false;
    }

    departurePlace(origin: number) {
        if(!this.travelInfoData.operation) {
            return '';
        }
        const id = this.reserves(origin)[0].origin;
        const location = this.travelInfoData.busStation.find(station => station.id === id);
        return location.label;
    }

    arrivalPlace(origin: number) {
        if(!this.travelInfoData.operation) {
            return '';
        }
        const reserves = this.reserves(origin);
        const id = reserves[reserves.length - 1].destination;
        const location = this.travelInfoData.busStation.find(station => station.id === id);
        return location.label;
    }

    companyLogo(origin: number) {
        if(!this.travelInfoData.operation) {
            return '';
        }
        const companyKey = this.reserves(origin)[0].companyKey;
        const company = this.travelInfoData.busCompany.find(station => station.key === companyKey);
        return company.logo;
    }

    departurePlaceUrl(origin: number) {
        if(!this.travelInfoData.operation) {
            return '';
        }
        const id = this.reserves(origin)[0].origin;
        const location = this.travelInfoData.busStation.find(station => station.id === id);
        return '/rodoviaria-' + location.url;
    }

    get haveConnection() {
        return this.origins.length > 1;
    }

    orderInfo(index: number) {
        switch(index) {
            case 0:
                return 'Primeiro';
            case 1:
                return 'Segundo';
            case 2:
                return 'Terceiro';
            case 3:
                return 'Quarto';
            default:
                return index+'º';
        }
    }

    toggleBack() {
        this.back = !this.back;
    }

    goToConfirmation() {
        const reserves = this.selectedReserves.filter(reserve => reserve.selected).map(reserve => reserve.id);
        this.selectReserves(reserves)
        this.changeOperation('completeCancel')
    }
}
