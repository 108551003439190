import * as AmplitudeService from '../../../../../src/_v2/application/components/amplitude/amplitude-config';
import { AccountAmplitudeEvent } from '../enums/amplitude-events.enums';
import { AccountOpenAmplitude } from 'account/interfaces/account-open-amplitude.interface';
import { PageName } from 'account/enums/page-name.enums';

class Service {

    /*
    * User accessed the account page
    */
    accountOpen(pageName: PageName): void {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.ACCOUNT_OPEN, { pageName });
    }

    /*
    * User clicked edit profile button
    */
    editProfileBtn(): void {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.BTN_EDIT_PROFILE);
    }

    /*
    * User save update profile
    */
    saveUpdateProfile(): void {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.SAVE_UPDATE_PROFILE_PAGE, {
            description: 'User updated info profile'
        });
    }

    /*
    * User toggled newsletter
    */
    saveToggleNewsletter(): void {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.TOGGLE_NEWSLETTER_PROFILE_PAGE, {
            description: 'User toggled newsletter'
        });
    }

    /*
    * User save update access data
    */
    saveUpdateAccessData(): void {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.SAVE_UPDATE_ACCESS_DATA_PAGE, {
            description: 'User updated password'
        });
    }

    /*
    * User delete card
    */
    deleteCard(): void {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.DELETE_CARD);
    }

    /*
    * User save new card
    */
    saveNewCard(): void {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.SAVE_NEW_CARD, {
            description: 'User added new card in cards page'
        });
    }

    /*
    * User opened travel details page
    */
    travelDetailsOpen(): void {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.TRAVEL_DETAIL);
    }

    /*
    * event sidemenu
    */
    buyTicket(): void {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.BUY_TICKET);
    }

    partialCancel(): void {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.PARTIAL_CANCEL);
    }

    fullCancel(): void {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.FULL_CANCEL);
    }

    cancellation(type: string) {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.CANCELLATION, {
            description: type
        })
    }

    swapModal() {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.SWAP_CLICK)
    }

    swapModalClose() {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.SWAP_CANCEL)

    }

    swapModalConfirm() {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.SWAP_CONFIRM)
    }

    swapConfirmPassenger() {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.SWAP_CONFIRM_PASSENGER)
    }

    swapBackPassenger() {
        AmplitudeService.sendAmplitudeEvent(AccountAmplitudeEvent.SWAP_CONFIRM_PASSENGER)
    }
}

export const AccountAmplitude: Service = new Service();
