import { PaymentBrand } from 'checkout/enums/payment-brand.enum';
import { PaymentType } from 'checkout/enums/payment-type.enum';
import { BuyerRequest } from './buyer.request';
import { CardRequest } from './card.request';
import { TransferRequest } from './transfer.request';

export class PaymentRequest {
    type: PaymentType = PaymentType.PIX;
    brand: PaymentBrand = PaymentBrand.DEFAULT;
    installmentsAmount: number = 1;
    oneClickId: string = '';
    chargeInsurance: boolean = false;
    buyerInfo: BuyerRequest;
    card: CardRequest;
    transfer: TransferRequest;

    constructor() {
        this.buyerInfo = new BuyerRequest();
        this.card = new CardRequest();
        this.transfer = new TransferRequest();
    }
}
