export enum DialogIdentifier {
    BOOKING_EXPIRED = '#booking-expired-dialog',
    PAYMENT_DECLINED = '#payment-declined-dialog',
    GENERAL_FAIL = '#general-fail-dialog',
    WAITING_CONFIRMATION = '#waiting-confirmation-dialog',
    PAYMENT_CANCELED = '#payment-canceled-dialog',
    TERMS_DIALOG = '#terms-dialog',
    PRIVACY_DIALOG = '#privacy-dialog',
    CANCEL_DIALOG = '#cancel-dialog',
    AUTHENTICATED_SALE_DIALOG = '#authenticated-sale-dialog',
    INSURANCE_DIALOG = '#insurance-dialog',
    FOREIGN_CARD_DIALOG = '#foreign-card-dialog',
    CREDIT_DEBIT_DIALOG = '#credit-debit-dialog',
    DEBIT_CREDIT_DIALOG = '#debit-credit-dialog',
    PRICE_CHANGE_DIALOG = '#price-change-dialog',
    USED_PASSENGER_DIALOG = '#used-passenger-dialog',
    DISCOUNT_DIALOG = '#discount-dialog',
    TAX_INFO_DIALOG = '#tax-info-dialog',
    CVV_INFO_DIALOG = '#cvv-info-dialog',
    DOCUMENT_INFO_DIALOG = '#document-info-dialog',
    UNDER_SIX_YEARS_INFO_DIALOG = '#under-six-years-info-dialog',
    STUDENT_TICKET_DIALOG = '#student-ticket-dialog',
}
