import { DialogUtil } from 'checkout/utils/dialog/dialog.util';
import { Dictionary, Route, VueRouter } from 'vue-router/types/router';

class Util {
    pushRoute(router: VueRouter, route: Route): void {
        router.push({ query: { ...route.query, modal: 'true' } });
    }

    replaceRoute(router: VueRouter, route: Route): void {
        const query: Dictionary<string | string[]> = route.query;
        delete query.modal;

        router.replace({ query });
        // window.history.replaceState({}, document.title, route.path);
    }

    onQueryChange(route: Route): void {
        if (!route.query.modal) {
            DialogUtil.closeOpenedModal();
        }
    }
}

export const RouterUtil: Util = new Util();
