import { OperationStatus } from 'checkout/enums/operation-status.enum';
import { HttpClient } from 'commons/ts/http.client';
import { ReserveOperationDTO } from '../dto';
import { UserTravelsDTO } from './dto';

export default class TravelService {
    private static _instance: TravelService = new TravelService();

    static getInstance(): TravelService {
        return this._instance;
    }

    private constructor() { }

    async getFutureTravels(pageIdx: number, pageSize: number): Promise<UserTravelsDTO> {
        const params = {
            pageIdx, pageSize,
            travelKind: 'FUTURE',
            expired: false
        }

        return HttpClient.get({ url: '/t/api/account/users/travels', params });
    }

    async getTravelsByStatus(pageIdx: number, pageSize: number, status: OperationStatus[]): Promise<UserTravelsDTO> {
        const params = {
            pageIdx, pageSize,
            byStatus: true,
            status: status.join(',')
        }

        return HttpClient.get({ url: '/t/api/account/users/travels', params });
    }


    async getReserveFromTravel(operationId: number, email: string): Promise<ReserveOperationDTO> {
        return HttpClient.get({ url: `/api/operation/${operationId}?email=${encodeURIComponent(email)}&withCancelReserves=true` });
    }

    async cancelReserve(operationId: number, operationEmail: string): Promise<{ status: number }> {
        return HttpClient.post('/t/api/pagamento/cancel', { operationId, operationEmail });
    }

    async partialCancel(operationId: number, operationEmail: string, reserveIds: number[]): Promise<any> {
        return HttpClient.put('/t/api/pagamento/partial-cancel', { operationId, operationEmail, reserveIds });
    }
}
