import { ReserveOperationDTO } from 'account/services/dto';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { parseDateToString } from 'vue-shared/utils/date';
import { ImgsUtil } from 'vue-shared/utils/imgs/imgs.util';

@Component({
    name: 'BsTravelPayment',
    template: require('./bs-travel-payment.html'),
})
export default class BSTravelPaymentComponent extends Vue {
    @Prop()
    travelInfoData: ReserveOperationDTO;

    active = false;

    get isActive() {
        return this.active;
    }

    toggleActive() {
        this.active = !this.active;
    }

    get dropdownIcon() {
        return this.active ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    }

    get boardingPass() {
        return `${ImgsUtil.staticAws()}/_v2/static/img/icons/boarding-pass-icon.svg`
    }

    get travelInfo() {
        if(!this.travelInfoData) {
            return null;
        }
        return this.travelInfoData;
    }

    operation() {
        if(this.travelInfoData.operation) {
            return this.travelInfoData.operation;
        }
        return null;
    }

    get buyerName() {
        if(this.travelInfoData.operation) {
            return this.travelInfoData.payment.buyerInfo.name;
        }
        return null;
    }
    get paymentDate() {
        if(this.travelInfoData.operation) {
            return this.travelInfoData.operation.purchaseDate;
        }
        return null;
    }

    get paymentType() {
        if(this.travelInfoData.operation) {
            return this.travelInfoData.operation.formaPagamento;
        }
        return null;
    }

    get totalAmount() {
        if(this.travelInfoData.operation) {
            return 'R$ ' + this.travelInfoData.operation.totalValue.toFixed(2).replace('.', ',');
        }
        return 'R$ 0,00';
    }

    get totalTicket() {
        if(this.travelInfoData.operation) {
            return 'R$ ' + this.travelInfoData.operation.ticketValue.toFixed(2).replace('.', ',');
        }
        return 'R$ 0,00';
    }

    get insuranceAmount() {
        if(this.travelInfoData.operation) {
            return 'R$ ' + this.travelInfoData.operation.insurance.toFixed(2).replace('.', ',');
        }
        return 'R$ 0,00';
    }

    get taxesAmount() {
        if(this.travelInfoData.operation) {
            return 'R$ ' + (this.travelInfoData.operation.totalValue - this.travelInfoData.operation.ticketValue).toFixed(2).replace('.', ',');
        }
        return 'R$ 0,00';
    }

    get interestValue() {
        if(this.travelInfoData.operation) {
            return 'R$ ' + this.travelInfoData.operation.interestValue.toFixed(2).replace('.', ',');
        }
        return 'R$ 0,00';
    }

    get discountValue() {
        if(this.travelInfoData.operation && this.travelInfoData.operation.priceComposition) {
            const {originalTicketValue, discountPercent} = this.travelInfoData.operation.priceComposition;
            return  'R$ ' + (originalTicketValue * discountPercent).toFixed(2).replace('.', ',');
        }
        return 'R$ 0,00';
    }

    get bpe() {
        if(this.travelInfoData.operation) {
            return this.travelInfoData.operation.easyBoardLink;
        }
        return null;
    }

    get status() {
        if(this.travelInfoData.operation) {
            switch (this.travelInfoData.operation.status) {
                case "PAID": return '<span class="green">Pago</span>';
                case "CANCEL": return '<span class="red">Cancelado</span>';
                case "EXPIRED": return '<span class="gray">Expirado</span>';
                case "PENDING": return '<span class="orange">Pendente</span>';
                case "FAIL": return '<span class="orange">Falha - em processamento</span>';
                case "RESERVED": return '<span class="green">Reservado</span>';
                case "PAYMENT_REJECTED": return '<span class="red">Pagamento rejeitado</span>';
                case "BOLETO_PENDENTE": return '<span class="orange">Boleto pendente</span>';
                case "PARTIAL_CANCEL": return '<span class="yellow">Parcialmente cancelado</span>';
                case "OFFLINE_TICKETS_PENDING": return '<span class="orange">Pendente</span>';
            }
        }
        return null;
    }

    get paymentInfo() {
        return [
            {
                label: 'Titular da compra',
                value: this.buyerName,
            },
            {
                label: 'Data da compra',
                value: parseDateToString(new Date(this.paymentDate), 'DD/MM/YYYY hh:mm'),
            },
            {
                label: 'Tipo de pagamento',
                value: this.paymentType,
            },
            {
                label: 'Total dos bilhetes',
                value: this.totalTicket,
            },
            {
                label: 'Seguro',
                value: this.insuranceAmount,
            },
            {
                label: 'Impostos e taxas',
                value: this.taxesAmount,
            },
            {
                label: 'Juros',
                value: this.interestValue,
            },
            {
                label: 'Valor do desconto',
                value: this.discountValue,
            },
            {
                label: 'Status',
                value: this.status,
            }
        ]
    }
}
