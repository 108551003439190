import {
    Action,
    Module,
    Mutation,
    VuexModule,
    getModule
} from 'vuex-module-decorators';
import { Store } from 'account/store';

@Module({
    name: 'payment-card-page',
    namespaced: true,
})
export class PaymentCardPageModule extends VuexModule {
    private _hasInit: boolean = false;
    private _creditCards: Array<number> = [];

    static getInstance(): PaymentCardPageModule {
        return getModule(PaymentCardPageModule, Store.getInstance());
    }

    get cardIds(): Array<number> {
        return [...this._creditCards];
    }

    get hasInit(): boolean {
        return this._hasInit;
    }

    @Mutation
    private _insertCard(creditCardId: number): void {
        if (!this._creditCards.includes(creditCardId))
            this._creditCards.push(creditCardId);
    }

    @Action
    insertCard(creditCardId: number): void {
        this.context.commit('_insertCard', creditCardId);
    }

    @Mutation
    private _removeCard(creditCardId: number): void {
        const index: number = this._creditCards.findIndex(cardId => cardId === creditCardId);
        if (index !== -1) {
            this._creditCards.splice(index, 1);
        }
    }

    @Action
    removeCard(creditCardId: number): void {
        this.context.commit('_removeCard', creditCardId);
    }

    @Mutation
    private _dispatchInit(): void {
        this._hasInit = true;
    }

    @Action
    dispatchInit(): void {
        this.context.commit('_dispatchInit');
    }
}