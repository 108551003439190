import {
    Action,
    Module,
    Mutation,
    VuexModule,
    getModule
} from 'vuex-module-decorators';
import { Store } from 'account/store';
import { BusCompany } from 'account/model';

@Module({
    name: 'bus-company',
    namespaced: true,
})
export default class BusCompanyModule extends VuexModule {
    /**
     * Repository of BusCompany.
     * 
     * It's a map from BusCompany.key to BusCompany.
     */
    private _repo: Map<string, BusCompany> = new Map<string, BusCompany>();

    static getInstance(): BusCompanyModule {
        return getModule(BusCompanyModule, Store.getInstance());
    }

    @Mutation
    private _insertBusCompany(busCompany: BusCompany): void {
        this._repo.set(busCompany.key, busCompany);
    }

    @Action
    insertBusCompany(busCompany: BusCompany): void {
        this.context.commit('_insertBusCompany', busCompany);
    }

    get getNickname(): (companyKey: string, def?: string) => string {
        return (companyKey: string, def: string = ""): string => {
            if (this._repo.has(companyKey))
                return this._repo.get(companyKey).nickname;

            return def;
        }
    }

    get getLogoId(): (companyKey: string) => number {
        return (companyKey: string): number => {
            if (this._repo.has(companyKey))
                return this._repo.get(companyKey).logo;

            return -1;
        }
    }
}