import { RouterPath } from 'account/enums/router-path.enum';
import { UserStorage } from 'account/enums/user.enum';
import { UserInfo } from 'account/interfaces/user-info.interface';
import { AccountService } from 'account/services/account.service';
import { AuthenticationService } from 'account/services/authentication.service';
import store from 'account/store';
import AccountModule from 'account/store/account';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import ProfilePage from '../pages/profile';
import TravelPage from 'account/pages/travel';
import PaymentCardPage from 'account/pages/payment-card';
import AccessDataPage from 'account/pages/access-data';
import UpdateProfile from 'account/pages/update-profle';
import RegisterCard from 'account/pages/register-card';
import WalletPage from 'account/pages/wallet';
import TravelInfoPage from 'account/pages/travel-info';
import GenericInfoPage from 'account/pages/generic-info';
import NotificationPage from 'account/pages/notification/notification.page';

Vue.use(VueRouter);

const account: AccountModule = getModule(AccountModule, store);

class Router extends VueRouter {
    constructor() {
        super({
            mode: 'history',
            routes: [
                {
                    path: RouterPath.HOME,
                    name: RouterPath.HOME,
                    component: ProfilePage,
                },
                {
                    path: RouterPath.UPDATE_PROFILE,
                    name: RouterPath.UPDATE_PROFILE,
                    component: UpdateProfile,
                },
                {
                    path: RouterPath.TRAVEL,
                    name: RouterPath.TRAVEL,
                    component: TravelPage,
                },
                {
                    path: RouterPath.PAYMENT_CARD,
                    name: RouterPath.PAYMENT_CARD,
                    component: PaymentCardPage,
                },
                {
                    path: RouterPath.REGISTER_CARD,
                    name: RouterPath.REGISTER_CARD,
                    component: RegisterCard,
                },
                {
                    path: RouterPath.ACCESS_DATA,
                    name: RouterPath.ACCESS_DATA,
                    component: AccessDataPage,
                },
                {
                    path: RouterPath.WALLET,
                    name: RouterPath.WALLET,
                    component: WalletPage,
                },
                {
                    path: RouterPath.GENERIC_INFO,
                    name: RouterPath.GENERIC_INFO,
                    component: GenericInfoPage
                },
                {
                    path: RouterPath.TRAVEL_INFO,
                    name: RouterPath.TRAVEL_INFO,
                    component: TravelInfoPage,
                },
                {
                    path: RouterPath.NOTIFICATION,
                    name: RouterPath.NOTIFICATION,
                    component: NotificationPage,
                },
                //
                // The following routes are not going into the first release of meu-perfil
                // {
                //     path: RouterPath.DETAIL_TRAVEL,
                //     name: RouterPath.DETAIL_TRAVEL,
                //     component: TravelDetailPage,
                // },
                // {
                //     path: RouterPath.REVIEW,
                //     name: RouterPath.REVIEW,
                //     component: ReviewPage,
                // },
            ],
        });

        this.beforeEach(this.onBeforeEach);
    }

    private onBeforeEach = async (to, from, next) => {
        if (!account.userInfo) {
            try {
                const isAuthenticated: boolean = await AuthenticationService.isAuthenticated();

                if (!isAuthenticated) {
                    throw "Not authenticated";
                }

                const userInfo: UserInfo = await AccountService.getUserInfo();

                if (!userInfo.email) {
                    throw "Not authenticated";
                }

                if (to.path == RouterPath.WALLET && !userInfo.features.includes("WALLET")) {
                    throw "Wallet is blocked";
                }

                account.setUserInfo(userInfo);
                localStorage.setItem(UserStorage.EMAIL, userInfo.email.toLowerCase().trim());
            }
            catch(e) {
                if(e === "Not authenticated"){
                    return await this.abort(true);
                }
                await this.abort();
                return;
            }
        }
        else if (to.path == RouterPath.WALLET && !account.isWalletEnable) {
            await this.abort();
            return;
        }
        else if ((to.path == RouterPath.TRAVEL_INFO || to.path == RouterPath.GENERIC_INFO)
                && !account.isPartialCancelEnable) {
            await this.abort();
            return;
        }

        next();
    };

    private abort = async (alert = false): Promise<void> => {
        await this.logout();
        if(alert) {
            window.location.href = "/login?alert=true";
            return;
        }
        window.location.href = "/login";
    }

    private logout = async (): Promise<void> => {
        try {
            await AuthenticationService.unauthenticate();
        }
        catch(e) {
            window.location.href = "/";
        }
    }
}

export const AppRouterAccount: Router = new Router();
