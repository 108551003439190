import {
    Action,
    Module,
    Mutation,
    VuexModule,
    getModule
} from 'vuex-module-decorators';
import { Operation } from 'account/model';
import { OperationStatus } from 'account/model/enum';
import { Store } from 'account/store';

@Module({
    name: 'operation',
    namespaced: true,
})
export default class OperationModule extends VuexModule {
    /**
     * Repository of bus stations.
     *
     * It's a map from BusStation.id to BusStation.
     */
    private _repo: Map<number, Operation> = new Map<number, Operation>();

    static getInstance(): OperationModule {
        return getModule(OperationModule, Store.getInstance());
    }

    @Mutation
    private _insertOperation(operation: Operation): void {
        this._repo.set(operation.id, operation);
    }

    @Action
    insertOperation(operation: Operation): void {
        this.context.commit("_insertOperation", operation);
    }

    get getOriginId(): (operationId: number) => number {
        return (operationId: number): number => {
            if (this._repo.has(operationId))
                return this._repo.get(operationId).origin;

            return -1;
        }
    }

    get getDestinationId(): (operationId: number) => number {
        return (operationId: number): number => {
            if (this._repo.has(operationId))
                return this._repo.get(operationId).destination;

            return -1;
        }
    }

    get getSeatReservesGo(): (operationId: number) => Array<number> {
        return (operationId: number): Array<number> => {
            if (this._repo.has(operationId))
                return [...this._repo.get(operationId).reservedSeatsGo];

            return [];
        }
    }

    get getSeatReservesBack(): (operationId: number) => Array<number> {
        return (operationId: number): Array<number> => {
            if (this._repo.has(operationId))
                return [...this._repo.get(operationId).reservedSeatsBack];

            return [];
        }
    }

    get getStatus(): (operationId: number) => OperationStatus {
        return (operationId: number): OperationStatus => {
            if (this._repo.has(operationId))
                return this._repo.get(operationId).status;

            return -1;
        }
    }
}
