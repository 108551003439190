import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import sidebar from './sidebar';
import modalLogin from './modal-login';

Vue.use(Vuex);

const store: StoreOptions<{}> = {
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        sidebar,
        modalLogin
    },
};

export default new Vuex.Store<{}>(store);
