import { MDCDialog } from '@material/dialog';
import { DialogIdentifier } from 'checkout/enums/dialog-identifier.enum';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
    name: 'dialog',
    namespaced: true,
})
export default class DialogModule extends VuexModule {
    message: string = '';
    openedModal: MDCDialog = null;
    openedModalName: string = '';

    get shouldCloseModal(): boolean {
        return (
            this.openedModalName !== DialogIdentifier.CREDIT_DEBIT_DIALOG &&
            this.openedModalName !== DialogIdentifier.DEBIT_CREDIT_DIALOG &&
            this.openedModalName !== DialogIdentifier.FOREIGN_CARD_DIALOG &&
            this.openedModalName !== DialogIdentifier.USED_PASSENGER_DIALOG &&
            this.openedModalName !== DialogIdentifier.CVV_INFO_DIALOG
        );
    }

    @Mutation
    setMessageMutation(message: string): void {
        this.message = message;
    }

    @Action({ commit: 'setMessageMutation' })
    setMessage(message: string): string {
        return message;
    }

    @Mutation
    setOpenedModalMutation(openedModal: HTMLElement): void {
        this.openedModal = openedModal;
    }

    @Action({ commit: 'setOpenedModalMutation' })
    setOpenedModal(openedModal: HTMLElement): HTMLElement {
        return openedModal;
    }

    @Mutation
    setOpenedModalNameMutation(openedModalName: string): void {
        this.openedModalName = openedModalName;
    }

    @Action({ commit: 'setOpenedModalNameMutation' })
    setOpenedModalName(openedModalName: string): string {
        return openedModalName;
    }
}
