import { OneClickCard } from 'checkout/interfaces/one-click-card.interface';
import { Installment } from 'checkout/models/installment.model';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
@Module({
    name: 'credit',
    namespaced: true,
})
export default class CreditModule extends VuexModule {
    updatingValues: boolean = false;
    installments: Installment[] = null;
    chosenInstallment: Installment = null;

    chosenInstallmentIndex: number = !!window['adapterData'] && window['adapterData'].improveInstallments ? null : 0;
    
    oneClickCards: Array<OneClickCard> = null;
    selectedOneClickCard: OneClickCard = null;

    get isOneClickSelected(): boolean {
        return this.isOneClick && this.selectedOneClickCard !== null;
    }

    get isOneClick(): boolean {
        return !!this.oneClickCards && this.oneClickCards.length > 0;
    }

    @Mutation
    initializeMutation(adapterData: any): void {
        this.installments = adapterData.useInsurance.paymentMethods[0].installments;
        this.chosenInstallment = window['adapterData'].improveInstallments
            ? null
            : this.installments[this.chosenInstallmentIndex];

        this.oneClickCards = adapterData.oneClickCards;
        // esse trecho abaixo inicializa com oneclick selecionado
        // if (!!this.oneClickCards && this.oneClickCards.length) {
        //     this.selectedOneClickCard = this.oneClickCards[0];
        // }
    }

    @Action({ commit: 'initializeMutation' })
    initialize(adapterData: any): any {
        return adapterData;
    }

    @Mutation
    setInstallmentsMutation(installments: Array<Installment>): void {
        this.installments = installments;
        this.chosenInstallment = this.installments[this.chosenInstallmentIndex];
    }

    @Action({ commit: 'setInstallmentsMutation' })
    setInstallments(installments: Array<Installment>): Array<Installment> {
        return installments;
    }

    @Mutation
    setChosenInstallmentIndexMutation(index: number): void {
        this.chosenInstallmentIndex = index;
    }

    @Action({ commit: 'setChosenInstallmentIndexMutation' })
    setChosenInstallmentIndex(index: number): number {
        return index;
    }

    @Mutation
    setChosenInstallmentMutation(installment: Installment): void {
        this.chosenInstallment = installment;
    }

    @Action({ commit: 'setChosenInstallmentMutation' })
    setChosenInstallment(installment: Installment): Installment {
        this.setChosenInstallmentIndex(this.installments.indexOf(installment));
        return installment;
    }

    @Mutation
    setSelectedOneClickCardMutation(selectedOneClickCard: OneClickCard): void {
        this.selectedOneClickCard = selectedOneClickCard;
    }

    @Action({ commit: 'setSelectedOneClickCardMutation' })
    setSelectedOneClickCard(selectedOneClickCard: OneClickCard): OneClickCard {
        return selectedOneClickCard;
    }

    @Mutation
    setOneClickCardsMutation(oneClickCards: Array<OneClickCard>): void {
        this.oneClickCards = oneClickCards;
        if (!!this.oneClickCards && this.oneClickCards.length) {
            this.selectedOneClickCard = this.oneClickCards[0];
        }
    }

    @Action({ commit: 'setOneClickCardsMutation' })
    setOneClickCards(oneClickCards: Array<OneClickCard>): Array<OneClickCard> {
        return oneClickCards;
    }

    @Mutation
    setUpdatingValuesMutation(updatingValues: boolean): void {
        this.updatingValues = updatingValues;
    }

    @Action({commit: 'setUpdatingValuesMutation'})
    setUpdatingValues(updatingValues: boolean) {
        return updatingValues;
    }

}
