import { RouterPath } from 'account/enums/router-path.enum';
import { Vue, Component } from 'vue-property-decorator';

@Component({
    name: 'GenericInfoPage',
    template: require('./generic-info.page.html'),
})
export default class GenericInfoPage extends Vue {
    messageType: string;

    created() {
        this.messageType = this.$route.query.messageType as string;
    }

    get message() {
        switch (this.messageType) {
            case 'warning-cancel':
                return {
                    color: 'bs-warning',
                    icon: 'error',
                    title: 'Esta passagem não pode mais ser cancelada',
                };
            case 'error-cancel':
                return {
                    color: 'bs-danger',
                    icon: 'cancel',
                    title: 'Não foi possível cancelar sua passagem.',
                };
            case 'success-cancel':
                return {
                    color: 'bs-success',
                    icon: 'check',
                    title: 'Sua passagem foi cancelada com sucesso!',
                };
            case 'error-cancel-try-again':
            default:
                return {
                    color: 'bs-danger',
                    icon: 'cancel',
                    title: 'Não foi possível cancelar sua passagem.',
                };
        }
    }

    get type() {
        return this.messageType;
    }

    goToTravel() {
        this.$router.push({ name: RouterPath.TRAVEL });
    }
}
