import VeeValidate from 'vee-validate';
import { CONSTANTS } from 'vue-shared/utils/constants';

VeeValidate.Validator.extend('card_expiration_date', {
    getMessage: function(): string {
        return CONSTANTS.VALIDATIONS.card_expiration_date.message;
    },
    validate: function(value: string): boolean {
        return CONSTANTS.VALIDATIONS.card_expiration_date.method(value);
    },
});

VeeValidate.Validator.extend('cpf', {
    getMessage: function(): string {
        return CONSTANTS.VALIDATIONS.cpf.message;
    },
    validate: function(value: string): boolean {
        return CONSTANTS.VALIDATIONS.cpf.method(value);
    },
});

VeeValidate.Validator.extend('date_min', {
    getMessage: function(): string {
        return CONSTANTS.VALIDATIONS.date_min.message;
    },
    validate: function(value: string, min: number): boolean {
        return CONSTANTS.VALIDATIONS.date_min.method(value, min);
    },
});

VeeValidate.Validator.extend('date_max', {
    getMessage: function(): string {
        return CONSTANTS.VALIDATIONS.date_max.message;
    },
    validate: function(value: string, max: number): boolean {
        return CONSTANTS.VALIDATIONS.date_max.method(value, max);
    },
});

VeeValidate.Validator.extend('telephone', {
    getMessage: function(): string {
        return CONSTANTS.VALIDATIONS.telephone.message;
    },
    validate: function(value: string): boolean {
        return CONSTANTS.VALIDATIONS.telephone.method(value);
    },
});

VeeValidate.Validator.extend('fullname', {
    getMessage: function(): string {
        return CONSTANTS.VALIDATIONS.fullname.message;
    },
    validate: function(value: string): boolean {
        return CONSTANTS.VALIDATIONS.fullname.method(value);
    },
});

VeeValidate.Validator.extend('document', {
    getMessage: function(): string {
        return CONSTANTS.VALIDATIONS.document.message;
    },
    validate: function(value: string): boolean {
        return CONSTANTS.VALIDATIONS.document.method(value);
    },
});

VeeValidate.Validator.extend('card_brand', {
    getMessage: function(): string {
        return CONSTANTS.VALIDATIONS.card_brand.message;
    },
    validate: function(value: string): boolean {
        return CONSTANTS.VALIDATIONS.card_brand.method(value);
    },
});

VeeValidate.Validator.extend('transfer_info', {
    getMessage: function(): string {
        return CONSTANTS.VALIDATIONS.transfer_info.message;
    },
    validate: function(value: string): boolean {
        return CONSTANTS.VALIDATIONS.transfer_info.method(value);
    },
});

VeeValidate.Validator.extend('past_date_or_today', {
    getMessage: function(): string {
        return CONSTANTS.VALIDATIONS.past_date_or_today.message;
    },
    validate: function(value: string): boolean {
        return CONSTANTS.VALIDATIONS.past_date_or_today.method(value);
    },
});

VeeValidate.Validator.extend('travel_max_age', {
    getMessage: function(value, args): string {
        return CONSTANTS.VALIDATIONS.travel_max_age.message(args);
    },
    validate: function(value: string, args): boolean {
        return CONSTANTS.VALIDATIONS.travel_max_age.method(value, args);
    },
});
