export function parserPaymentBrandToBgImg(paymentBrand: PaymentBrand): string {
    switch (paymentBrand) {
        case PaymentBrand.MASTERCARD: return 'mastercard-bg.png';
        case PaymentBrand.VISA: return 'visa-bg.png';
        case PaymentBrand.AMEX: return 'amex-bg.png';
        case PaymentBrand.ELO: return 'elo-bg.png';
        case PaymentBrand.DISCOVER: return 'discover-bg.png';
        case PaymentBrand.HIPERCARD: return 'hipercard-bg.png';
        default: return 'default-bg.png';
    }
}

export function parserPaymentBrandToImg(paymentBrand: PaymentBrand): string {
    switch(paymentBrand) {
        case PaymentBrand.MASTERCARD: return 'master-card-bg.png';
        case PaymentBrand.VISA: return 'Visa1.png';
        case PaymentBrand.AMEX: return 'amex.png';
        case PaymentBrand.ELO: return 'elo.png';
        case PaymentBrand.DISCOVER: return 'discover.png';
        case PaymentBrand.HIPERCARD: return 'hipercard.png';
        default: return '';
    }
}

export function parserPaymentBrandToString(paymentBrand: PaymentBrand): string {
    switch(paymentBrand) {
        case PaymentBrand.MASTERCARD: return "Mastercard";
        case PaymentBrand.VISA: return "Visa";
        case PaymentBrand.AMEX: return "Amex";
        case PaymentBrand.ELO: return "Elo";
        case PaymentBrand.DISCOVER: return "Discover";
        case PaymentBrand.HIPERCARD: return "Hipercard";
        default: return "";
    }
}

export function parserStringToPaymentBrand(paymentBrand: string) {
    switch(paymentBrand) {
        case 'DEBITO_ITAU': return PaymentBrand.DEBITO_ITAU;
        case 'DEBITO_SANTANDER_MAESTRO': return PaymentBrand.DEBITO_SANTANDER_MAESTRO;
        case 'DEBITO_BRB_MAESTRO': return PaymentBrand.DEBITO_BRB_MAESTRO;
        case 'DEBITO_CITIBANK_MAESTRO': return PaymentBrand.DEBITO_CITIBANK_MAESTRO;
        case 'DEBITO_BRASIL_MAESTRO': return PaymentBrand.DEBITO_BRASIL_MAESTRO;
        // visa debit
        case 'DEBITO_BRASIL': return PaymentBrand.DEBITO_BRASIL;
        case 'DEBITO_ITAU_VISA': return PaymentBrand.DEBITO_ITAU_VISA;
        case 'DEBITO_BRADESCO': return PaymentBrand.DEBITO_BRADESCO;
        case 'DEBITO_HSBC': return PaymentBrand.DEBITO_HSBC;
        case 'DEBITO_SANTANDER': return PaymentBrand.DEBITO_SANTANDER;
        case 'DEBITO_BRB': return PaymentBrand.DEBITO_BRB;
        // boleto
        case 'BANK_SLIP': return PaymentBrand.BANK_SLIP;
        // credit
        case 'MASTERCARD': return PaymentBrand.MASTERCARD;
        case 'AMEX': return PaymentBrand.AMEX;
        case 'ELO': return PaymentBrand.ELO;
        case 'DISCOVER': return PaymentBrand.DISCOVER;
        case 'HIPERCARD': return PaymentBrand.HIPERCARD;
        case 'VISA': return PaymentBrand.VISA;
        default: return PaymentBrand.UNKNOWN;
    }
}

export enum PaymentBrand {
    // maestro debit
    DEBITO_ITAU = 'DEBITO_ITAU',
    DEBITO_SANTANDER_MAESTRO = 'DEBITO_SANTANDER_MAESTRO',
    DEBITO_BRB_MAESTRO = 'DEBITO_BRB_MAESTRO',
    DEBITO_CITIBANK_MAESTRO = 'DEBITO_CITIBANK_MAESTRO',
    DEBITO_BRASIL_MAESTRO = 'DEBITO_BRASIL_MAESTRO',
    // visa debit
    DEBITO_BRASIL = 'DEBITO_BRASIL',
    DEBITO_ITAU_VISA = 'DEBITO_ITAU_VISA',
    DEBITO_BRADESCO = 'DEBITO_BRADESCO',
    DEBITO_HSBC = 'DEBITO_HSBC',
    DEBITO_SANTANDER = 'DEBITO_SANTANDER',
    DEBITO_BRB = 'DEBITO_BRB',
    DEFAULT = '',
    // boleto
    BANK_SLIP = 'BOLETO_ITAU',
    // credit
    MASTERCARD = 'MASTERCARD',
    AMEX = 'AMEX',
    ELO = 'ELO',
    DISCOVER = 'DISCOVER',
    HIPERCARD = 'HIPERCARD',
    VISA = 'VISA',
    PIX = 'PIX',
    // In case of missing information
    UNKNOWN = 'UNKNOWN'
}
