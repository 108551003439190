import { PaymentBrand } from 'checkout/enums/payment-brand.enum';
import { PaymentType } from 'checkout/enums/payment-type.enum';
import { PaymentRequest } from 'checkout/models/request/payment.request';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
    name: 'payment',
    namespaced: true,
})
export default class PaymentModule extends VuexModule {
    paymentInfo: PaymentRequest = new PaymentRequest();
    fakeSelect: boolean = true;

    get cardClass(): string {
        return this.paymentInfo.brand !== undefined
            ? 'gv-card-flag-thumb gv-card-flag-thumb-' + this.paymentInfo.brand.toLowerCase()
            : ' ';
    }

    @Mutation
    setBuyerInfoEmailMutation(email: string): void {
        this.paymentInfo.buyerInfo.email = email;
    }

    @Action({ commit: 'setBuyerInfoEmailMutation' })
    setBuyerInfoEmail(email: string): string {
        localStorage.setItem("user-email", email);
        return email;
    }

    @Mutation
    setInstallmentsAmountMutation(amount: number): void {
        this.paymentInfo.installmentsAmount = amount;
    }

    @Action({ commit: 'setInstallmentsAmountMutation' })
    setInstallmentsAmount(amount: number): number {
        return amount;
    }

    @Mutation
    setPaymentTypeMutation(paymentType: PaymentType): void {
        this.paymentInfo.type = paymentType;
    }

    @Action({ commit: 'setPaymentTypeMutation' })
    setPaymentType(paymentType: PaymentType): PaymentType {
        return paymentType;
    }

    @Mutation
    setChargeInsuranceMutation(chargeInsurance: boolean): void {
        this.paymentInfo.chargeInsurance = chargeInsurance;
    }

    @Action({ commit: 'setChargeInsuranceMutation' })
    setChargeInsurance(chargeInsurance: boolean): boolean {
        return chargeInsurance;
    }

    @Mutation
    setCardBrandMutation(brand: PaymentBrand): void {
        this.paymentInfo.brand = brand;
    }

    @Action({ commit: 'setCardBrandMutation' })
    setCardBrand(brand: string): string {
        return brand;
    }

    @Mutation
    setCardNumberMutation(cardNumber: string): void {
        this.paymentInfo.card.number = cardNumber;
    }

    @Action({ commit: 'setCardNumberMutation' })
    setCardNumber(cardNumber: string): string {
        return cardNumber;
    }

    @Mutation
    setCardNameMutation(cardName: string): void {
        this.paymentInfo.card.name = cardName;
        this.paymentInfo.buyerInfo.name = cardName;
    }

    @Action({ commit: 'setCardNameMutation' })
    setCardName(cardName: string): string {
        return cardName;
    }

    @Mutation
    setCardExpirationDateMutation(cardExpirationDate: string): void {
        this.paymentInfo.card.expirationDate = cardExpirationDate;
    }

    @Action({ commit: 'setCardExpirationDateMutation' })
    setCardExpirationDate(cardExpirationDate: string): string {
        return cardExpirationDate;
    }

    @Mutation
    setCardSecurityCodeMutation(cardSecurityCode: string): void {
        this.paymentInfo.card.securityCode = cardSecurityCode;
    }

    @Action({ commit: 'setCardSecurityCodeMutation' })
    setCardSecurityCode(cardSecurityCode: string): string {
        return cardSecurityCode;
    }

    @Mutation
    setBuyerInfoDocumentMutation(buyerInfoDocument: string): void {
        this.paymentInfo.buyerInfo.document = buyerInfoDocument;
    }

    @Action({ commit: 'setBuyerInfoDocumentMutation' })
    setBuyerInfoDocument(buyerInfoDocument: string): string {
        return buyerInfoDocument;
    }

    @Mutation
    setbuyerInfoBirthdateMutation(buyerInfoBirthdate: string): void {
        this.paymentInfo.buyerInfo.birthDate = buyerInfoBirthdate;
    }

    @Action({ commit: 'setbuyerInfoBirthdateMutation' })
    setbuyerInfoBirthdate(buyerInfoBirthdate: string): string {
        return buyerInfoBirthdate;
    }

    @Mutation
    setBuyerInfoPhoneNumberMutation(buyerInfoPhoneNumber: string): void {
        this.paymentInfo.buyerInfo.phoneNumber = buyerInfoPhoneNumber;
    }

    @Action({ commit: 'setBuyerInfoPhoneNumberMutation' })
    setBuyerInfoPhoneNumber(buyerInfoPhoneNumber: string): string {
        return buyerInfoPhoneNumber;
    }

    @Mutation
    setBuyerInfoNameMutation(name: string): void {
        this.paymentInfo.buyerInfo.name = name;
    }

    @Action({commit: 'setBuyerInfoNameMutation'})
    setBuyerInfoName(name: string): string {
        return name;
    }

    @Mutation
    setSaveOneClickMutation(saveOneClick: boolean): void {
        this.paymentInfo.card.saveOneClick = saveOneClick;
    }

    @Action({ commit: 'setSaveOneClickMutation' })
    setSaveOneClick(saveOneClick: boolean): boolean {
        return saveOneClick;
    }

    @Mutation
    setFakeSelectMutation(fakeSelect: boolean): void {
        this.fakeSelect = fakeSelect;
    }

    @Action({ commit: 'setFakeSelectMutation' })
    setFakeSelect(fakeSelect: boolean): boolean {
        return fakeSelect;
    }

    @Mutation
    setChosenMethodMutation(chosenMethod: string): void {
        this.paymentInfo.transfer.chosenMethod = chosenMethod;
    }

    @Action({ commit: 'setChosenMethodMutation' })
    setChosenMethod(chosenMethod: string): string {
        return chosenMethod;
    }

    @Mutation
    setBuyerBranchMutation(buyerBranch: string): void {
        this.paymentInfo.transfer.buyerBranch = buyerBranch;
    }

    @Action({ commit: 'setBuyerBranchMutation' })
    setBuyerBranch(buyerBranch: string): string {
        return buyerBranch;
    }

    @Mutation
    setBuyerAccountMutation(buyerAccount: string): void {
        this.paymentInfo.transfer.buyerAccount = buyerAccount;
    }

    @Action({ commit: 'setBuyerAccountMutation' })
    setBuyerAccount(buyerAccount: string): string {
        return buyerAccount;
    }

    @Mutation
    setTransferLimitHoursMutation(limitHours: number): void {
        this.paymentInfo.transfer.limitHours = limitHours;
    }

    @Action({commit: 'setTransferLimitHoursMutation'})
    setTransferLimitHours(limitHours: number): number {
        return limitHours;
    }
}
