import {
    Action,
    Module,
    Mutation,
    VuexModule,
    getModule
} from 'vuex-module-decorators';
import { ImgsUtil } from 'vue-shared/utils/imgs/imgs.util';
import { Store } from 'account/store';
import { Image } from 'account/model';

@Module({
    name: 'image',
    namespaced: true,
})
export default class ImageModule extends VuexModule {
    /**
     * Repository of images.
     * 
     * It's a map from Image.id to Image.
     */
    private _repo: Map<number, Image> = new Map<number, Image>();

    static getInstance(): ImageModule {
        return getModule(ImageModule, Store.getInstance());
    }

    @Mutation
    private _insertImage(image: Image): void {
        this._repo.set(image.id, image);
    }

    @Action
    insertImage(image: Image): void {
        this.context.commit('_insertImage',image);
    }

    get getImageUrl(): (imageId: number, def?: string) => string {
        return (imageId: number, def: string = ""): string => {
            if (this._repo.has(imageId))
                return ImgsUtil.assetSrc + this._repo.get(imageId).url;

            return def;
        }
    }
}