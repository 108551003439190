import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'GVLoading',
    template: require('./gv-loading.html'),
})
export default class GVLoading extends Vue {
    @Prop()
    staticAws: string;

    get imageUrl(): string {
        const path = this.staticAws || window['staticAws'] || '/public';
        return `${path}/_v2/static/img/right-arrow.svg`;
    }
}
