import {
    Component,
    Vue
} from 'vue-property-decorator';
import GVNotify from 'account/components/gv-notify';
import GVTravelCard from './components/gv-travel-card';
import {
    UserTravelsDTO,
    TravelService
} from 'account/services/travel';
import {
    busStationAdapter,
    busCompanyAdapter,
    destinationBannerAdapter,
    imageAdapter,
    operationAdapter,
    seatReserveAdapter
} from 'account/services/adapter';
import {
    BusCompanyModule,
    BusStationModule,
    DestinationBannerModule,
    ImageModule,
    OperationModule,
    SeatReserveModule
} from 'account/store/module';
import LoadingModule from 'account/store/loading';
import { TravelPageModule } from './module';
import { AccountAmplitude } from 'account/services/amplitude.service';
import { PageName } from 'account/enums/page-name.enums';
import { OperationStatus } from 'checkout/enums/operation-status.enum';

@Component({
    name: 'TravelPage',
    template: require('./travel.page.html'),
    components: {
        'gv-notify': GVNotify,
        'gv-travel-card': GVTravelCard,
    }
})
export default class TravelPage extends Vue {
    private _travelService: TravelService;

    private _travelPageModule: TravelPageModule;
    private _loadingModule: LoadingModule;

    private _busStationModule: BusStationModule;
    private _busCompanyModule: BusCompanyModule;
    private _destinationBannerModule: DestinationBannerModule;
    private _imageModule: ImageModule;
    private _operationModule: OperationModule;
    private _seatReserveModule: SeatReserveModule;
    private showAlert = false;

    created(): void {
        this._travelService = TravelService.getInstance();

        this._travelPageModule = TravelPageModule.getInstance();
        this._loadingModule = LoadingModule.getInstance();

        this._busStationModule = BusStationModule.getInstance();
        this._busCompanyModule = BusCompanyModule.getInstance();
        this._destinationBannerModule = DestinationBannerModule.getInstance();
        this._imageModule = ImageModule.getInstance();
        this._operationModule = OperationModule.getInstance();
        this._seatReserveModule = SeatReserveModule.getInstance();
    }

    mounted(): void {
        this.init();
        AccountAmplitude.accountOpen(PageName.TRAVELS);
        if (this.$route.query.alert) {
            this.showAlert = true;
        }
    }

    async init(): Promise<void> {
        this._loadingModule.hide();

        if (this._travelPageModule.hasInit)
            return;

        await Promise.all([
            this.fetchFutureOperations(),
            this.fetchConfirmedOperations(),
            this.fetchCancelledOperations(),
            this.fetchExpiredOperations(),
            this.fetchPendingOperations()
        ]);

        this._travelPageModule.dispatchInit();
    }

    get hasInit(): boolean {
        return this._travelPageModule.hasInit;
    }

    get hasNoFutureTravel(): boolean {
        return this.hasInit &&
            this._travelPageModule.futureOperations.length === 0;
    }

    get passangerHasNoTravels(): boolean {
        return this.hasInit &&
            this._travelPageModule.confirmedOperations.length === 0 &&
            this._travelPageModule.cancelledOperations.length === 0 &&
            this._travelPageModule.pendingOperations.length === 0 &&
            this._travelPageModule.expiredOperations.length === 0;
    }

    get futureOperationsList(): Array<number> {
        return this._travelPageModule.futureOperations;
    }

    get confirmedOperationsList(): Array<number> {
        return this._travelPageModule.confirmedOperations.reverse();
    }

    get moreConfirmedOperationsAvailable(): boolean {
        return this._travelPageModule.moreConfirmedOperationsAvailable;
    }

    get confirmedTravelText() {
        if (this.confirmedOperationsList.length > 0)
            return 'Viagens em andamento'
        return '';
    }

    get cancelledOperationsList(): Array<number> {
        return this._travelPageModule.cancelledOperations.reverse();
    }

    get moreCancelledOperationsAvailable(): boolean {
        return this._travelPageModule.moreCancelledOperationsAvailable;
    }

    get cancelledTravelText() {
        if (this.cancelledOperationsList.length > 0)
            return 'Viagens canceladas'
        return '';
    }

    get expiredOperationsList(): Array<number> {
        return this._travelPageModule.expiredOperations.reverse();
    }

    get moreExpiredOperationsAvailable(): boolean {
        return this._travelPageModule.moreExpiredOperationsAvailable;
    }

    get expiredTravelText() {
        if (this.expiredOperationsList.length > 0)
            return 'Viagens expiradas'
        return '';
    }

    get pendingOperationsList(): Array<number> {
        return this._travelPageModule.pendingOperations.reverse();
    }

    get morePendingOperationsAvailable(): boolean {
        return this._travelPageModule.morePendingOperationsAvailable;
    }

    get pendingTravelText() {
        if (this.pendingOperationsList.length > 0)
            return 'Viagens pendentes'
        return '';
    }

    async fetchFutureOperations(): Promise<void> {
        this._loadingModule.startLoading();
        try {
            const userTravelsDTO: UserTravelsDTO =
                await this._travelService.getFutureTravels(
                    this._travelPageModule.futureOperationsPageIdx,
                    this._travelPageModule.futureOperationsPageSize
                );
            const operationIds: Array<number> = this.processUserTravelsDependecies(userTravelsDTO);
            this._travelPageModule.insertFutureOperationPage(operationIds);
        }
        catch (e) {

        }
        finally {
            this._loadingModule.finishLoading();
        }
    }

    async fetchConfirmedOperations(): Promise<void> {
        this._loadingModule.startLoading();
        try {
            const userTravelsDTO: UserTravelsDTO =
                await this._travelService.getTravelsByStatus(
                    this._travelPageModule.confirmedOperationsPageIdx,
                    this._travelPageModule.confirmedOperationsPageSize,
                    [OperationStatus.PAYMENT_SUCCESS, OperationStatus.PARTIAL_CANCEL, OperationStatus.RESERVED, OperationStatus.OFFLINE_TICKETS_PENDING]
                );
            const operationIds: Array<number> = this.processUserTravelsDependecies(userTravelsDTO);
            this._travelPageModule.insertConfirmedOperationPage(operationIds);
        }
        catch (e) {

        }
        finally {
            this._loadingModule.finishLoading();
        }
    }


    async fetchExpiredOperations(): Promise<void> {
        this._loadingModule.startLoading();
        try {
            const userTravelsDTO: UserTravelsDTO =
                await this._travelService.getTravelsByStatus(
                    this._travelPageModule.expiredOperationsPageIdx,
                    this._travelPageModule.expiredOperationsPageSize,
                    [OperationStatus.EXPIRED]
                );
            const operationIds: Array<number> = this.processUserTravelsDependecies(userTravelsDTO);
            this._travelPageModule.insertExpiredOperationPage(operationIds);
        }
        catch (e) {

        }
        finally {
            this._loadingModule.finishLoading();
        }
    }

    async fetchCancelledOperations(): Promise<void> {
        this._loadingModule.startLoading();
        try {
            const userTravelsDTO: UserTravelsDTO =
                await this._travelService.getTravelsByStatus(
                    this._travelPageModule.cancelledOperationsPageIdx,
                    this._travelPageModule.cancelledOperationsPageSize,
                    [OperationStatus.CANCEL]
                );
            const operationIds: Array<number> = this.processUserTravelsDependecies(userTravelsDTO);
            this._travelPageModule.insertCancelledOperationPage(operationIds);
        }
        catch (e) {

        }
        finally {
            this._loadingModule.finishLoading();
        }
    }

    async fetchPendingOperations(): Promise<void> {
        this._loadingModule.startLoading();
        try {
            const userTravelsDTO: UserTravelsDTO =
                await this._travelService.getTravelsByStatus(
                    this._travelPageModule.pendingOperationsPageIdx,
                    this._travelPageModule.pendingOperationsPageSize,
                    [OperationStatus.PAYMENT_PENDING, OperationStatus.PENDING_BOLETO, OperationStatus.IN_ANALYSIS, OperationStatus.RESERVED, OperationStatus.RECOVER]
                );
            const operationIds: Array<number> = this.processUserTravelsDependecies(userTravelsDTO);
            this._travelPageModule.insertPendingOperationPage(operationIds);
        }
        catch (e) {

        }
        finally {
            this._loadingModule.finishLoading();
        }
    }


    private processUserTravelsDependecies(userTravelsDTO: UserTravelsDTO): Array<number> {
        // Images
        userTravelsDTO.busCompanies
            .map(busCompanyDTO => imageAdapter(busCompanyDTO.logo))
            .forEach(image => this._imageModule.insertImage(image))

        // Destination banner
        userTravelsDTO.destinationBanners
            .map(destinationBannerDTO => destinationBannerAdapter(destinationBannerDTO))
            .forEach(destinationBanner => this._destinationBannerModule.insertDestinationBanner(destinationBanner))

        // Bus station
        userTravelsDTO.busStations
            .map(busStationDTO => busStationAdapter(busStationDTO))
            .forEach(busStation => this._busStationModule.insertBusStation(busStation));

        // Bus company
        userTravelsDTO.busCompanies
            .map(busCompanyDTO => busCompanyAdapter(busCompanyDTO))
            .forEach(busCompany => this._busCompanyModule.insertBusCompany(busCompany));

        // Seat reserve
        userTravelsDTO.operations
            .map(operationDTO => {
                operationDTO.reservedSeatsGo
                    .map(seatReserveDTO => seatReserveAdapter(seatReserveDTO))
                    .forEach(seatReserve => this._seatReserveModule.insertSeatReserve(seatReserve));

                operationDTO.reservedSeatsBack
                    .map(seatReserveDTO => seatReserveAdapter(seatReserveDTO))
                    .forEach(seatReserve => this._seatReserveModule.insertSeatReserve(seatReserve));
            })

        // Operation
        userTravelsDTO.operations
            .map(operationDTO => operationAdapter(operationDTO))
            .forEach(operation => this._operationModule.insertOperation(operation));

        return userTravelsDTO.operations.map(operationDTO => operationDTO.operationId);
    }
}
