export class Imgs {
    staticAws(): string {
        return window['staticAws'];
    }

    get assetSrc(): string {
        return window['assetSrc'];
    }
}

export const ImgsUtil: Imgs = new Imgs();
