export enum PageName {
    PROFILE = 'profile',
    EDIT_PROFILE = 'edit_profile_page',
    PAYMENT_CARDS = 'payment_cards',
    NEW_CARD = 'new_card_page',
    ACCESS_DATA = 'access_data',
    TRAVELS = 'travels',
    WALLET = 'wallet',
    TRAVEL_INFO = 'travel_info',
    NOTIFICATIONS = 'notifications_config',
}
