import { SeatReserve } from 'account/model';
import {
    SeatReserveStatus,
    SeatTravelType
} from 'account/model/enum';
import { SeatReserveDTO } from 'account/services/dto';
import { parseStringToDate } from 'vue-shared/utils/date';

function translateStatus(status: string): SeatReserveStatus {
    switch(status) {
        case "RESERVED": return SeatReserveStatus.RESERVED;
        case "CONFIRMED": return SeatReserveStatus.CONFIRMED;
        case "CANCELLED": return SeatReserveStatus.CANCELLED;
        case "EXPIRED": return SeatReserveStatus.EXPIRED;
        case "PEDIDO_ESTORNO": return SeatReserveStatus.PEDIDO_ESTORNO;
        case "ESTORNO": return SeatReserveStatus.ESTORNO;
        case "TO_REPLACE": return SeatReserveStatus.TO_REPLACE;
        case "OFFLINE_PENDING": return SeatReserveStatus.OFFLINE_PENDING;
        case "REPLACED": return SeatReserveStatus.REPLACED;
        case "RESERVED_BOLETO": return SeatReserveStatus.RESERVED_BOLETO;
        default: return SeatReserveStatus.UNKNOWN;
    }
}

function translateTravelType(travelType: string): SeatTravelType {
    switch(travelType) {
        case "DIRECT": return SeatTravelType.DIRECT;
        case "CONNECTION": return SeatTravelType.CONNECTION;
        case "STOPOVER": return SeatTravelType.STOPOVER;
        default: return SeatTravelType.UNKNOWN;
    }
}

export default function seatReserveAdapter(seatReserveDto: SeatReserveDTO): SeatReserve {
    return {
        id: seatReserveDto.id,
        status: translateStatus(seatReserveDto.status),
        origin: seatReserveDto.origin,
        destination: seatReserveDto.destination,
        busCompany: seatReserveDto.companyKey,
        departureDate: parseStringToDate(seatReserveDto.departureDate, "YYYY-MM-DD hh:mm"),
        arrivalDate: parseStringToDate(seatReserveDto.arrivalDate, "YYYY-MM-DD hh:mm"),
        locator: seatReserveDto.locator,
        ticketNumber: seatReserveDto.ticketNumber,
        travelType: translateTravelType(seatReserveDto.seatTravelType)
    }
}