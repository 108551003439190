export enum SnackbarIdentifier {
    VALIDATION_OK = '#validation-snackbar',
    VALIDATION_ERROR = '#validation-fields-error',
    ERROR = '#validation-error',
    WARNING = '#validation-warning',
    ADDING_CARD = '#submit-snackbar',
    REMOVED_CARD = '#remove-card-snacbar',
    NOT_VALID = "#card-not-valid",
    GENERIC_ERROR = "#generic-error"
}
