import { Filter } from 'checkout/interfaces/filter.interface';
import { Vue } from 'vue-property-decorator';
import FormatCurrencyFilter from 'vue-shared/filters/format-currency';
import CapitalizeFilter from './capitalize';
import CapitalizeAllFilter from './capitalize-all';

export class Filters {
    static install(): void {
        const filters: any[] = [new FormatCurrencyFilter(), new CapitalizeFilter(), new CapitalizeAllFilter()];

        filters.forEach((filterService: Filter) =>
            Vue.filter(filterService.filterName, (...params: Array<any>) => filterService.filterAction(...params))
        );
    }
}
