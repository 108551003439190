import { MDCRipple } from '@material/ripple';
import { MDCTextField } from '@material/textfield';
import { ErrorField, Validator } from 'vee-validate/types/vee-validate';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'GVTextfield',
    template: require('./gv-textfield.html'),
})
export default class GVTextfield extends Vue {
    @Prop({ required: true, type: String, default: '' })
    id: string;

    @Prop({ required: true, type: String, default: '' })
    name: string;

    @Prop({ type: String, default: 'text' })
    type: string;

    @Prop({ required: true, type: String, default: '' })
    label: string;

    @Prop({ type: String })
    value: string;

    @Prop({ type: String, default: '100%' })
    width: string;

    @Prop({ type: String, default: 'none' })
    textTransform: string;

    @Prop({ type: String, default: '' })
    leadingIcon: string;

    @Prop({ type: String, default: '' })
    trailingIcon: string;

    @Prop({ type: Boolean, default: false })
    isTrailingIconButton: boolean;

    @Prop({ type: String, default: '' })
    dataVvScope: string;

    @Prop({ type: String, default: '' })
    validate: string;

    @Prop({ type: Boolean, default: false })
    disabled: boolean;

    @Prop({ type: Boolean, default: false })
    success: boolean;

    @Prop({ type: Boolean, default: false })
    focus: boolean;

    @Prop({ type: String })
    height: string;

    @Inject()
    $validator: Validator;

    mounted(): void {
        new MDCTextField(this.$el.querySelector('.mdc-text-field'));

        if (this.focus) {
            (this.$refs.input as HTMLInputElement).focus();
        }

        if (this.isTrailingIconButton) {
            const iconButtonRipple: MDCRipple = new MDCRipple(this.$el.querySelector('.mdc-icon-button'));
            iconButtonRipple.unbounded = true;
        }
    }

    trailingIconButtonAction(): void {
        this.$emit('trailingIconButtonAction');
    }

    get styleObjectOuterContent(): any {
        return {
            width: this.width,
        };
    }

    get styleObjectInnerContent(): any {
        return {
            height: this.height,
        };
    }

    get styleObjectInput(): any {
        return {
            textTransform: this.textTransform,
        };
    }

    get classObject(): any {
        return {
            'gt-success': this.success,
            'gt-error': this.hasError,
            'mdc-text-field--with-leading-icon': !!this.leadingIcon,
            'mdc-text-field--with-trailing-icon': !!this.trailingIcon,
        };
    }

    get hasError(): boolean {
        return !!this.getError || false;
    }

    get getError(): string {
        const foundError: ErrorField = this.errors.items.find((error: ErrorField) => error.field === this.name);
        return (!!foundError && foundError.msg) || '';
    }

    updateValue(): void {
        this.$emit('input', (this.$refs.input as HTMLInputElement).value);
    }
}
