import { AccountAmplitude } from 'account/services/amplitude.service';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { OperationStatus } from 'account/model/enum';
import AccountModule from 'account/store/account';
import GVButton from 'vue-shared/components/gv-button/gv-button';
import store from 'account/store';

const account: AccountModule = getModule(AccountModule, store);

@Component({
    name: 'GVTicket',
    template: require('./gv-ticket.html'),
    components: {
        'gv-button': GVButton
    }
})
export default class GVTicket extends Vue {

    @Prop({ type: Number })
    locator: number;

    @Prop({ default: OperationStatus.UNKNOWN })
    status: OperationStatus;

    get statusColor() {
        return {
            'blue': this.status === OperationStatus.RESERVED || this.status === OperationStatus.PENDING,
            'red': this.status === OperationStatus.CANCEL,
            'gray': this.status === OperationStatus.EXPIRED,
            'green': this.status === OperationStatus.PAID,
            'orange': this.status === OperationStatus.UNKNOWN,
            'yellow': this.status === OperationStatus.PARTIAL_CANCEL || this.status === OperationStatus.OFFLINE_TICKETS_PENDING,
        };
    }

    get statusStr() {
        switch(this.status) {
            case OperationStatus.PAID: return "Confirmada";
            case OperationStatus.PENDING: return "Pendente";
            case OperationStatus.RESERVED: return "Pendente";
            case OperationStatus.CANCEL: return "Cancelada";
            case OperationStatus.EXPIRED: return "Expirada";
            case OperationStatus.UNKNOWN: return "Em análise";
            case OperationStatus.OFFLINE_TICKETS_PENDING: return "Emitindo ticket";
            case OperationStatus.PARTIAL_CANCEL: return "Parcialmente Cancelada";
            default: return "";
        }
    }

    get detailsUrl(): string {
        if (this.status === OperationStatus.RESERVED || this.status === OperationStatus.PENDING) {
            return `/t/pagamento/sec/r/${this.locator}`;
        }

        return account.isPartialCancelEnable
                ? `/detalhes-viagem/${this.locator}`
                : `/t/pagamento/sec/r/${this.locator}`;
    }
    // TODO: Chamar esse evento.
    attachLoggedEvents(): void {
        // const operation = new OperationRequest();
        //window.location.href = `/t/pagamento/sec/r/${operation.operationId}/cancelado`;
        AccountAmplitude.travelDetailsOpen();
    }
}
