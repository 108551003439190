import { MDCDialog } from '@material/dialog';
import { Dialog } from 'account/enums/dialog.enum';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
    name: 'dialog',
    namespaced: true,
})
export default class DialogModule extends VuexModule {
    message: string = '';
    openedModal: MDCDialog = null;
    openedModalName: string = '';

    get shouldCloseModal(): boolean {
        return (
            this.openedModalName !== Dialog.REMOVE_CARD_DIALOG
        );
    }

    @Mutation
    setMessageMutation(message: string): void {
        this.message = message;
    }

    @Action({ commit: 'setMessageMutation' })
    setMessage(message: string): string {
        return message;
    }

    @Mutation
    setOpenedModalMutation(openedModal: HTMLElement): void {
        this.openedModal = openedModal;
    }

    @Action({ commit: 'setOpenedModalMutation' })
    setOpenedModal(openedModal: HTMLElement): HTMLElement {
        return openedModal;
    }

    @Mutation
    setOpenedModalNameMutation(openedModalName: string): void {
        this.openedModalName = openedModalName;
    }

    @Action({ commit: 'setOpenedModalNameMutation' })
    setOpenedModalName(openedModalName: string): string {
        return openedModalName;
    }
}
