import { Filter } from 'checkout/interfaces/filter.interface';
import { StringUtil } from 'vue-shared/utils/string/string.util';

export default class CapitalizeAllFilter implements Filter {
    filterName: string;

    constructor() {
        this.filterName = 'capitalizeAll';
    }

    filterAction(text: string): string {
        return StringUtil.capitalizeAll(text);
    }
}
