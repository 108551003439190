import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
    name: 'loading',
    namespaced: true,
})
export default class LoadingModule extends VuexModule {
    opened: boolean = false;

    @Mutation
    showMutation(state: boolean): void {
        this.opened = state;
    }

    @Mutation
    hideMutation(state: boolean): void {
        this.opened = state;
    }

    @Action({ commit: 'showMutation' })
    show(): boolean {
        return true;
    }

    @Action({ commit: 'hideMutation' })
    hide(): boolean {
        return false;
    }
}
