import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'GVNotification',
    template: require('./gv-notification.html'),
})
export default class GVNotification extends Vue {
    @Prop({ type: String, default: '' })
    message: string;

    @Prop({ type: String, default: 'info' })
    icon: string;

    @Prop({ type: String, default: 'error' })
    type: string;

    get color(): any {
        return {
            'gn-error': this.type === 'error',
            'gn-success': this.type === 'success',
        };
    }
}
