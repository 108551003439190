import store from 'account/store';
import AccountModule from 'account/store/account';
import storeAccount from 'account/store';
import { Component, Vue } from 'vue-property-decorator';
import GVButton from 'vue-shared/components/gv-button/gv-button';
import { ImgsUtil } from 'vue-shared/utils/imgs/imgs.util';
import { getModule } from 'vuex-module-decorators';
import { AccountAmplitude } from 'account/services/amplitude.service';
import { AccountOpenAmplitude } from 'account/interfaces/account-open-amplitude.interface';
import LoadingModule from 'account/store/loading';
import { PageName } from 'account/enums/page-name.enums';
import { RouterPath } from 'account/enums/router-path.enum';

const account: AccountModule = getModule(AccountModule, storeAccount);
const loading: LoadingModule = getModule(LoadingModule, store);
@Component({
    name: 'ProfilePage',
    template: require('./profile.page.html'),
    components: {
        'gv-button': GVButton
    }
})
export default class ProfilePage extends Vue {
    isAuthenticated: boolean;

    lastGoLabel: string;
    lastBackLabel: string;

    beforeCreate(): void {
        this.lastGoLabel = localStorage.getItem('lastGoLabel');
        this.lastBackLabel = localStorage.getItem('lastBackLabel');
    }

    mounted(): void {
        loading.hide();
        AccountAmplitude.accountOpen(PageName.PROFILE);
    }

    get goLabel() {
        return this.lastGoLabel;
    }

    get backLabel() {
        return this.lastBackLabel;
    }

    get userInfo() {
        return account.userInfo;
    }

    get userPicture() {
        return account.userPicture;
    }

    get  hrefSearch() {
        const lastGoDate = localStorage.getItem('lastGoDate')
        const lastGoId = localStorage.getItem('lastGoId')
        const lastBackId = localStorage.getItem('lastBackId')

        const params = new URLSearchParams()
        params.append("radio","on")
        params.append("departure.id", lastGoId)
        params.append("departure.label", this.lastGoLabel)
        params.append("arrival.id",lastBackId)
        params.append("arrival.label", this.lastBackLabel)
        params.append("dateGo",lastGoDate)

        const url = "/busca?"+params.toString()

        return url
    }

    editProfileBtn(): void {
        window.location.href = RouterPath.UPDATE_PROFILE;
        AccountAmplitude.editProfileBtn();
    }
}
