enum SeatReserveStatus {
    RESERVED,
    CONFIRMED,
    CANCELLED,
    EXPIRED,
    PEDIDO_ESTORNO,
    ESTORNO,
    TO_REPLACE,
    OFFLINE_PENDING,
    REPLACED,
    RESERVED_BOLETO,
    UNKNOWN
}

export default SeatReserveStatus;