import { RouterPath } from 'account/enums/router-path.enum';
import { AuthenticationService } from 'account/services/authentication.service';
import store from 'account/store';
import AccountModule from 'account/store/account';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import LoginPage from '../pages/login';

Vue.use(VueRouter);

const account: AccountModule = getModule(AccountModule, store);

class Router extends VueRouter {
    constructor() {
        super({
            mode: 'history',
            routes: [
                {
                    path: RouterPath.LOGIN,
                    name: RouterPath.LOGIN,
                    component: LoginPage,
                },
            ],
        });

        this.beforeEach(this.onBeforeEach);
    }

    private onBeforeEach = async (to, from, next) => {
        const isAuthenticated: boolean = await AuthenticationService.isAuthenticated();

        if (!isAuthenticated && to.path !== RouterPath.LOGIN) {
            await AuthenticationService.unauthenticate();
            account.setUserInfo(null);
            next(RouterPath.LOGIN);
            return;
        }

        if (isAuthenticated && to.path === RouterPath.LOGIN) {
            window.location.href = RouterPath.HOME;
            return;
        }

        next();
    };
}

export const AppRouterLogin: Router = new Router();
