import {
    Action,
    Module,
    Mutation,
    VuexModule,
    getModule
} from 'vuex-module-decorators';
import { Store } from 'account/store';

@Module({
    name: 'loading',
    namespaced: true,
})
export default class LoadingModule extends VuexModule {
    private _loadingCount: number = 0;

    static getInstance(): LoadingModule {
        return getModule(LoadingModule, Store.getInstance());
    }

    get isLoading(): boolean {
        return this._loadingCount > 0;
    }

    @Mutation
    private _incLoadingCount(): void {
        this._loadingCount++;
    }

    @Mutation
    private _decLoadingCount(): void {
        if (this._loadingCount > 0)
            this._loadingCount--;
    }

    @Action
    startLoading(): void {
        this.context.commit('_incLoadingCount');
    }

    @Action
    finishLoading(): void {
        this.context.commit('_decLoadingCount');
    }

    ////////////////
    // DEPRECATED //
    ////////////////

    // Deprecated
    opened: boolean = false;

    // Deprecated
    @Mutation
    showMutation(state: boolean): void {
        this.opened = state;
    }

    // Deprecated
    @Mutation
    hideMutation(state: boolean): void {
        this.opened = state;
    }

    // Deprecated
    @Action({ commit: 'showMutation' })
    show(): boolean {
        return true;
    }

    // Deprecated
    @Action({ commit: 'hideMutation' })
    hide(): boolean {
        return false;
    }
}
