import { FinancialStatementType } from 'account/model/enum/financial-statement-type';
import { FinancialStatementModule } from 'account/store/module';

export default class FinancialStatementSelector {
    private _financialStatementId: number;
    private _financialStatementModule: FinancialStatementModule;

    constructor(financialStatementId: number) {
        this._financialStatementId = financialStatementId;
        this._financialStatementModule = FinancialStatementModule.getInstance();
    }

    get date(): Date {
        return this._financialStatementModule.getDate(this._financialStatementId);
    }

    get type(): FinancialStatementType {
        return this._financialStatementModule.getType(this._financialStatementId);
    }

    get details(): string {
        return this._financialStatementModule.getDetails(this._financialStatementId);
    }

    get value(): number {
        return this._financialStatementModule.getValue(this._financialStatementId);
    }
}