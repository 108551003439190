import { Component, Prop, Vue } from 'vue-property-decorator';
import { CREDIT } from 'account/model/enum/financial-statement-type';
import { FinancialStatementSelector } from 'account/store/selector';
import { MONTHS } from 'account/utils/date-time/consts';

@Component({
    name: 'FinancialBlockLine',
    template: require('./financial-block-line.html')
})
export default class FinancialBlockLine extends Vue {
    private _financialStatementSelector: FinancialStatementSelector;

    @Prop({type: Number})
    financialStatementId: number;

    @Prop({type: Boolean})
    desktop: boolean;

    created(): void {
        this._financialStatementSelector =
            new FinancialStatementSelector(this.financialStatementId);
    }

    get date(): string {
        const date: Date = this._financialStatementSelector.date;
        return `${date.getDate()} ${MONTHS[date.getMonth()].Nme}`;
    }

    get details(): string {
        return this._financialStatementSelector.details;
    }

    get isCredit(): boolean {
        return this._financialStatementSelector.type == CREDIT;
    }

    get value(): string {
        return this._financialStatementSelector.value.toFixed(2).toString();
    }
}