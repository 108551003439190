import Vue from 'vue';
import Vuex, { StoreOptions, Store as VStore } from 'vuex';
import account from './account';
import loading from './loading';
import dialog from './dialog';

import {
    BusCompanyModule,
    BusStationModule,
    CreditCardModule,
    DestinationBannerModule,
    FinancialStatementModule,
    ImageModule,
    OperationModule,
    SeatReserveModule
} from './module';
import { TravelPageModule } from 'account/pages/travel/module';
import { PaymentCardPageModule } from 'account/pages/payment-card/module';

Vue.use(Vuex);

const config: StoreOptions<{}> = {
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        account,
        loading,
        'bus-company': BusCompanyModule,
        'bus-station': BusStationModule,
        'credit-card': CreditCardModule,
        'destination-banner': DestinationBannerModule,
        'financial-statement': FinancialStatementModule,
        'image': ImageModule,
        'seat-reserve': SeatReserveModule,
        'travel-page': TravelPageModule,
        'payment-card-page': PaymentCardPageModule,
        'operation': OperationModule,
        dialog
    },
};

const storeInstance: Store = new VStore<{}>(config);

class Store extends VStore<{}> {
    static getInstance(): VStore<{}> {
        return storeInstance;
    }
}

export { Store };
export default storeInstance;