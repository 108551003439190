import { OperationStatus, SeatTravelType } from 'account/model/enum';

import {
    BusCompanyModule,
    BusStationModule,
    DestinationBannerModule,
    ImageModule,
    OperationModule,
    SeatReserveModule
} from '../module';

export default class OperationSelector {
    private _operationId: number;

    private _busCompanyModule: BusCompanyModule;
    private _busStationModule: BusStationModule;
    private _destinationBannerModule: DestinationBannerModule;
    private _imageModule: ImageModule;
    private _operationModule: OperationModule;
    private _seatReserveModule: SeatReserveModule;

    constructor(operationId: number) {
        this._operationId = operationId;

        this._busCompanyModule = BusCompanyModule.getInstance();
        this._busStationModule = BusStationModule.getInstance();
        this._destinationBannerModule = DestinationBannerModule.getInstance();
        this._imageModule = ImageModule.getInstance();
        this._operationModule = OperationModule.getInstance();
        this._seatReserveModule = SeatReserveModule.getInstance();
    }

    get id(): number {
        return this._operationId;
    }

    get originLabel(): string {
        const originId: number = this._operationModule.getOriginId(this._operationId);
        return this._busStationModule.getLabel(originId);
    }

    get destinationLabel(): string {
        const destinationId: number = this._operationModule.getDestinationId(this._operationId);
        return this._busStationModule.getLabel(destinationId);
    }

    get isConnection(): boolean {
        const seatReservesGoIds: Array<number> = this._operationModule.getSeatReservesGo(this._operationId);

        if (seatReservesGoIds.length == 0)
            return false;

        const seatReserveId = seatReservesGoIds[0];
        return this._seatReserveModule.getTravelType(seatReserveId) == SeatTravelType.CONNECTION;
    }

    get isStopOver(): boolean {
        const seatReservesGoIds: Array<number> = this._operationModule.getSeatReservesGo(this._operationId);

        if (seatReservesGoIds.length == 0)
            return false;

        const seatReserveId = seatReservesGoIds[0];
        return this._seatReserveModule.getTravelType(seatReserveId) == SeatTravelType.STOPOVER;
    }

    get companyLogoUrl(): string {
        const seatReservesGoIds: Array<number> = this._operationModule.getSeatReservesGo(this._operationId);

        if (seatReservesGoIds.length == 0)
            return "";

        const seatReserveId = seatReservesGoIds[0];
        const companyKey = this._seatReserveModule.getCompanyKey(seatReserveId);
        const imageId = this._busCompanyModule.getLogoId(companyKey);
        return this._imageModule.getImageUrl(imageId);
    }

    get status(): OperationStatus {
        return this._operationModule.getStatus(this._operationId);
    }

    get destinationBannerUrl(): string {
        const destinationId = this._operationModule.getDestinationId(this._operationId);
        return this._destinationBannerModule.getBannerUrl(destinationId);
    }

    get goTravelDepartureDate(): Date {
        const seatReserveIds = this._operationModule.getSeatReservesGo(this._operationId);
        var minDepartureDate: Date = this._seatReserveModule.getDepartureDate(seatReserveIds[0]);

        seatReserveIds.forEach(seatReserveId => {
            const departureDate = this._seatReserveModule.getDepartureDate(seatReserveId);

            if (departureDate.getTime() < minDepartureDate.getTime())
                minDepartureDate = departureDate;
        })

        return minDepartureDate;
    }

    get goTravelArrivalDate(): Date {
        const seatReserveIds = this._operationModule.getSeatReservesGo(this._operationId);
        var maxArrivalDate: Date = this._seatReserveModule.getArrivalDate(seatReserveIds[0]);

        seatReserveIds.forEach(seatReserveId => {
            const arrivalDate = this._seatReserveModule.getArrivalDate(seatReserveId);

            if (maxArrivalDate.getTime() < arrivalDate.getTime())
                maxArrivalDate = arrivalDate;
        })

        return maxArrivalDate;
    }

    get hasBack(): boolean {
        return this._operationModule.getSeatReservesBack(this._operationId).length > 0;
    }

    get goTravelBackDate(): Date {
        const seatReserveIds = this._operationModule.getSeatReservesBack(this._operationId);
        var minDepartureDate: Date = this._seatReserveModule.getDepartureDate(seatReserveIds[0]);

        seatReserveIds.forEach(seatReserveId => {
            const departureDate = this._seatReserveModule.getDepartureDate(seatReserveId);

            if (departureDate.getTime() < minDepartureDate.getTime())
                minDepartureDate = departureDate;
        })

        return minDepartureDate;
    }
}
