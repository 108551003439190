import { ReserveOperationDTO } from 'account/services/dto';
import AccountModule from 'account/store/account';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import storeAccount from 'account/store';
import { AccountAmplitude } from 'account/services/amplitude.service';

const account: AccountModule = getModule(AccountModule, storeAccount);
@Component({
    name: 'BsSwapTravelSelection',
    template: require('./bs-swap-travel-selection.html'),
})
export default class BSSwapTravelSelectionComponent extends Vue {
    @Prop()
    travelInfoData: ReserveOperationDTO;

    @Prop({
        default: false
    })
    back: boolean;

    @Prop()
    changeOperation: (location: string) => void;

    selectedId: number = 0;
    maxPrice: number = 0;
    origin: number = 0;
    destination: number = 0;

    reserves(origin: number, filteredInformation: boolean = true) {
        if (!this.travelInfoData.operation) {
            return [];
        }

        if (this.back) {
            return filteredInformation ? this.operation().reservedSeatsBack.filter(reserve => reserve.origin === origin) : this.operation().reservedSeatsBack;
        }

        return filteredInformation ? this.operation().reservedSeatsGo.filter(reserve => reserve.origin === origin) : this.operation().reservedSeatsGo;
    }

    operation() {
        if (this.travelInfoData.operation) {
            return this.travelInfoData.operation;
        }
        return null;
    }

    get origins() {
        const reserves = this.reserves(null, false);
        const reserveLocations = new Set<number>();

        reserves.forEach(reserve => {
            reserveLocations.add(reserve.origin);
        });

        return Array.from(reserveLocations);
    }

    passengers(origin: number) {
        if (!this.travelInfoData.operation) {
            return '';
        }
        return this.reserves(origin).filter(reserve => reserve.origin && reserve.status !== 'CANCELLED');
    }

    get selectedReserve() {
        return this.selectedId;
    }

    setRoute(origin: number, destination: number) {
        this.origin = origin;
        this.destination = destination;
    }

    setMaxPrice(price: number) {
        this.maxPrice = price;
    }

    selectReserve(id: number) {
        if (id === this.selectedId) {
            this.selectedId = 0;
        } else {
            this.selectedId = id;
        }
    }

    goBack() {
        AccountAmplitude.swapBackPassenger();
        this.changeOperation('display');
    }

    get isBack() {
        return this.back;
    }

    getStationDetail(origin: number) {
        return this.travelInfoData.busStation.find(bs => bs.id === origin)
    }

    action() {
        AccountAmplitude.swapConfirmPassenger();
        const originLabel = this.getStationDetail(this.origin).label;
        const destinationLabel = this.getStationDetail(this.destination).label;
        const urlQuery = new URLSearchParams();
        urlQuery.set('reserveId', this.selectedReserve.toString());
        urlQuery.set('email', this.userEmail());
        urlQuery.set('origin', this.origin.toString());
        urlQuery.set('originLabel', originLabel);
        urlQuery.set('destination', this.destination.toString());
        urlQuery.set('destinationLabel', destinationLabel);
        urlQuery.set('maxValue', this.maxPrice.toString());
        urlQuery.set('today', 'true');
        window.location.replace(`/troca?${urlQuery.toString()}`);
    }

    userEmail() {
        return account.userLogged.email;
    }
}
