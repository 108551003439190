import { HttpClient } from 'commons/ts/http.client';
import { RestEndPoint } from 'commons/ts/utils/rest.endpoints';
import { FinancialStatementDTO } from './dto';
import { financialStatementAdapter } from './adapter';
import { FinancialStatement } from 'account/model';
import { FinancialStatementType } from 'account/model/enum';

const MOCK_STATEMENTS: Array<FinancialStatement> = [
    {
        id: 0,
        date: new Date("2020-09-21T12:00:00"),
        type: FinancialStatementType.CREDIT,
        details: "Oi",
        value: 1200.99
    },
    {
        id: 1,
        date: new Date("2020-09-20T12:00:00"),
        type: FinancialStatementType.DEBIT,
        details: "Oi 2",
        value: 10
    },
    {
        id: 3,
        date: new Date("2020-08-21T12:00:00"),
        type: FinancialStatementType.CREDIT,
        details: "Oi",
        value: 12
    },
    {
        id: 4,
        date: new Date("2020-08-20T12:00:00"),
        type: FinancialStatementType.DEBIT,
        details: "Oi 2",
        value: 10
    },
    {
        id: 5,
        date: new Date("2020-07-21T12:00:00"),
        type: FinancialStatementType.CREDIT,
        details: "Hello World!",
        value: 2450.50
    },
    {
        id: 6,
        date: new Date("2020-07-20T12:00:00"),
        type: FinancialStatementType.DEBIT,
        details: "A very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very long line",
        value: 10
    },
    {
        id: 7,
        date: new Date("2020-07-19T12:00:00"),
        type: FinancialStatementType.CREDIT,
        details: "A very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very long line",
        value: 3000.00
    },
    {
        id: 8,
        date: new Date("2020-07-18T12:00:00"),
        type: FinancialStatementType.CREDIT,
        details: "A very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very very long line",
        value: 9999999999999.00
    },
];

export default class AccoutingService {
    public async getFinancialStatements(pageIdx: number = 0, pageSize: number = 50, reverse: boolean = true): Promise<Array<FinancialStatement>> {
        // return MOCK_STATEMENTS;
        const data: Array<FinancialStatementDTO> = await HttpClient.get({
            url: RestEndPoint.FINANCIAL_STATEMENTS,
            params: {pageIdx, pageSize, reverse}
        });

        return data.map(financialStatementDTO => financialStatementAdapter(financialStatementDTO));
    }

    public async getAccountValue(): Promise<number> {
        const ret = await HttpClient.get({url: RestEndPoint.ACCOUNT_VALUE});
        return ret.value;
    }
}