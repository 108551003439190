import { DestinationBanner } from 'account/model';
import { DestinationBannerDTO } from 'account/services/dto';

export default function destinationBannerAdapter(destinationBannerDTO: DestinationBannerDTO): DestinationBanner {
    return {
        destinationId: destinationBannerDTO.destinationId,
        imgId: destinationBannerDTO.imgId,
        imgTitle: destinationBannerDTO.imgTitle,
        imgAlt: destinationBannerDTO.imgAlt
    }
}