import { CurrencyFormat } from 'checkout/interfaces/currency-format.interface';
import { Filter } from 'checkout/interfaces/filter.interface';
import { CurrencyUtil } from 'vue-shared/utils/currency/currency.util';

export default class FormatCurrencyFilter implements Filter {
    filterName: string;

    constructor() {
        this.filterName = 'formatCurrency';
    }

    filterAction(value: number, format?: CurrencyFormat): string {
        format = format || {
            prefix: 'R$ ',
            decimal: ',',
            thousands: '.',
            precision: 2,
        };

        return CurrencyUtil.formatCurrency(value, format);
    }
}
