import { Filter } from 'checkout/interfaces/filter.interface';

export default class CapitalizeFilter implements Filter {
    filterName: string;

    constructor() {
        this.filterName = 'capitalize';
    }

    filterAction(text: string): string {
        return this.capitalize(text);
    }

    private capitalize(text: string): string {
        if (!text) return '';

        text = text.toString().toLowerCase();
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
}
