import { Component, Vue } from 'vue-property-decorator';
import { AccoutingService } from 'account/services';
import FinancialBlockTable from './components/financial-block-table/financial-block-table';
import { FinancialBlock } from './model/financial-block';
import { FinancialStatement } from 'account/model';
import LoadingModule from 'account/store/loading';
import { FinancialStatementModule } from 'account/store/module';
import { getMonth, removeTime } from 'account/utils/date-time/time-transformation';
import { MDCSnackbar } from '@material/snackbar';
import { SnackbarIdentifier } from 'account/enums/snackbar-identifier.enum';
import { AccountAmplitude } from 'account/services/amplitude.service';
import { PageName } from 'account/enums/page-name.enums';

@Component({
    name: 'WalletPage',
    template: require('./wallet-page.html'),
    components: {
        'gv-financial-block-table': FinancialBlockTable
    }
})
export default class WalletPage extends Vue {
    accountValue: number = 0;
    previousNDays: number = 0;
    hasInit: boolean = false;

    private _hasMoreStatements: boolean;
    private _pageIdx: number;
    private _pageSize: number;

    private _accoutingService: AccoutingService;

    private _loadingModule: LoadingModule;
    private _financialStateModule: FinancialStatementModule;

    private _errorSnackBar: MDCSnackbar;

    created(): void {
        this._hasMoreStatements = true;
        this._pageIdx = 0;
        this._pageSize = 50;

        this._accoutingService = new AccoutingService();
        this._loadingModule = LoadingModule.getInstance();
        this._financialStateModule = FinancialStatementModule.getInstance();

        this._errorSnackBar = new MDCSnackbar(document.querySelector(SnackbarIdentifier.GENERIC_ERROR));
    }

    mounted(): void {
        this.init();
        AccountAmplitude.accountOpen(PageName.WALLET);
    }

    async init(): Promise<void> {
        if (this.hasInit)
            return;

        this.selectAllStatements();
        await Promise.all([
            this.fetchAccountValue(),
            this.fetcheFinancialStatements()
        ]);

        this.hasInit = true;
    }

    get hasMoreStatements(): boolean {
        const oldestFinancialStatement: FinancialStatement = this._financialStateModule.oldestStatement;

        if (oldestFinancialStatement == null)
            this._hasMoreStatements;

        return this._hasMoreStatements && oldestFinancialStatement.date.getTime() > this.getTimeTreshold();
    }

    get financialBlocks(): Array<FinancialBlock> {
        const timeTreshould = this.getTimeTreshold();
        const financialStatements: Array<FinancialStatement> = this._financialStateModule.query(
            financialStatement => financialStatement.date.getTime() >= timeTreshould
        );

        const financialMap: Map<number,Array<FinancialStatement>> = new Map();
        financialStatements.forEach(financialStatement => {
            const month: number = getMonth(financialStatement.date).getTime();

            if (!financialMap.has(month))
                financialMap.set(month, []);

            financialMap.get(month).push(financialStatement);
        });

        const financialBlocks: Array<FinancialBlock> = [];
        financialMap.forEach((statements, month) => {
            financialBlocks.push({
                month: new Date(month),
                statements: statements
                    .sort(this.compareStatementsByDate)
                    .reverse()
                    .map(f => f.id)
            });
        });

        return financialBlocks
            .sort(this.compareFinancialBlocksByMonth)
            .reverse();
    }

    public async fetcheFinancialStatements(): Promise<void> {
        try {
            this._loadingModule.startLoading();

            const financilaStatements: Array<FinancialStatement> =
                await this._accoutingService.getFinancialStatements(this._pageIdx, this._pageSize);

            financilaStatements.forEach(financilaStatement =>
                this._financialStateModule.insertFinancialStatement(financilaStatement));

            this._hasMoreStatements = financilaStatements.length == this._pageSize;
            this._pageIdx++;
        }
        catch (e) {
            this._errorSnackBar.open();
        }
        finally {
            this._loadingModule.finishLoading();
        }
    }

    public selectAllStatements() {
        this.selectLastNDays(0);
    }

    public selectLastNDays(ndays: number) {
        this.previousNDays = ndays;
    }

    private async fetchAccountValue(): Promise<void> {
        console.log('fetch account value');
        try {
            this._loadingModule.startLoading();
            this.accountValue = await this._accoutingService.getAccountValue();
        }
        catch (error) {
            this.accountValue = 0;
            this._errorSnackBar.open();
        }
        finally {
            this._loadingModule.finishLoading();
        }
    }

    private compareStatementsByDate(f0: FinancialStatement, f1: FinancialStatement): number {
        return f0.date.getTime() < f1.date.getTime() ? -1 : 1;
    }

    private compareFinancialBlocksByMonth(fb0: FinancialBlock, fb1: FinancialBlock): number {
        return fb0.month.getTime() < fb1.month.getTime() ? -1 : 1;
    }

    private getTimeTreshold(): number {
        if (this.previousNDays == 0)
            return 0;

        const today = removeTime(new Date());
        return today.getTime() - this.previousNDays * 24*60*60*1000;
    }
}
