/**
 * Returns a new Date with no time information, which means all time
 * settings are 0.
 */
export function removeTime(date: Date): Date {
    const ret = new Date(date);

    ret.setHours(0);
    ret.setMinutes(0);
    ret.setSeconds(0);
    ret.setMilliseconds(0);

    return ret;
}

/**
 * Returns a new Date with no time on the first day of month.
 */
export function getMonth(date: Date): Date {
    const ret = removeTime(date);
    ret.setDate(1);

    return ret;
}