import { CreditCardModule } from 'account/store/module';
import { PaymentBrand } from 'checkout/enums/payment-brand.enum';

export default class CreditCardSelector {
    private _creditCardId: number = null;
    private _creditCardModule: CreditCardModule = null;

    constructor(creditCardId: number) {
        this._creditCardId = creditCardId;
        this._creditCardModule = CreditCardModule.getInstance();
    }

    get ownerName(): string {
        return this._creditCardModule.getOwnerName(this._creditCardId);
    }

    get last4(): string {
        return this._creditCardModule.getLast4(this._creditCardId);
    }

    get brand(): PaymentBrand {
        return this._creditCardModule.getBrand(this._creditCardId);
    }

    get expirationDate(): string {
        return this._creditCardModule.getExpirationDate(this._creditCardId);
    }
}