import { UserInfo } from 'account/interfaces/user-info.interface';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
    name: 'account',
    namespaced: true,
})
export default class AccountModule extends VuexModule {
    userInfo: UserInfo = null;
    userPicture = localStorage.getItem('user-picture');

    @Mutation
    setUserInfoMutation(userInfo: UserInfo): void {
        this.userInfo = userInfo;
    }

    @Action({ commit: 'setUserInfoMutation' })
    setUserInfo(userInfo: UserInfo): UserInfo {
        return userInfo;
    }

    @Mutation
    setUserPicture(blob: string): void {
        localStorage.setItem('user-picture', blob)
    }

    get userLogged() {
        return this.userInfo;
    }

    get isWalletEnable() {
        return !!this.userInfo &&
            Array.isArray(this.userInfo.features) &&
            this.userInfo.features.includes("WALLET");
    }

    get isPartialCancelEnable() {
        return !!this.userInfo &&
            Array.isArray(this.userInfo.features) &&
            this.userInfo.features.includes("PARTIAL_CANCEL");
    }
}
