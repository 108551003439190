import { DialogIdentifier } from 'checkout/enums/dialog-identifier.enum';
import { PaymentType } from 'checkout/enums/payment-type.enum';
import { CardInfo } from 'checkout/interfaces/card-info.interface';
import { CardIdentifier } from 'checkout/models/card.identifier.model';
import { PaymentService } from 'checkout/services/payment.service';
import store from 'checkout/store';
import PaymentModule from 'checkout/store/payment';
import VueRouter, { Route } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import { DialogUtil } from '../dialog/dialog.util';

const payment: PaymentModule = getModule(PaymentModule, store);

class Util {
    unformatCardNumber(cardNumber: string): string {
        return cardNumber.replace(/ /g, '').trim();
    }

    identifyCard(cardNumber: string): CardIdentifier {
        const cardsPatterns: CardIdentifier[] = this.getCardPatterns();

        const card: CardIdentifier = cardsPatterns.find((card: CardIdentifier) =>
            card.p.test(this.unformatCardNumber(cardNumber))
        );

        return (
            card || {
                p: null,
                patternValidation: /\d{15,16}$/,
                brand: '',
                spacingPattern: '#### #### #### ####',
                cvvPattern: '####',
            }
        );
    }

    getCardPatterns(): CardIdentifier[] {
        const EloRangeChecker: any = {
            test: (s: string): boolean => {
                const between: Function = (from: number, value: number, to: number): boolean =>
                 value >= from && value <= to;

                const bin: number = Number(s.substr(0, 6));

                return (
                    bin > 100000 &&
                    (between(506699, bin, 506778) ||
                        between(509000, bin, 509999) ||
                        between(650031, bin, 650033) ||
                        between(650035, bin, 650051) ||
                        between(650405, bin, 650439) ||
                        between(650485, bin, 650538) ||
                        between(650541, bin, 650598) ||
                        between(650700, bin, 650718) ||
                        between(650720, bin, 650727) ||
                        between(650901, bin, 650920) ||
                        between(506699, bin, 506778) ||
                        between(651652, bin, 651679) ||
                        between(509000, bin, 509999) ||
                        between(655000, bin, 655019) ||
                        between(655021, bin, 655058))
                );
            },
        };

        const cardsPatterns: CardIdentifier[] = [
            {
                p: /^5[1-5][0-9]\d{0,14}$/,
                patternValidation: /^5[1-5]\d{14}$/,
                brand: 'MASTERCARD',
                spacingPattern: '#### #### #### ####',
                cvvPattern: '###',
            },
            {
                p: /^2\d{0,15}$/,
                patternValidation: /^2\d{15}$/,
                brand: 'MASTERCARD',
                spacingPattern: '#### #### #### ####',
                cvvPattern: '###',
            },
            {
                p: /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,12}$/,
                patternValidation: /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{12}$/,
                brand: 'MASTERCARD',
                spacingPattern: '#### #### #### ####',
                cvvPattern: '###',
            },
            {
                p: /^3[47][0-9]{0,13}$/,
                patternValidation: /^3[47][0-9]{13}$/,
                brand: 'AMEX',
                spacingPattern: '#### ###### #####',
                cvvPattern: '####',
            },
            {
                p: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
                patternValidation: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
                brand: 'DINERS',
                spacingPattern: '#### ###### ####',
                cvvPattern: '###',
            },
            {
                p: /^((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10}$/,
                patternValidation: /^((636368)|(438935)|(504175)|(451416)|(636297))\d{10}$/,
                brand: 'ELO',
                spacingPattern: '#### #### #### ####',
                cvvPattern: '###',
            },
            {
                p: /^((401178)|(401179)|(431274)|(438935)|(451416))\d{0,10}$/,
                patternValidation: /^((401178)|(401179)|(431274)|(438935)|(451416))\d{10}$/,
                brand: 'ELO',
                spacingPattern: '#### #### #### ####',
                cvvPattern: '###',
            },
            {
                p: /^(((457393)|(457631)|(457632)|(627780))\d{0,10})$/,
                patternValidation: /^(((457393)|(457631)|(457632)|(627780))\d{10})$/,
                brand: 'ELO',
                spacingPattern: '#### #### #### ####',
                cvvPattern: '###',
            },
            {
                p: EloRangeChecker,
                patternValidation: EloRangeChecker,
                brand: 'ELO',
                spacingPattern: '#### #### #### ####',
                cvvPattern: '###',
            },
            {
                p: /^(((6011)\d{0,12})|((622)\d{0,13})|((64)|(65)\d{0,14}))$/,
                patternValidation: /^(((6011)\d{0,12})|((622)\d{0,13})|((64)|(65)\d{14}))$/,
                brand: 'DISCOVER',
                spacingPattern: '#### #### #### ####',
                cvvPattern: '###',
            },
            {
                p: /^4\d{0,15}$/,
                patternValidation: /^4\d{15}$/,
                brand: 'VISA',
                spacingPattern: '#### #### #### ####',
                cvvPattern: '###',
            },
            {
                p: /^4[0-9]{12}(?:[0-9]{3})$/,
                patternValidation: /^4[0-9]{12}(?:[0-9]{3})$/,
                brand: 'VISA',
                spacingPattern: '#### #### #### ####',
                cvvPattern: '###',
            },
            {
                p: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
                patternValidation: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
                brand: 'HIPERCARD',
                spacingPattern: '#### #### #### ####',
                cvvPattern: '###',
            },
        ];

        return cardsPatterns;
    }

    cardUpdate(): void {
        const card: CardIdentifier = this.identifyCard(payment.paymentInfo.card.number);
        if (!card) {
            return;
        }
        payment.setCardBrand(card.brand);
    }

    async checkCardInfo(router: VueRouter, route: Route): Promise<void> {
        const cardBin: string = this.unformatCardNumber(payment.paymentInfo.card.number).substring(0, 6);

        if (!cardBin || cardBin.length !== 6) {
            return;
        }

        try {
            const cardInfo: CardInfo = await PaymentService.checkCardInfo(cardBin);
            if (!cardInfo) {
                return;
            }

            if (
                payment.paymentInfo.type === PaymentType.CREDIT &&
                (!payment.paymentInfo.brand || payment.paymentInfo.brand !== cardInfo.provider)
            ) {
                payment.setCardBrand(cardInfo.provider);
            }

            // const type: string =
            //     payment.paymentInfo.type === PaymentType.CREDIT
            //         ? 'Credito'
            //         : payment.paymentInfo.type === PaymentType.DEBIT
            //         ? 'Debito'
            //         : '';

            // if (cardInfo.cardType !== type && cardInfo.cardType !== 'Multiplo') {
            //     if (type === 'Credito') {
            //         DialogUtil.openDialog(DialogIdentifier.DEBIT_CREDIT_DIALOG, router, route);
            //         return;
            //     }

            //     if (type === 'Debito') {
            //         DialogUtil.openDialog(DialogIdentifier.CREDIT_DEBIT_DIALOG, router, route);
            //         return;
            //     }
            // }

            if (cardInfo.foreignCard) {
                DialogUtil.openDialog(DialogIdentifier.FOREIGN_CARD_DIALOG, router, route);
            }
        } catch (error) {
            return;
        }
    }
}

export const CreditCardUtil: Util = new Util();
