import { CancelType } from 'account/enums/cancel-type.enum';
import { ReserveOperationDTO } from 'account/services/dto';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'BsCancelDetail',
    template: require('./bs-cancel-detail.html'),
})
export default class BSCancelDetailComponent extends Vue {
    @Prop()
    travelInfoData: ReserveOperationDTO;

    @Prop()
    cancelAction: () => void;

    @Prop()
    partialCancel: (reserveIds: number[]) => void;

    @Prop()
    changeOperation: () => void;

    @Prop()
    mode: CancelType;

    @Prop()
    selectedReserves: Array<number>;

    cancelText: string = '';

    completeCancel() {
        if (this.mode === CancelType.ONLY_GO) {
            const reserves = this.travelInfoData.operation.reservedSeatsGo.filter(reserve => reserve.status !== 'CANCELLED').map(reserve => reserve.id )
            return this.partialCancel(reserves);
        }
        if (this.mode === CancelType.ONLY_BACK) {
            const reserves = this.travelInfoData.operation.reservedSeatsBack.filter(reserve => reserve.status !== 'CANCELLED').map(reserve => reserve.id)
            return this.partialCancel(reserves);
        }
        if (this.mode === CancelType.PARTIAL) {
            return this.partialCancel(this.selectedReserves);
        }
        if (this.mode === CancelType.COMPLETE) {
            return this.cancelAction();
        }
        return null;
    }

    get haveBack() {
        return !(this.mode === CancelType.ONLY_GO);
    }

    get haveGo() {
        return !(this.mode === CancelType.ONLY_BACK);
    }

    get passengersGo() {
        if (!this.travelInfoData.operation) {
            return [];
        }
        const origins = {} as { [key: string]: Array<{name: string, seat: string}> };
        const selectedReserves = this.mode === CancelType.COMPLETE ? [] : [...this.selectedReserves];

        this.travelInfoData.operation.reservedSeatsGo.filter(reserve => reserve.status !== 'CANCELLED').forEach(reserve => {
            if (!origins[reserve.origin]) {
                origins[reserve.origin] = [];
            }
            if(selectedReserves.length > 0) {
                if(selectedReserves.includes(reserve.id)) {
                    origins[reserve.origin].push({
                        name: reserve.passengerName,
                        seat: reserve.seatNumber,
                    });
                }
            } else {
                origins[reserve.origin].push({
                    name: reserve.passengerName,
                    seat: reserve.seatNumber,
                });
            }
        });
        return Object.values(origins);
    }

    get passengersBack() {
        if (!this.travelInfoData.operation) {
            return [];
        }
        const selectedReserves = this.mode === CancelType.COMPLETE ? [] : [...this.selectedReserves];
        const origins = {} as { [key: string]: Array<{name: string, seat: string}> };
        this.travelInfoData.operation.reservedSeatsBack.filter(reserve => reserve.status !== 'CANCELLED').forEach(reserve => {
            if (!origins[reserve.origin]) {
                origins[reserve.origin] = [];
            }
            if(selectedReserves.length > 0) {
                if(selectedReserves.includes(reserve.id)) {
                    origins[reserve.origin].push({
                        name: reserve.passengerName,
                        seat: reserve.seatNumber,
                    });
                }
            } else {
                origins[reserve.origin].push({
                    name: reserve.passengerName,
                    seat: reserve.seatNumber,
                });
            }
        });
        return Object.values(origins);
    }
    orderInfo(index: number) {
        switch(index) {
            case 0:
                return 'Primeiro';
            case 1:
                return 'Segundo';
            case 2:
                return 'Terceiro';
            case 3:
                return 'Quarto';
            default:
                return index+'º';
        }
    }
}
