import { MDCRipple } from "@material/ripple";
import { Provider } from "account/enums/provider.enum";
import { UserCreate } from "account/interfaces/user-create.interface";
import { UserInfo } from "account/interfaces/user-info.interface";
import { AccountService } from "account/services/account.service";
import { AuthenticationService } from "account/services/authentication.service";
import storeAccount from "account/store";
import LoadingModuleAccount from "account/store/loading";
import storeCheckout from "checkout/store";
import LoadingModuleCheckout from "checkout/store/loading";
import { ErrorField, Validator } from "vee-validate/types/vee-validate";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import GVButton from "vue-shared/components/gv-button/gv-button";
import GVNotification from "vue-shared/components/gv-notification";
import GVTextfield from "vue-shared/components/gv-textfield/gv-textfield";
import { getModule } from "vuex-module-decorators";
import { AppRouterAccount } from "account/router";
import { RouterPath } from "account/enums/router-path.enum";
import ModalLoginModule from "vue-shared/store/modal-login";
import storeShared from "vue-shared/store";
import { LoadingComponent } from "commons/ts/loading.component";

declare const window: any;

const loadingAccount: LoadingModuleAccount = getModule(
    LoadingModuleAccount,
    storeAccount,
);
const loadingCheckout: LoadingModuleCheckout = getModule(
    LoadingModuleCheckout,
    storeCheckout,
);
const modalLogin: ModalLoginModule = getModule(ModalLoginModule, storeShared);

@Component({
    name: "GVLogin",
    template: require("./gv-login.html"),
    components: {
        "gv-textfield": GVTextfield,
        "gv-button": GVButton,
        "gv-notification": GVNotification,
    },
})
export default class GVLogin extends Vue {
    @Inject()
    $validator: Validator;

    private email: string = "";
    private password: string = "";
    private redefineEmail: string = "";
    private isShowingPassword: boolean = false;
    private customMessage: string = "";
    private customMessageType: string = "";
    private showLogin: boolean = true;
    private showRegister: boolean = false;
    private showRemember: boolean = false;
    private userRegister: UserCreate = {
        email: "",
        name: "",
        password: "",
        passwordConfirmation: "",
        newsletter: true,
        token: "",
    };
    private showAlert = false;
    private loadingComponent = new LoadingComponent("load", "Carregando");

    mounted(): void {
        this.loading.hide();
        if (this.isCheckoutLogin) {
            new MDCRipple(this.$el.querySelector(".mdc-fab"));
        }
        if (this.$route.query.alert) {
            this.showAlert = true;
        }
        setTimeout(() => {
            this.loadAutofill();
        }, 700);

        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
            "src",
            "https://www.google.com/recaptcha/enterprise.js",
        );
        recaptchaScript.setAttribute("async", "true");
        recaptchaScript.setAttribute("defer", "true");
        document.head.appendChild(recaptchaScript);
    }

    loadAutofill(): void {
        // this loads the necessary class to adjust the mdc-text-field components title
        this.$el
            .querySelectorAll(".mdc-text-field__input:-webkit-autofill")
            .forEach((el: HTMLElement) => {
                const textField: ParentNode = el.parentNode;
                const label: HTMLElement = textField.querySelector(
                    ".mdc-floating-label",
                );
                if (label) {
                    label.classList.add("mdc-floating-label--float-above");
                }

                const notch: HTMLElement = textField.querySelector(
                    ".mdc-notched-outline",
                );
                if (notch) {
                    notch.classList.add("mdc-notched-outline--notched");

                    const innerNotch: HTMLElement = notch.querySelector(
                        ".mdc-notched-outline__notch",
                    );
                    if (innerNotch) {
                        innerNotch.style.width = "41px";
                    }
                }
            });
    }

    hasError(fieldName: string): boolean {
        return !!this.getError(fieldName);
    }

    getError(fieldName: string): string {
        const foundError: any = this.errors.items.find(
            (error: any) => error.field === fieldName,
        );
        return foundError ? foundError.msg : null;
    }

    async signInGV(): Promise<void> {
        try {
            this.loadingComponent.appear(document.body);
            if (!(await this.$validator.validateAll("login"))) {
                return;
            }
            let token = "";
            if (this.isProduction) {
                token = await window.grecaptcha.enterprise.getResponse();
            }
            await AuthenticationService.authenticateGV(
                this.email,
                this.password,
                Provider.GUICHE_VIRTUAL,
                token,
            );
            window.grecaptcha.enterprise.reset();
            if (!this.isCheckoutLogin) {
                if (
                    document.referrer &&
                    document.referrer.endsWith("/minhas-viagens")
                ) {
                    window.location.href = RouterPath.TRAVEL + "?alert=true";
                    return;
                }
                if (this.$route.query.to) {
                    window.location.href = `${this.$route.query.to}`;
                    return;
                }
                window.location.href = document.referrer;
            }

            this.authenticateCheckout();
        } catch (error) {
            this.customMessage = error;
            this.customMessageType = "error";
        } finally {
            this.loadingComponent.disappear();
        }
    }

    async signInGoogle(): Promise<void> {
        try {
            const googleUser: any = await this.$gAuth.signIn();

            if (!googleUser) {
                return;
            }

            this.loadingComponent.appear(document.body);

            await AuthenticationService.authenticateSocial(
                Provider.GOOGLE,
                googleUser.getAuthResponse().id_token,
            );

            if (!this.isCheckoutLogin) {
                window.location.href = RouterPath.HOME;
            }

            this.authenticateCheckout();
        } catch (error) {
            if (!!error && error.error === "popup_closed_by_user") {
                return;
            }
            this.customMessage = error;
            this.customMessageType = "error";
        } finally {
            this.loadingComponent.disappear();
        }
    }

    async signInFacebook(): Promise<void> {
        this.clearCustomMessage();

        const options: facebook.LoginOptions = {
            scope: "email",
            auth_type: "rerequest",
        };

        FB.login(async (response: any) => {
            if (
                !response.authResponse ||
                !response.authResponse.accessToken ||
                !response.authResponse.userID
            ) {
                return;
            }

            FB.api(`/me`, { fields: "email" }, async (meResponse: any) => {
                this.loadingComponent.appear(document.body);

                if (!meResponse.email) {
                    FB.api(
                        `${response.authResponse.userID}/permissions`,
                        async (permissionRes: any) => {
                            const hasDeclinedPermission: boolean =
                                permissionRes.data.some(
                                    (x) =>
                                        x.permission === "email" &&
                                        x.status === "declined",
                                );
                            this.customMessage = hasDeclinedPermission
                                ? "Precisamos ter acesso ao seu e-mail do Facebook"
                                : "É necessário que a conta do Facebook possua um e-mail";
                            this.customMessageType = "error";
                            this.loadingComponent.disappear();
                        },
                    );
                    return;
                }

                try {
                    await AuthenticationService.authenticateSocial(
                        Provider.FACEBOOK,
                        response.authResponse.accessToken,
                    );
                    if (!this.isCheckoutLogin) {
                        window.location.href = RouterPath.HOME;
                    }
                    this.authenticateCheckout();
                } catch (error) {
                    this.customMessage = error;
                    this.customMessageType = "error";
                } finally {
                    this.loadingComponent.disappear();
                }
            });
        }, options);
    }

    async signUpGV(e): Promise<void> {
        try {
            if (
                !(await this.$validator.validateAll("register")) ||
                this.errors.items.length > 0
            ) {
                return;
            }
            this.userRegister.token = window.captchatoken;

            if(!this.userRegister.token) {
                this.customMessage = "Execute a validação de robô";
                this.customMessageType = "error";
                return;
            }

            this.loadingComponent.appear(document.body);

            const user: UserInfo = await AccountService.createUser(
                this.userRegister,
            );

            if (!user.token) {
                // throw 'Não foi possível cadastrar o usuário';
                this.customMessage = "Um email de confirmação foi enviado";
                this.customMessageType = "success";
                return;
            }

            await AuthenticationService.authenticate();

            if (!this.isCheckoutLogin) {
                window.location.href = RouterPath.HOME;
            }

            this.authenticateCheckout();
        } catch (error) {
            this.customMessage = error;
            this.customMessageType = "error";

            if (!this.isCheckoutLogin) {
                AppRouterAccount.push(RouterPath.LOGIN);
            }
        } finally {
            this.loadingComponent.disappear();
            this.loading.hide();
        }
    }

    authenticateCheckout(): void {
        if (!this.isCheckoutLogin) {
            return;
        }

        this.$emit("authenticate-checkout");
    }

    async redefinePassword(): Promise<void> {
        try {
            if (!(await this.$validator.validateAll("redefine"))) {
                return;
            }

            this.loading.show();

            await AccountService.redefinePassword(this.redefineEmail);

            this.customMessage = "Email enviado com sucesso";
            this.customMessageType = "success";
        } catch (error) {
            this.customMessage = error;
            this.customMessageType = "error";
        } finally {
            this.loading.hide();
        }
    }

    toggleLogin(): void {
        this.showLogin = true;
        this.showRegister = false;
        this.showRemember = false;
        this.clearCustomMessage();
    }

    toggleRegister(): void {
        this.showLogin = false;
        this.showRegister = true;
        this.showRemember = false;
        this.clearCustomMessage();
    }

    toggleRemember(): void {
        this.showLogin = false;
        this.showRegister = false;
        this.showRemember = true;
        this.clearCustomMessage();
    }

    clearCustomMessage(): void {
        this.customMessage = "";
        this.customMessageType = "";
    }

    changeVisibility(): void {
        this.isShowingPassword = !this.isShowingPassword;
    }

    get iconPassword(): string {
        return this.isShowingPassword ? "visibility_off" : "visibility";
    }

    get isProduction(): boolean {
        return process.env.NODE_ENV === 'production'
    }

    get typePassword(): string {
        return this.isShowingPassword ? "text" : "password";
    }

    validatePassword(): void {
        this.errors.items = this.errors.items.filter((item) => {
            return item.field !== 'password' && item.field !== 'password-confirmation'
        });
        const re: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z0-9!-\/:-@])(?=.*[!-.:-@]).{8,50}$/;
        if(!re.test(this.userRegister.password)) {
            const error: ErrorField = {
                field: "password",
                rule: "confirmed",
                scope: "required",
                msg: "A senha deve conter pelo menos 8 dígitos, uma letra maiúscula, uma letra minúscula, um símbolo e um número",
            };

            this.errors.items.push(error)
        }

        // don't use filter to remove, the reference is lost and vee-validate can't find the errors array
        const index: number = this.errors.items.findIndex(
            (item: ErrorField) => {
                return (
                    item.field === "password-confirmation" &&
                    item.rule === "confirmed" &&
                    item.scope === "required"
                );
            },
        );

        if (index !== -1) {
            this.errors.items.splice(index, 1);
        }

        if (
            this.userRegister.password !==
            this.userRegister.passwordConfirmation
        ) {
            const error: ErrorField = {
                field: "password-confirmation",
                rule: "confirmed",
                scope: "required",
                msg: "As senhas não coincidem",
            };

            this.errors.items.push(error);
        }
    }

    get loading(): LoadingModuleAccount | LoadingModuleCheckout {
        if (this.isCheckoutLogin) return loadingCheckout;
        return loadingAccount;
    }

    closeModal(): void {
        this.$emit("close-modal");
    }

    get userHasOneClick() {
        return modalLogin.userHasOneClick;
    }

    get isCheckoutLogin() {
        return modalLogin.isCheckoutLogin;
    }
}
