import { TravelService } from 'account/services';
import AccountModule from 'account/store/account';
import LoadingModule from 'account/store/loading';
import storeAccount from 'account/store';
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { ReserveOperationDTO } from 'account/services/dto';
import BSTravelDetailComponent from './components/bs-travel-detail';
import BSTravelPaymentComponent from './components/bs-travel-payment';
import BSCancelDetailComponent from './components/bs-cancel-detail';
import BSCancelSelectorComponent from './components/bs-cancel-selector';
import { CancelType } from 'account/enums/cancel-type.enum';
import BSTravelCancelSelectionComponent from './components/bs-travel-cancel-selection/bs-travel-cancel-selection';
import { AccountAmplitude } from 'account/services/amplitude.service';
import { PageName } from 'account/enums/page-name.enums';
import BSSwapTravelSelectionComponent from './components/bs-swap-travel-selection';

const account: AccountModule = getModule(AccountModule, storeAccount);

@Component({
    name: 'TravelInfoPage',
    template: require('./travel-info.page.html'),
    components: {
        'bs-travel-detail': BSTravelDetailComponent,
        'bs-travel-payment': BSTravelPaymentComponent,
        'bs-cancel-detail': BSCancelDetailComponent,
        'bs-cancel-selector': BSCancelSelectorComponent,
        'bs-travel-cancel-selection': BSTravelCancelSelectionComponent,
        'bs-swap-travel-selection': BSSwapTravelSelectionComponent,
    }
})
export default class TravelInfoPage extends Vue {
    private _loadingModule: LoadingModule;
    private _travelService: TravelService;
    private operationId: number;
    private travelInfoData: ReserveOperationDTO = {
        operation: null,
        payment: null,
        busCompany: [],
        busStation: []
    };

    actualOperation: string = 'display';
    mode: string = CancelType.COMPLETE;
    selectedReserves = new Array<number>();
    defaultSelection: CancelType = CancelType.PARTIAL;
    directCancel: boolean;

    created() {
        this._loadingModule = LoadingModule.getInstance();
        this._travelService = TravelService.getInstance();
        this.operationId = Number(this.$route.params.operationId);
        this.directCancel = Boolean(this.$route.query.directCancel);
        AccountAmplitude.accountOpen(PageName.TRAVEL_INFO);
    }

    async mounted() {
        this._loadingModule.startLoading();
        try{
            this.travelInfoData = await this._travelService.getReserveFromTravel(this.operationId, account.userInfo.email);
        } finally {
            this._loadingModule.finishLoading();
        }
        if (this.directCancel) {
            this.actualOperation = 'cancelSelector';
        }
    }

    setSelectedReserves(reserves: number[]) {
        this.selectedReserves = reserves;
    }

    get travelInfo() {
        if (!this.travelInfoData) {
            return null;
        }
        return this.travelInfoData;
    }

    get hasBack() {
        if (!this.travelInfoData.operation) {
            return false;
        }
        return this.travelInfoData.operation.reservedSeatsBack.length > 0;
    }

    get showGo() {
        if (!this.travelInfoData.operation) {
            return false;
        }
        return !!this.travelInfoData.operation.reservedSeatsGo.some(reserve => reserve.status !== 'CANCELLED');
    }

    get showBack() {
        if (!this.travelInfoData.operation) {
            return false;
        }
        return !!this.travelInfoData.operation.reservedSeatsBack.some(reserve => reserve.status !== 'CANCELLED');
    }

    async cancelReserve() {
        this._loadingModule.startLoading();
        AccountAmplitude.fullCancel();
        try {
            const {status} = await this._travelService.cancelReserve(this.operationId, account.userInfo.email);
            if (status >= 400) {
                throw new Error(status.toString());
            }
            this.$router.push('/detalhes-viagem/info?messageType=success-cancel');

        }catch (e) {
            this.$router.push('/detalhes-viagem/info?messageType=error-cancel-try-again');
        } finally {
            this._loadingModule.finishLoading();
        }
    }

    async partialCancel(reserveIds: number[]) {
        this._loadingModule.startLoading();
        AccountAmplitude.partialCancel();
        try {
            const res = await this._travelService.partialCancel(this.operationId, account.userInfo.email, reserveIds);
            if (typeof res === 'number') {
                throw new Error(res.toString());
            }
            this.$router.push('/detalhes-viagem/info?messageType=success-cancel');
        }catch (e) {
            if(e instanceof Error) {
                if (e.message == '500') {
                    return this.$router.push('/detalhes-viagem/info?messageType=error-cancel-try-again');
                }
                if (e.message == '503') {
                    return this.$router.push('/detalhes-viagem/info?messageType=error-cancel-try-again');
                }
                if (e.message == '400') {
                    return this.$router.push('/detalhes-viagem/info?messageType=error-cancel');
                }
                if (e.message == '403') {
                    return this.$router.push('/detalhes-viagem/info?messageType=error-cancel');
                }
                return this.$router.push('/detalhes-viagem/info?messageType=error-cancel-try-again');
            }

        } finally {
            this._loadingModule.finishLoading();
        }
    }

    get operation() {
        return this.actualOperation;
    }

    changeOperation(operation: string) {
        this.actualOperation = operation;
    }

    changeMode(mode: string) {
        this.mode = mode;
    }

    changeDefaultSelection(selection: CancelType) {
        this.defaultSelection = selection;
    }
}
