import { CancelType } from 'account/enums/cancel-type.enum';
import { AccountAmplitude } from 'account/services/amplitude.service';
import { ReserveOperationDTO } from 'account/services/dto';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { parseDateToString } from 'vue-shared/utils/date';
import { ImgsUtil } from 'vue-shared/utils/imgs/imgs.util';
import dateUtils from '../../../../../../../../ts/modules/utils/date-util';
import BSSwapTravelModalComponent from '../bs-swap-travel-modal';
import { AdapterData } from 'checkout/interfaces/adapter-data.interface';

@Component({
    name: 'BsTravelDetail',
    template: require('./bs-travel-detail.html'),
    components: {
        'bs-swap-travel-modal': BSSwapTravelModalComponent,
    }
})
export default class BSTravelDetailComponent extends Vue {
    @Prop()
    title: string;

    @Prop()
    back: boolean;

    @Prop()
    travelInfoData: ReserveOperationDTO;

    @Prop()
    changeOperation: (operation: string) => void;

    @Prop()
    changeDefaultSelection: (selection: CancelType) => void;

    active = false;

    showTravelCancellation = false;

    showTravelReschedule = false;

    showSwapModal = false;

    enableSwapButton = false;

    mounted() {
        const adapterData: AdapterData = window['adapterData'];

        if (adapterData.enableSwapButton) {
            this.enableSwapButton = true;
        }

        if (!this.back) {
            this.toggleActive();
        }
    }

    get isActive() {
        return this.active;
    }

    get swapEnabled() {
        return this.enableSwapButton;
    }

    toggleActive() {
        this.active = !this.active;
    }

    changeSelection() {
        this.changeDefaultSelection(this.back ? CancelType.ONLY_BACK : CancelType.ONLY_GO);
        this.changeOperation('cancelSelector');
    }

    changeToSwapTravel() {
        if (this.back) {
            this.changeOperation('swap-travel-back');
        } else {
            this.changeOperation('swap-travel');
        }
    }

    get dropdownIcon() {
        return this.active ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    }

    get boardingPass() {
        return `${ImgsUtil.staticAws()}/_v2/static/img/icons/boarding-pass-icon.svg`
    }

    get travelInfo() {
        if (!this.travelInfoData) {
            return null;
        }
        return this.travelInfoData;
    }

    get cancelAllowed() {
        const isBlockedCompany = this.travelInfoData.busCompany.filter(b => b.key.includes('OFFLINE')).length > 0;
        if (isBlockedCompany) {
            return false;
        }
        if (!this.travelInfoData.operation) {
            return false;
        }
        let maxTime = dateUtils.subtract(this.travelInfoData.operation.reservedSeatsGo[0].departureDate, 3, 'hour');
        if (this.back) {
            maxTime = dateUtils.subtract(this.travelInfoData.operation.reservedSeatsBack[0].departureDate, 3, 'hour');
        }
        const diff = dateUtils.getDateDiff(new Date(), maxTime)
        return diff !== '' ? diff.milliseconds() > 0 : false;
    }

    get swapAllowed() {
        const isBlockedCompany = this.travelInfoData.busCompany.filter(b => !(b.key.includes('-v') || b.key.includes('-f') || b.key === 'VLI') || b.key.includes('OFFLINE')).length > 0;
        const haveConnection = this.origins.length > 1
        return isBlockedCompany && !haveConnection
    }

    operation() {
        if (this.travelInfoData.operation) {
            return this.travelInfoData.operation;
        }
        return null;
    }

    reserves(origin: number, filteredInformation: boolean = true) {
        if (!this.travelInfoData.operation) {
            return [];
        }
        if (this.back) {

            return filteredInformation ? this.operation().reservedSeatsBack.filter(reserve => reserve.origin === origin) : this.operation().reservedSeatsBack;
        }
        return filteredInformation ? this.operation().reservedSeatsGo.filter(reserve => reserve.origin === origin) : this.operation().reservedSeatsGo;
    }

    get origins() {
        const reserves = this.reserves(null, false);
        const reserveLocations = new Set<number>();

        reserves.forEach(reserve => {
            reserveLocations.add(reserve.origin);
        });

        return Array.from(reserveLocations);
    }

    departureDate(origin: number) {
        if (!this.travelInfoData.operation) {
            return '';
        }
        const date = new Date(this.reserves(origin)[0].departureDate);
        return parseDateToString(date, "Dow, DD mth");
    }

    companyName(origin: number) {
        if (!this.travelInfoData.operation) {
            return '';
        }
        return this.reserves(origin)[0].companyKey;
    }

    departureTime(origin: number) {
        if (!this.travelInfoData.operation) {
            return '';
        }
        const date = new Date(this.reserves(origin)[0].departureDate);
        return parseDateToString(date, "hh:mm");
    }

    arrivalTime(origin: number) {
        if (!this.travelInfoData.operation) {
            return '';
        }
        const reserves = this.reserves(origin);
        const date = new Date(reserves[reserves.length - 1].arrivalDate);
        return parseDateToString(date, "hh:mm");
    }

    passengers(origin) {
        if (!this.travelInfoData.operation) {
            return '';
        }
        return this.reserves(origin).filter(reserve => reserve.origin);
    }



    hasEasyBoarding(origin: number) {
        if (!this.travelInfoData.operation) {
            return '';
        }
        let easyBoarding = this.reserves(origin).find(reserve => reserve.easyBoarding);
        return easyBoarding ? true : false;
    }

    departurePlace(origin: number) {
        if (!this.travelInfoData.operation) {
            return '';
        }
        const id = this.reserves(origin)[0].origin;
        const location = this.travelInfoData.busStation.find(station => station.id === id);
        return location.label;
    }

    arrivalPlace(origin: number) {
        if (!this.travelInfoData.operation) {
            return '';
        }
        const reserves = this.reserves(origin);
        const id = reserves[reserves.length - 1].destination;
        const location = this.travelInfoData.busStation.find(station => station.id === id);
        return location.label;
    }

    companyLogo(origin: number) {
        if (!this.travelInfoData.operation) {
            return '';
        }
        const companyKey = this.reserves(origin)[0].companyKey;
        const company = this.travelInfoData.busCompany.find(station => station.key === companyKey);
        return company.logo;
    }

    departurePlaceUrl(origin: number) {
        if (!this.travelInfoData.operation) {
            return '';
        }
        const id = this.reserves(origin)[0].origin;
        const location = this.travelInfoData.busStation.find(station => station.id === id);
        return '/rodoviaria-' + location.url;
    }

    get haveConnection() {
        return this.origins.length > 1;
    }

    orderInfo(index: number) {
        switch (index) {
            case 0:
                return 'Primeiro';
            case 1:
                return 'Segundo';
            case 2:
                return 'Terceiro';
            case 3:
                return 'Quarto';
            default:
                return index + 'º';
        }
    }

    get alreadyCancelled() {
        if (!this.travelInfoData.operation) {
            return true;
        }
        if (!this.reserves(null, false).some(reserve => reserve.status !== 'CANCELLED')) {
            return true;
        }
        return this.travelInfoData.operation.status === 'CANCEL' || this.travelInfoData.operation.status === 'EXPIRED';
    }

    get disclaimers() {
        const disclaimerSet = new Set();
        this.reserves(null, false).forEach(reserve => {
            reserve.disclaimers.forEach(disclaimer => {
                disclaimerSet.add(disclaimer.html.replace('<b>Atenção passageiro!</b>', '').trim() + '.')
            });
        });
        return Array.from(disclaimerSet);
    }

    get whatsappImg() {
        return `${ImgsUtil.staticAws()}/_v2/static/img/whatsapp.webp`;
    }

    get phoneImg() {
        return `${ImgsUtil.staticAws()}/_v2/static/img/phone.webp`;
    }

    toggleCancellation() {
        this.showTravelCancellation = !this.showTravelCancellation;
    }

    toggleReschedule() {
        this.showTravelReschedule = !this.showTravelReschedule;

        // hide selector
        if (this.showTravelReschedule === true) {
            this.showTravelCancellation = false;
        }
    }

    get cancellation() {
        return this.showTravelCancellation;
    }

    get reschedule() {
        return this.showTravelReschedule;
    }

    trackAmplitudeCancellation(type: string) {
        AccountAmplitude.cancellation(type);
    }

    get showModal() {
        AccountAmplitude.swapModal();
        return this.showSwapModal;
    }

    toggleSwapModal() {
        this.showSwapModal = !this.showSwapModal;
    }
}
