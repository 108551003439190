import { CurrencyFormat } from 'checkout/interfaces/currency-format.interface';

class Util {
    format: CurrencyFormat = {
        prefix: 'R$ ',
        decimal: ',',
        thousands: '.',
        precision: 2,
    };

    formatCurrency = (value: number = 0, format?: CurrencyFormat): string => {
        if (format) {
            this.format = format;
        }

        const signal: string = value < 0 ? '-' : '';
        const val: string = Math.abs(value)
            .toFixed(this.format.precision)
            .replace('.', this.format.decimal);

        return `${signal}${this.format.prefix}${val
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, this.format.thousands)}`;
    };

    formatCurrencyNoPrefix = (value: number = 0, format?: CurrencyFormat): string => {
        if (format) {
            this.format = format;
        }

        const val = value.toFixed(this.format.precision).replace('.', this.format.decimal);
        return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.format.thousands)}`;
    };

    unformat = (value: string, format?: CurrencyFormat): number => {
        if (format) {
            this.format = format;
        }

        const thousands: string = value
            .replace(this.format.prefix, '')
            .split(this.format.decimal)[0]
            .replace(/\D/g, '');
        const decimal: string = value.replace(this.format.prefix, '').split(this.format.decimal)[1];
        return parseFloat(`${thousands}.${decimal}`);
    };

    roundToDecimals = (value: number, decimals: number): number => {
        return parseFloat((Math.round(value * 100) / 100).toFixed(decimals));
    };
}

export const CurrencyUtil: Util = new Util();
