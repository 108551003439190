import {
    Action,
    Module,
    Mutation,
    VuexModule,
    getModule
} from 'vuex-module-decorators';
import { Store } from 'account/store';
import { DestinationBanner } from 'account/model';
import { DestinationBannerService } from 'account/services';

@Module({
    name: 'destination-banner',
    namespaced: true,
})
export default class DestinationBannerModule extends VuexModule {
    private static _instance: DestinationBannerModule;
    private _destinationBannerService: DestinationBannerService;

    /**
     * Repository of DestinationBanner.
     * 
     * It's a map from BusStation.id to DestinationBanner.
     */
    private _repo: Map<number, DestinationBanner> = new Map<number, DestinationBanner>();

    /**
     * List of default links for DestinationBanner. Used on the absence of a DestinationBanner.
     */
    private _defaultLinks: Array<string> = [];

    static getInstance(): DestinationBannerModule {
        if (!this._instance) {
            this._instance = getModule(DestinationBannerModule, Store.getInstance());
            this._instance.init();
        }

        return this._instance;
    }

    @Action
    private async init(): Promise<void> {
        this._destinationBannerService = DestinationBannerService.getInstance();
        this.fetchDestinationBannerDefaultLinks();
    }

    @Mutation
    private _insertDefaultLink(link: string) {
        if (!this._defaultLinks.includes(link))
            this._defaultLinks.push(link);
    }

    @Action
    private async fetchDestinationBannerDefaultLinks(): Promise<void> {
        const links: Array<string> = await this._destinationBannerService.getDefaultLinks();
        links.forEach(link => this.context.commit('_insertDefaultLink', link));
    }

    @Mutation
    private _insertDestinationBanner(destinationBanner: DestinationBanner): void {
        this._repo.set(destinationBanner.destinationId, destinationBanner);
    }

    @Action
    insertDestinationBanner(destinationBanner: DestinationBanner): void {
        this.context.commit('_insertDestinationBanner', destinationBanner);
    }

    get getBannerUrl(): (destinationId: number, def?: string) => string {
        return (destinationId: number, def: string = ""): string => {
            if (this._repo.has(destinationId))
                return "/image/view/" + this._repo.get(destinationId).imgId;

            if (!!def)
                return def;

            if (this._defaultLinks.length > 0)
                return this._defaultLinks[destinationId % this._defaultLinks.length];

            return "";
        }
    }
}