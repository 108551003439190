import {
    BusCompanyModule,
    BusStationModule,
    DestinationBannerModule,
    ImageModule,
    SeatReserveModule
} from 'account/store/module';
import { SeatReserveStatus } from 'account/model/enum';

export default class SeatReserveSelector {
    private _seatReserveId: number;
    private _seatReserveModule: SeatReserveModule;
    private _busCompanyModule: BusCompanyModule;
    private _busStationModule: BusStationModule;
    private _destinationBannerModule: DestinationBannerModule;
    private _imageModule: ImageModule;

    constructor(seatReserveId: number) {
        this._seatReserveId = seatReserveId;
        this._seatReserveModule = SeatReserveModule.getInstance();
        this._busCompanyModule = BusCompanyModule.getInstance();
        this._busStationModule = BusStationModule.getInstance();
        this._destinationBannerModule = DestinationBannerModule.getInstance();
        this._imageModule = ImageModule.getInstance();
    }

    get originLabel(): string {
        const originId = this._seatReserveModule.getOriginId(this._seatReserveId);
        return this._busStationModule.getLabel(originId);
    }

    get destinationLabel(): string {
        const originId = this._seatReserveModule.getDestinationId(this._seatReserveId);
        return this._busStationModule.getLabel(originId);
    }

    get companyLogoUrl(): string {
        const busCompanyKey = this._seatReserveModule.getCompanyKey(this._seatReserveId);
        const imageId = this._busCompanyModule.getLogoId(busCompanyKey);
        return this._imageModule.getImageUrl(imageId);
    }

    get locator(): string {
        return this._seatReserveModule.getLocator(this._seatReserveId);
    }

    get status(): SeatReserveStatus {
        return this._seatReserveModule.getStatus(this._seatReserveId);
    }

    get destinationBannerUrl(): string {
        const destinationId = this._seatReserveModule.getDestinationId(this._seatReserveId);
        return this._destinationBannerModule.getBannerUrl(destinationId);
    }

    get departureDate(): Date {
        return this._seatReserveModule.getDepartureDate(this._seatReserveId);
    }

    get arrivalDate(): Date {
        return this._seatReserveModule.getDepartureDate(this._seatReserveId);
    }
}