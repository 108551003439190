import { Operation } from 'account/model';
import { OperationStatus, PaymentType } from 'account/model/enum';
import { OperationDTO } from 'account/services/dto';
import { parseStringToDate } from 'vue-shared/utils/date';

function operationStatusMap(operationStatus: string): OperationStatus {
    switch(operationStatus) {
        case "PAID": return OperationStatus.PAID;
        case "CANCEL": return OperationStatus.CANCEL;
        case "EXPIRED": return OperationStatus.EXPIRED;
        case "PENDING": return OperationStatus.PENDING;
        case "FAIL": return OperationStatus.FAIL;
        case "RESERVED": return OperationStatus.RESERVED;
        case "PAYMENT_REJECTED": return OperationStatus.PAYMENT_REJECTED;
        case "BOLETO_PENDENTE": return OperationStatus.BOLETO_PENDENTE;
        case "PARTIAL_CANCEL": return OperationStatus.PARTIAL_CANCEL;
        case "OFFLINE_TICKETS_PENDING": return OperationStatus.OFFLINE_TICKETS_PENDING;
    }

    return OperationStatus.UNKNOWN;
}

function paymentTypeMap(paymentType: string): PaymentType {
    switch (paymentType) {
        case "DEBIT": return PaymentType.DEBIT;
        case "CREDIT": return PaymentType.CREDIT;
        case "FASTCASH": return PaymentType.FASTCASH;
        case "BOLETO": return PaymentType.BOLETO;
        case "INVOICE": return PaymentType.INVOICE;
        case "TRANSFER": return PaymentType.TRANSFER;
        case "DIGITAL_WALLET": return PaymentType.DIGITAL_WALLET
    }
    return PaymentType.UNKNOWN;
}

export default function operationAdapter(operationDTO: OperationDTO): Operation {
    return {
        id: operationDTO.operationId,
        status: operationStatusMap(operationDTO.status),
        email: operationDTO.email,
        origin: operationDTO.origin,
        destination: operationDTO.finalDestination,
        reservedSeatsGo: operationDTO.reservedSeatsGo.map(seatReserveDTO => seatReserveDTO.id),
        reservedSeatsBack: operationDTO.reservedSeatsBack.map(seatReserveDTO => seatReserveDTO.id),
        paymentType: paymentTypeMap(operationDTO.paymentType),
        totalValue: operationDTO.totalValue,
        ticketValue: operationDTO.ticketValue,
        confirmedValue: operationDTO.confirmedValue,
        interestPercentage: operationDTO.interestPercentage,
        interestValue: operationDTO.interestValue,
        insuranceSum: operationDTO.insuranceSum,
        purchaseDate: parseStringToDate(operationDTO.purchaseDate, "YYYY-MM-DD hh:mm"),
        formaPagamento: operationDTO.formaPagamento,
        paymentUrl: operationDTO.paymentUrl,
        userHasOneClick: operationDTO.userHasOneClick,
        firstBuy: operationDTO.firstBuy
    }
}