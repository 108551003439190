export enum AccountAmplitudeEvent {
    ACCOUNT_OPEN = 'account_open',
    BTN_EDIT_PROFILE = 'clicked_button_edit_profile',
    SAVE_UPDATE_PROFILE_PAGE = 'click_save_update_profile',
    TOGGLE_NEWSLETTER_PROFILE_PAGE = 'user_toggle_newsletter',
    SAVE_UPDATE_ACCESS_DATA_PAGE = 'click_save_update_access_data',
    DELETE_CARD = 'click_remove_card',
    SAVE_NEW_CARD = 'click_save_new_card',
    TRAVEL_DETAIL = 'click_button_travel_detail',
    BUY_TICKET = 'click_button_return_home',
    PARTIAL_CANCEL = 'partial_cancel',
    FULL_CANCEL = 'full_cancel',
    CANCELLATION = 'cancellation',
    SWAP_CLICK = 'swap_travel_modal_click',
    SWAP_CONFIRM = 'swap_travel_modal_confirm',
    SWAP_CANCEL = 'swap_travel_modal_cancel',
    SWAP_CONFIRM_PASSENGER = 'swap_travel_confirm_passenger',
    SWAP_BACK_PASSENGER = 'swap_travel_back_passenger',
}
