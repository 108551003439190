import { Component, Prop, Vue } from 'vue-property-decorator';
import { parseDateToString } from 'vue-shared/utils/date';
import { ImgsUtil } from 'vue-shared/utils/imgs/imgs.util';

@Component({
    name: 'GVRoute',
    template: require('./gv-route.html')
})
export default class GVRoute extends Vue {
    isTravelGo = false;
    isEasyBoarding = true;

    @Prop({ type: String, default: "" })
    origin: string;

    @Prop({ type: String, default: "" })
    destination: string;

    @Prop({ type: String, default: "" })
    companyName: string;

    @Prop({ type: String, default: "" })
    companyLogoUrl: string;

    @Prop({ type: Date })
    departureDate: Date;

    @Prop({ type: Date })
    arrivalDate: Date;

    @Prop({ type: Boolean, default: false})
    isConnection;

    @Prop({ type: Boolean, default: false})
    isStopOver;

    @Prop()
    img: string;

    @Prop({ type: Number, default: "" })
    locator: string;

    @Prop()
    hasBack: boolean;

    @Prop()
    backDepartureDate: Date;

    get easyBoarding() {
        return {
            'background-image': `url('${ImgsUtil.staticAws()}/_v2/static/img/icons/ic_qr-code.svg')`
        }
    }

    get ticketImg() {
        return `${ImgsUtil.staticAws()}/_v2/static/img/ticket.png`;
    }

    get departureDateStr(): string {
        return parseDateToString(this.departureDate, "Dow DD Mth YYYY");
    }

    get departureDateStrShort(): string {
        return parseDateToString(this.departureDate, "Dow, DD mth");
    }
    get backDateStrShort(): string {
        return parseDateToString(this.backDepartureDate, "Dow, DD mth");
    }

    get departureDateTime(): string {
        return parseDateToString(this.departureDate, "Dow DD Mth YYYY<span>,</span> hh:mmh");
    }

    get departureTimeStr(): string {
        return parseDateToString(this.departureDate, "hh:mmh");
    }

    get arrivalDateStr(): string {
        return parseDateToString(this.arrivalDate, "Dow DD Mth YYYY");
    }

    get arrivalDateTime(): string {
        return parseDateToString(this.arrivalDate, "Dow DD Mth YYYY<span>,</span> hh:mmh");
    }

    get arrivalTimeStr(): string {
        return parseDateToString(this.arrivalDate, "hh:mmh");
    }

    get isTypeRouteInfo() {
        return this.showTypeRouteInfo ? 'detail' : '';
    }

    @Prop()
    title: string;

    @Prop({ type: Boolean, default: true })
    showLogoBus: boolean;

    @Prop({ type: Boolean, default: true})
    showInfoRoute: boolean;

    @Prop({ type: Boolean, default: true})
    showTypeRoute: boolean;

    @Prop({ type: Boolean, default: true})
    showInfoResume: boolean;

    @Prop({ type: Boolean, default: false})
    showInfoGoBack: boolean;

    @Prop({ type: Boolean, default: false})
    showTitle: boolean;

    @Prop({ type: Boolean, default: false})
    showTypeRouteInfo: boolean;
}
