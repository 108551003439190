import { Component, Prop, Vue } from 'vue-property-decorator';
import { ButtonVariant } from './button-variant.enum';

@Component({
    name: 'GVButton',
    template: require('./gv-button.html'),
})
export default class GVButton extends Vue {
    @Prop({ type: String, default: '' })
    variant: string;

    @Prop({ required: true, type: String, default: '' })
    title: string;

    @Prop({ type: Boolean, default: false })
    disabled: boolean;

    @Prop({ type: Boolean, default: false })
    fullWidth: boolean;

    @Prop({ type: Boolean, default: false })
    shadow: boolean;

    @Prop({ type: String, default: '' })
    leadingIcon: string;

    @Prop({ type: String, default: '' })
    trailingIcon: string;

    @Prop({ type: String, default: '' })
    color: string;

    @Prop({ type: String })
    height: string;

    click(): void {
        this.$emit('click');
    }

    get hasShadow(): boolean {
        return this.shadow && !this.isText;
    }

    get isText(): boolean {
        return this.variant === ButtonVariant.TEXT && !this.isGoogle && !this.isFacebook;
    }

    get isUnelevated(): boolean {
        return this.variant === ButtonVariant.UNELEVATED && !this.isGoogle && !this.isFacebook;
    }

    get isOutlined(): boolean {
        return this.variant === ButtonVariant.OUTLINED && !this.isGoogle && !this.isFacebook;
    }

    get isRounded(): boolean {
        return this.variant === ButtonVariant.ROUNDED && !this.isGoogle && !this.isFacebook;
    }

    get isRoundedOutlined(): boolean {
        return this.variant === ButtonVariant.ROUNDED_OUTLINED && !this.isGoogle && !this.isFacebook;
    }
    get classObject(): any {
        return {
            'gv-btn--text': this.isText,
            'gv-btn--outlined': this.isOutlined,
            'gv-btn--unelevated': this.isUnelevated,
            'gv-btn--rounded': this.isRounded,
            'gv-btn--rounded--outlined': this.isRoundedOutlined,
            'gv-btn--shadow': this.hasShadow,
            'gv-btn--facebook': this.isFacebook,
            'gv-btn--google': this.isGoogle,
            'gv-btn--full-width': this.fullWidth,
            'gv-btn--orange': this.color === 'orange' || this.color === 'secondary',
            'gv-btn--white': this.color === 'white',
            'gv-btn--blue': this.color === 'blue',
        };
    }

    get styleObject(): any {
        return {
            height: this.height,
        };
    }

    get isFacebook(): boolean {
        return this.color === 'facebook';
    }

    get isGoogle(): boolean {
        return this.color === 'google';
    }
}
