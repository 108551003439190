import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
    name: 'modalLogin',
    namespaced: true,
})
export default class ModalLoginModule extends VuexModule {
    isCheckoutLogin: boolean = false;
    showLoginModal: boolean = false;
    userHasOneClick: boolean = false;

    @Mutation
    setLoginModalMutation(showLoginModal: boolean): void {
        this.showLoginModal = showLoginModal;
    }

    @Action({ commit: 'setLoginModalMutation' })
    setLoginModal(showLoginModal: boolean): boolean {
        return showLoginModal;
    }

    @Mutation
    setUserOneClickMutation(userHasOneClick: boolean): void {
        this.userHasOneClick = userHasOneClick;
    }

    @Action({ commit: 'setUserOneClickMutation' })
    setUserOneClick(userHasOneClick: boolean): boolean {
        return userHasOneClick;
    }

    @Mutation
    setIsCheckoutLoginMotation(isCheckoutLogin: boolean): void {
        this.isCheckoutLogin = isCheckoutLogin;
    }

    @Action({ commit: 'setIsCheckoutLoginMotation' })
    setIsCheckoutLogin(isCheckoutLogin: boolean): boolean {
        return isCheckoutLogin;
    }
}
