import {
    Action,
    Module,
    Mutation,
    VuexModule,
    getModule
} from 'vuex-module-decorators';
import { Store } from 'account/store';
import { SeatReserve } from 'account/model';
import {
    SeatReserveStatus,
    SeatTravelType
} from 'account/model/enum';

@Module({
    name: 'seat-reserve',
    namespaced: true,
})
export default class SeatReserveModule extends VuexModule {
    /**
     * Repository of travels.
     * 
     * It's a map from SeatReserve.id to SeatReserve.
     */
    private _repo: Map<number, SeatReserve> = new Map<number, SeatReserve>();

    static getInstance(): SeatReserveModule {
        return getModule(SeatReserveModule, Store.getInstance());
    }

    @Mutation
    private _insertSeatReserve(seatReserve: SeatReserve) {
        this._repo.set(seatReserve.id, seatReserve);
    }

    @Action
    insertSeatReserve(seatReserve: SeatReserve): void {
        this.context.commit("_insertSeatReserve", seatReserve);
    }

    get getOriginId(): (seatReserveId: number) => number {
        return (seatReserveId: number): number => {
            if (this._repo.has(seatReserveId))
                return this._repo.get(seatReserveId).origin;
    
            return -1;
        }
    }

    get getDestinationId(): (seatReserveId: number) => number {
        return (travelId: number): number => {
            if (this._repo.has(travelId))
                return this._repo.get(travelId).destination;
    
            return -1;
        }
    }

    get getCompanyKey(): (seatReserveId: number) => string {
        return (seatReserveId: number): string => {
            if (this._repo.has(seatReserveId))
                return this._repo.get(seatReserveId).busCompany;
    
            return "";
        }
    }

    get getLocator(): (seatReserveId: number) => string {
        return (seatReserveId: number): string => {
            if (this._repo.has(seatReserveId))
                return this._repo.get(seatReserveId).locator;

            return "";
        }
    }

    get getStatus(): (seatReserveId: number) => SeatReserveStatus {
        return (seatReserveId: number): SeatReserveStatus => {
            if (this._repo.has(seatReserveId))
                return this._repo.get(seatReserveId).status;

            return SeatReserveStatus.UNKNOWN;
        }
    }

    get getDepartureDate(): (seatReserveId: number, def?: Date) => Date {
        return (seatReserveId: number, def: Date = new Date()): Date => {
            if (this._repo.has(seatReserveId))
                return this._repo.get(seatReserveId).departureDate;

            return def;
        }
    }

    get getArrivalDate(): (seatReserveId: number, def?: Date) => Date {
        return (seatReserveId: number, def: Date = new Date()): Date => {
            if (this._repo.has(seatReserveId))
                return this._repo.get(seatReserveId).arrivalDate;

            return def;
        }
    }

    get getTravelType(): (seatReserveId: number, def?: SeatTravelType) => SeatTravelType {
        return (seatReserveId: number, def: SeatTravelType = SeatTravelType.UNKNOWN): SeatTravelType => {
            if (this._repo.has(seatReserveId))
                return this._repo.get(seatReserveId).travelType;

            return def;
        }
    }
}