export enum RouterPath {
    HOME = '/perfil',
    UPDATE_PROFILE = '/perfil-editar',
    TRAVEL = '/minhas-viagens',
    DETAIL_TRAVEL = '/detalhes-viagem/:operationId',
    PAYMENT_CARD = '/meus-cartoes',
    REGISTER_CARD = '/novo',
    WALLET = '/carteira',
    REVIEW = '/avaliacoes',
    NOTIFICATION = '/notificacoes',
    ACCESS_DATA = '/dados-acesso',
    LOGIN = '/login',
    TRAVEL_INFO = '/detalhes-viagem/:operationId',
    GENERIC_INFO = '/detalhes-viagem/info',
}
