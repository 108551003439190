import { Image } from 'account/model';
import { ImageDTO } from 'account/services/dto';

export default function imageAdapter(imageDto: ImageDTO): Image {
    return {
        id: imageDto.id,
        url: imageDto.linkImage,
        title: imageDto.titleImage,
        alt: imageDto.altImage
    }
}