import { AdapterData } from 'checkout/interfaces/adapter-data.interface';
import { OneClickCard } from 'checkout/interfaces/one-click-card.interface';
import { HttpClient } from '../../commons/ts/http.client';
import { RestEndPoint } from '../../commons/ts/utils/rest.endpoints';
import { CheckoutPaymentModel } from '../models/checkout-payment-model';
import { OperationPaymentMethodModel } from '../models/operation.payment.method.model';
import { OperationStatusModel } from '../models/operation.status.model';
import { InsuranceParams } from '../models/request/insurance.request';
import { OperationRequest } from '../models/request/operation.request';
import { PaymentRequest } from '../models/request/payment.request';
import { DeviceInfo } from '../models/request/device-info.request';
import { CreditCardDTO } from 'account/services/dto';

class Service {
    /**
     * Method responsible for activate the insurance into a reserve.
     *
     * @param operationId
     * @param bookEmail
     * @param chargeInsurance
     */
    async updateInsuranceInfo(
        operationId: number,
        email: string,
        insurance: boolean
    ): Promise<OperationPaymentMethodModel> {
        const insuranceParams: InsuranceParams = {
            operationId,
            email,
            insurance,
        };

        return await HttpClient.get({ url: RestEndPoint.PAYMENT_METHOD, params: insuranceParams });
    }

    /**
     * Method responsible for activate the insurance into a reserve.
     *
     * @param operationId
     * @param bookEmail
     * @param chargeInsurance
     */
    async updateGuicheInsuranceInfo(
        operationId: number,
        email: string,
        withGuicheInsurance: Array<number>,
        reservesWithHalfPrice: Array<number>
    ): Promise<OperationPaymentMethodModel> {
        const insuranceParams: InsuranceParams = {
            operationId,
            email,
            withGuicheInsurance: withGuicheInsurance.join(','),
            reservesWithHalfPrice: reservesWithHalfPrice.join(','),
        };

        return await HttpClient.get({ url: RestEndPoint.PAYMENT_METHOD, params: insuranceParams });
    }

    /**
     *
     * This method is responsible for perform the payment.
     *
     * @param operation
     * @param paymentInfo
     * @returns True - payment success, False - payment error
     */
    async executePayment(operation: OperationRequest, paymentInfo: PaymentRequest, deviceInfo: DeviceInfo): Promise<CheckoutPaymentModel> {
        const body: any = {
            operation: operation,
            payment: paymentInfo,
            deviceInfo
        };

        return await HttpClient.post(RestEndPoint.PAYMENT, body);
    }

    /**
     * Method responsible for retrieve status of the operation.
     *
     * @param operationId
     * @param bookEmail
     * @param chargeInsurance
     */
    async checkOperationStatus(operationId: number, bookEmail: string): Promise<OperationStatusModel> {
        return await HttpClient.get({ url: RestEndPoint.CHECK_OPERATION_STATUS + '/' + operationId + '/' + bookEmail });
    }

    /**
     * Method responsible for retrieve the operation data.
     *
     * @param operationId
     */
    async getOperationInfo(operationId: number): Promise<AdapterData> {
        return await HttpClient.get({ url: RestEndPoint.INDEX + '/' + operationId });
    }

    /**
     * Method responsible for retrieve the credit card info
     *
     * @param cardBin
     */
    async checkCardInfo(cardBin: string): Promise<any> {
        return await HttpClient.get({ url: RestEndPoint.CARD_BIN + '/' + cardBin });
    }

    async getOneClickCards(): Promise<Array<OneClickCard>> {
        return await HttpClient.get({ url: RestEndPoint.ONE_CLICK });
    }

    async getCreditCards(): Promise<Array<CreditCardDTO>> {
        return await HttpClient.get({ url: RestEndPoint.ONE_CLICK });
    }
}

export const PaymentService: Service = new Service();
