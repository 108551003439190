import * as vClickOutside from 'v-click-outside';
import VeeValidate from 'vee-validate';
import Vue from 'vue';
import GAuth from 'vue-google-oauth2';
import { Filters } from 'vue-shared/filters';
import { CONSTANTS } from 'vue-shared/utils/constants';
import VueTheMask from 'vue-the-mask';
import { AppRouterLogin as router } from '../login/router';
import { GVCookiesLgpdAlert } from '../commons/components/gv-cookie-lgpd-alert';
import '../vue-shared/config/vee-validation.config.ts';
import { GoogleConfig } from './interfaces/google-config.interface';
import LoginController from './login.controller';

const gauthOption: GoogleConfig = {
    clientId: process.env.GOOGLE_KEY,
};

new GVCookiesLgpdAlert();

Vue.use(GAuth, gauthOption);
Vue.use(Filters);
Vue.use(VueTheMask);
Vue.use(vClickOutside);
Vue.use(VeeValidate, {
    locale: 'pt-br',
    dictionary: CONSTANTS.DICTIONARY,
    events: 'blur',
});

new LoginController({
    router,
    el: '#js-login-content',
});
