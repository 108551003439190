import { FinancialStatement } from 'account/model';
import { CREDIT, DEBIT } from 'account/model/enum/financial-statement-type';
import { FinancialStatementDTO } from 'account/services/dto';

export default function financialStatementAdapter(financialStatementDTO: FinancialStatementDTO): FinancialStatement {
    return {
        id: financialStatementDTO.id,
        date: new Date(financialStatementDTO.createdAt),
        type: financialStatementDTO.operation == "C" ? CREDIT : DEBIT,
        details: financialStatementDTO.comment,
        value: financialStatementDTO.value
    }
}