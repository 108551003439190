import { Component, Vue } from 'vue-property-decorator';
import { CreditCardUtil } from 'checkout/utils/credit-card/credit-card.util';
import LoadingModule from 'account/store/loading';
import { CreditCardDTO } from 'account/services/dto';
import { CreditCardService } from 'account/services';
import { creditCardAdapter } from 'account/services/adapter';
import { MDCSnackbar } from '@material/snackbar';
import { SnackbarIdentifier } from 'account/enums/snackbar-identifier.enum';
import { AccountAmplitude } from 'account/services/amplitude.service';
import AccountModule from 'account/store/account';
import { getModule } from 'vuex-module-decorators';
import storeAccount from 'account/store';
import { PaymentCardPageModule } from 'account/pages/payment-card/module';
import { CreditCardModule } from 'account/store/module';
import { PageName } from 'account/enums/page-name.enums';
import { RouterPath } from 'account/enums/router-path.enum';

const account: AccountModule = getModule(AccountModule, storeAccount);
@Component({
    name: 'RegisterCard',
    template: require('./register-card.page.html'),
})
export default class RegisterCard extends Vue {
    private _paymentCardPageModule: PaymentCardPageModule = null;
    private _creditCardModule: CreditCardModule = null;
    private _loadingModule: LoadingModule;

    private _errorSnackBar: MDCSnackbar;
    private _creditCardService: CreditCardService;

    userCPF: string = "";
    userName: string = "";
    userBirthdate: string = "";
    userPhone: string = "";
    userEmail: string = account.userInfo.email;

    cardNumber: string = "";
    cardExpirationDate: string = "";
    cardCVV: string = "";

    mounted(): void {
        this._creditCardModule = CreditCardModule.getInstance();
        this._paymentCardPageModule = PaymentCardPageModule.getInstance();
        this._loadingModule = LoadingModule.getInstance();
        this._creditCardService = CreditCardService.getInstance();

        this._errorSnackBar = new MDCSnackbar(document.querySelector(SnackbarIdentifier.ERROR));
        AccountAmplitude.accountOpen(PageName.NEW_CARD);
    }

    get creditCardPattern(): string {
        return CreditCardUtil.identifyCard(this.cardNumber).spacingPattern;
    }

    get creditCardCVVPattern(): string {
        return CreditCardUtil.identifyCard(this.cardNumber).cvvPattern;
    }

    get cardBrand(): string {
        return CreditCardUtil.identifyCard(this.cardNumber).brand;
    }

    get cardClass(): string {
        return !!this.cardBrand
            ? `gv-card-flag-thumb gv-card-flag-thumb-${this.cardBrand.toLowerCase()}`
            : '';
    }

    cardUpdate(): void {
        CreditCardUtil.cardUpdate();
    }

    checkCardInfo(): void {
        CreditCardUtil.checkCardInfo(this.$router, this.$route);
    }

    hasError(fieldName: string): boolean {
        return !!this.getError(fieldName);
    }

    getError(fieldName: string): string {
        const foundError: any = this.errors.items.find((error: any) => error.field === fieldName);
        return foundError ? foundError.msg : null;
    }

    openSnackbar(snackbarId: string): void {
        const snackbar: MDCSnackbar = new MDCSnackbar(document.querySelector(snackbarId));
        snackbar.open();
    }

    async submit(): Promise<void> {
        const isValid = await this.$validator.validateAll('creditFields');

        if (isValid) {
            this._loadingModule.startLoading();
            const creditCardDTO: CreditCardDTO = this.parseCreditCardDTO();

            try {
                const creditCardDto = await this._creditCardService.saveCreditCard(creditCardDTO);
                const creditCard = creditCardAdapter(creditCardDto);
                this._creditCardModule.setCreditCard(creditCard);
                this._paymentCardPageModule.insertCard(creditCard.id);
                this.$router.push("meus-cartoes");

                AccountAmplitude.saveNewCard();
                this.openSnackbar(SnackbarIdentifier.ADDING_CARD);
            }
            catch(e) {
                this.openSnackbar(SnackbarIdentifier.WARNING);
            }
            finally {
                this._loadingModule.finishLoading();
            }
        } else {
            this.openSnackbar(SnackbarIdentifier.WARNING);
            return;
        }
    }

    private parseCreditCardDTO(): CreditCardDTO {
        return {
            card: this.parseCardNumber(),
            cvv: this.cardCVV,
            expirationDate: this.parseExpirationDate(),
            brand: this.cardBrand,
            last4: this.parseLast4(),
            cardOwner: {
                name: this.userName,
                document: this.parseCPF(),
                birthDate: this.parseBirthdate(),
                phoneNumber: this.parsePhone(),
                email: this.userEmail
            }
        };
    }

    private parseCardNumber(): string {
        return this.cardNumber.replace(/ /g, "");
    }

    private parseExpirationDate(): string {
        var [month, year] = this.cardExpirationDate.split("/");

        if (year.length == 2)
            year = "20"+year;

        return year+month;
    }

    private parseLast4(): string {
        const cardNumber = this.parseCardNumber();
        return cardNumber.slice(cardNumber.length - 4);
    }

    private parseCPF(): string {
        return this.userCPF.replace(/(\.|\-)/g, "");
    }

    private parseBirthdate(): string {
        const [day, month, year] = this.userBirthdate.split("/");
        return year+"-"+month+"-"+day;
    }

    private parsePhone(): string {
        return this.userPhone.replace(/(\(|\)|\s|\-)/g, "");
    }

    doCancel(): void {
        window.location.href = RouterPath.PAYMENT_CARD;
    }
}
