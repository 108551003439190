enum OperationStatus {
    PAID,
    CANCEL,
    EXPIRED,
    PENDING,
    FAIL,
    RESERVED,
    PAYMENT_REJECTED,
    BOLETO_PENDENTE,
    PARTIAL_CANCEL,
    OFFLINE_TICKETS_PENDING,
    UNKNOWN
}

export default OperationStatus;
