import { HttpClient } from 'commons/ts/http.client';

export default class DestinationBannerService {
    private static _instance: DestinationBannerService = new DestinationBannerService();

    static getInstance(): DestinationBannerService {
        return this._instance;
    }

    getDefaultLinks(): Promise<Array<string>> {
        return HttpClient.get({url: "/t/api/account/destination-banner/default-links"});
    }
}