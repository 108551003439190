import { MDCDialog } from '@material/dialog';
import { DialogIdentifier } from 'checkout/enums/dialog-identifier.enum';
import store from 'checkout/store';
import DialogModule from 'checkout/store/dialog';
import VueRouter, { Route } from 'vue-router';
import { MediaUtil } from 'vue-shared/utils/media/media.util';
import { RouterUtil } from 'vue-shared/utils/router/router.util';
import { getModule } from 'vuex-module-decorators';

const dialogStore: DialogModule = getModule(DialogModule, store);

class Util {
    /**
     *
     * Auxiliary method to open a dialog.
     * @param {DialogIdentifier} identifier
     * @param {String} msg
     * @param {VueRouter} router
     * @param {Route} route
     */
    openDialog(identifier: DialogIdentifier, router: VueRouter, route: Route, msg?: string): void {
        this.closeOpenedModal();

        dialogStore.setMessage(msg || '');

        const dialog: MDCDialog = new MDCDialog(document.querySelector(identifier));

        dialog.open();
        dialog.listen('MDCDialog:closed', () => {
            if (
                (identifier !== DialogIdentifier.CREDIT_DEBIT_DIALOG &&
                    identifier !== DialogIdentifier.DEBIT_CREDIT_DIALOG &&
                    identifier !== DialogIdentifier.FOREIGN_CARD_DIALOG &&
                    identifier !== DialogIdentifier.USED_PASSENGER_DIALOG &&
                    identifier !== DialogIdentifier.CVV_INFO_DIALOG &&
                    identifier !== DialogIdentifier.DOCUMENT_INFO_DIALOG &&
                    identifier !== DialogIdentifier.UNDER_SIX_YEARS_INFO_DIALOG) ||
                !MediaUtil.isMobile()
            ) {
                RouterUtil.replaceRoute(router, route);
            }

            dialogStore.setMessage('');
            dialogStore.setOpenedModal(null);
            dialogStore.setOpenedModalName('');
        });
        dialogStore.setOpenedModalName(identifier);
        dialogStore.setOpenedModal(dialog);

        if (
            identifier === DialogIdentifier.BOOKING_EXPIRED ||
            identifier === DialogIdentifier.AUTHENTICATED_SALE_DIALOG
        ) {
            // when this property is empty, esc key doesn't have any action
            dialog.escapeKeyAction = '';
            // when this property is empty, scrim click doesn't have any action
            dialog.scrimClickAction = '';
        }

        RouterUtil.pushRoute(router, route);
    }

    closeOpenedModal(): void {
        if (!dialogStore.openedModal) {
            return;
        }

        dialogStore.openedModal.close();
    }
}

export const DialogUtil: Util = new Util();
