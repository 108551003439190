import { Component, Vue } from 'vue-property-decorator';
import GVNotify from 'account/components/gv-notify';
import GVTravelPreferences from 'account/components/gv-travel-preferences';
import AccountModule from 'account/store/account';
import { getModule } from 'vuex-module-decorators';
import store from 'account/store';
import LoadingModule from 'account/store/loading';
import { PageName } from 'account/enums/page-name.enums';
import { AccountAmplitude } from 'account/services/amplitude.service';
import { AccountService } from 'account/services';
import { UserInfo } from 'account/interfaces/user-info.interface';

const account: AccountModule = getModule(AccountModule, store);
const loading: LoadingModule = getModule(LoadingModule, store);
@Component({
    name: 'NotificationPage',
    template: require('./notification.page.html'),
    components: {
        'gv-notify': GVNotify,
        'gv-travel-preferences': GVTravelPreferences
    }
})
export default class NotificationPage extends Vue {
    active: boolean = true;

    mounted(): void {
        loading.hide();
        AccountAmplitude.accountOpen(PageName.NOTIFICATIONS);
        this.active = account.userInfo.newsletter;
    }
    get userInfo() {
        return account.userInfo;
    }

    get isActive() {
        return this.active;
    }

    async saveUpdate(): Promise<void> {
        try {
            loading.startLoading();

            // Atualiza usuario
            const updatedUser: UserInfo = await AccountService.updateUser({
                ...account.userInfo,
                newsletter: this.active
            });
            AccountAmplitude.saveToggleNewsletter();
            account.setUserInfo(updatedUser);
        } catch (error) {
            console.log(error)
        }
        finally {
            loading.finishLoading();
        }
    }
    toggle() {
        this.active = !this.active;
    }

}
