import { MDCSnackbar } from '@material/snackbar';
import { Component, Vue } from 'vue-property-decorator';
import LoadingModule from 'account/store/loading';
import { getModule } from 'vuex-module-decorators';
import store from 'account/store';
import storeAccount from 'account/store';

import { passwordChange } from 'account/interfaces/password-change.interface';
import { AccountService } from 'account/services/account.service';
import { SnackbarIdentifier } from 'account/enums/snackbar-identifier.enum';
import { DataScope } from 'account/enums/data-scope.enum';
import { AccountAmplitude } from 'account/services/amplitude.service';
import { PageName } from 'account/enums/page-name.enums';
import { RouterPath } from 'account/enums/router-path.enum';
import AccountModule from 'account/store/account';

const loading: LoadingModule = getModule(LoadingModule, store);
const account: AccountModule = getModule(AccountModule, storeAccount);
@Component({
    name: 'AccessDataPage',
    template: require('./access-data.page.html'),
})
export default class AccessPage extends Vue {
    private isShowingPassword: boolean = false;
    private passwordConfirmation: string = '';
    private changedPassword: passwordChange = {
        oldPassword: '',
        newPassword: '',
    };

    mounted(): void {
        loading.hide();
        AccountAmplitude.accountOpen(PageName.ACCESS_DATA);
    }

    clearForm(): void {
        this.changedPassword.oldPassword = '';
        this.changedPassword.newPassword = '';
        this.passwordConfirmation = '';
    }

    openSnackbar(snackbarId: string): void {
        const snackbar: MDCSnackbar = new MDCSnackbar(document.querySelector(snackbarId));
        snackbar.open();
    }

    /**
     * change password
     */
    async changePassword(password): Promise<void> {
        const formIsValid: boolean = this.validateFields();

        if(!formIsValid) {
            this.openSnackbar(SnackbarIdentifier.WARNING);
            return;
        }

        try {
            loading.startLoading();
            await AccountService.changePassword(this.changedPassword);
            this.openSnackbar(SnackbarIdentifier.VALIDATION_OK);
            AccountAmplitude.saveUpdateAccessData();
            this.$router.push('perfil');
        } catch (error) {
            this.openSnackbar(SnackbarIdentifier.ERROR);
            return;
        }
        finally {
            loading.finishLoading();
        }
    }

    get iconPassword(): string {
        return this.isShowingPassword ? 'visibility_off' : 'visibility';
    }

    get typePassword(): string {
        return this.isShowingPassword ? 'text' : 'password';
    }

    get passengersFields(): string {
        return DataScope.PASSENGER_FIELDS_DATA;
    }

    doCancel(): void {
        window.location.href = RouterPath.HOME;
    }

    changeVisibility(): void {
        this.isShowingPassword = !this.isShowingPassword;
    }

    validateField(input: HTMLInputElement): boolean {
        input.parentElement.classList.remove('error');
        if (input.required && !input.value) {
            input.parentElement.classList.add('error');
            input.parentElement.querySelector('.bs-input-error').innerHTML = 'Campo obrigatório';
            return false;
        }
        if (input.dataset.type === 'old-password') {
            const valid: boolean = input.value.length > 2;
            if (!valid) {
                input.parentElement.classList.add('error');
                input.parentElement.querySelector('.bs-input-error').innerHTML = 'Senha inválida';
                return false;
            }
            input.parentElement.querySelector('.bs-input-error').innerHTML = '';
        }
        if (input.dataset.type === 'new-password') {
            const re: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z0-9!-\/:-@])(?=.*[!-.:-@]).{8,50}$/;
            const valid: boolean = re.test(input.value);
            if (!valid) {
                input.parentElement.classList.add('error');
                input.parentElement.querySelector('.bs-input-error').innerHTML = 'Senha inválida';
                return false;
            }
            input.parentElement.querySelector('.bs-input-error').innerHTML = '';
        }
        if (input.dataset.type === 'password-confirmation') {
            const re: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z0-9!-\/:-@])(?=.*[!-.:-@]).{8,50}$/;
            const valid: boolean = re.test(input.value);
            if (!valid) {
                input.parentElement.classList.add('error');
                input.parentElement.querySelector('.bs-input-error').innerHTML = 'Senha inválida';
                return false;
            }
            input.parentElement.querySelector('.bs-input-error').innerHTML = '';
        }
        if (this.passwordConfirmation !== this.changedPassword.newPassword) {
            if ((input.dataset.type === 'password-confirmation') || (input.dataset.type === 'new-password')) {
                input.parentElement.classList.add('error');
                input.parentElement.querySelector('.bs-input-error').innerHTML = 'As senhas não coincidem';
                return false;
            }
        }
        return true;
    }

    private validateFields(): boolean {
        const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('.js-access-form');
        let valid: boolean = true;
        inputs.forEach((input: HTMLInputElement) => {
            const validField: boolean = this.validateField(input);
            if (valid) valid = validField;
        });
        return valid;
    }
}
