import GVLogin from 'vue-shared/components/gv-login';
import { Component, Vue } from 'vue-property-decorator';
import GVLoading from 'vue-shared/components/gv-loading/gv-loading';
import LoadingModule from 'account/store/loading';
import { getModule } from 'vuex-module-decorators';
import store from 'account/store';
const loading: LoadingModule = getModule(LoadingModule, store);
@Component({
    name: 'LoginPage',
    template: require('./login.page.html'),
    components: {
        'gv-login': GVLogin,
        'gv-loading': GVLoading,
    },
})
export default class LoginPage extends Vue {
    get staticAws(): string {
        return window['staticAws'];
    }

    get isShowing(): boolean {
        return loading.opened || loading.isLoading;
    }
}
