import {
    Action,
    Module,
    Mutation,
    VuexModule,
    getModule
} from 'vuex-module-decorators';
import { Store } from 'account/store';
import { CreditCard } from 'account/model';
import { PaymentBrand } from 'checkout/enums/payment-brand.enum';

@Module({
    name: 'credit-card',
    namespaced: true,
})
export default class CreditCardModule extends VuexModule {
    /**
     * Repository of CreditCard.
     * 
     * It's a map from CreditCard.id to CreditCard.
     */
    private _repo: Map<number, CreditCard> = new Map<number, CreditCard>();

    static getInstance(): CreditCardModule {
        return getModule(CreditCardModule, Store.getInstance());
    }

    @Mutation
    private _setCreditCard(creditCard: CreditCard): void {
        this._repo.set(creditCard.id, creditCard);
    }

    @Action
    setCreditCard(creditCard: CreditCard): void {
        this.context.commit('_setCreditCard', creditCard);
    }

    get getOwnerName(): (creditCardId: number, def?: string) => string {
        return (creditCardId: number, def: string = ''): string => {
            if (this._repo.has(creditCardId))
                return this._repo.get(creditCardId).cardOwner.name;

            return def;
        }
    }

    get getLast4(): (creditCardId: number, def?: string) => string {
        return (creditCardId: number, def: string = ''): string => {
            if (this._repo.has(creditCardId))
                return this._repo.get(creditCardId).last4;

            return def;
        }
    }

    get getBrand(): (creditCardId: number, def?: string) => PaymentBrand {
        return (creditCardId: number, def: PaymentBrand = PaymentBrand.UNKNOWN): PaymentBrand => {
            if (this._repo.has(creditCardId))
                return this._repo.get(creditCardId).brand;

            return def;
        }
    }

    get getExpirationDate(): (creditCardId: number, def?: string) => string {
        return (creditCardId: number, def: string = ''): string => {
            if (this._repo.has(creditCardId))
                return this._repo.get(creditCardId).expirationDate;

            return def;
        }
    }
}