export const MONTHS = [
    { Name: 'Janeiro',   name: 'janeiro',   Nme: 'Jan', nme: 'jan' },
    { Name: 'Fevereiro', name: 'fevereiro', Nme: 'Fev', nme: 'fev' },
    { Name: 'Março',     name: 'março',     Nme: 'Mar', nme: 'mar' },
    { Name: 'Abril',     name: 'abril',     Nme: 'Abr', nme: 'abr' },
    { Name: 'Maio',      name: 'maio',      Nme: 'Mai', nme: 'mai' },
    { Name: 'Junho',     name: 'junho',     Nme: 'Jun', nme: 'jun' },
    { Name: 'Julho',     name: 'Julho',     Nme: 'Jul', nme: 'jul' },
    { Name: 'Agosto',    name: 'agosto',    Nme: 'Ago', nme: 'ago' },
    { Name: 'Setembro',  name: 'setembro',  Nme: 'Set', nme: 'set' },
    { Name: 'Outubro',   name: 'outubro',   Nme: 'Out', nme: 'out' },
    { Name: 'Novembro',  name: 'novembro',  Nme: 'Nov', nme: 'nov' },
    { Name: 'Dezembro',  name: 'dezembro',  Nme: 'Dez', nme: 'dez' }
];